export const NEWS_UPDATE = 'NEWS_UPDATE';
export const NEWS_UPDATE_SUCCESS = 'NEWS_UPDATE_SUCCESS';
export const NEWS_UPDATE_FAIL = 'NEWS_UPDATE_FAIL';
export const NEWS_ADD = 'NEWS_ADD';
export const NEWS_ADD_SUCCESS = 'NEWS_ADD_SUCCESS';
export const NEWS_ADD_FAIL = 'NEWS_ADD_FAIL';
export const NEWS_REMOVE = 'NEWS_REMOVE';
export const NEWS_REMOVE_SUCCESS = 'NEWS_REMOVE_SUCCESS';
export const NEWS_REMOVE_FAIL = 'NEWS_REMOVE_FAIL';
export const NEWS_GET_ALL = 'NEWS_GET_ALL';
export const NEWS_GET_ALL_SUCCESS = 'NEWS_GET_ALL_SUCCESS';
export const NEWS_GET_ALL_FAIL = 'NEWS_GET_ALL_FAIL';
export const NEWS_GET_ONE = 'NEWS_GET_ONE';
export const NEWS_GET_ONE_SUCCESS = 'NEWS_GET_ONE_SUCCESS';
export const NEWS_GET_ONE_FAIL = 'NEWS_GET_ONE_FAIL';
export const NEWS_ADD_IMAGE = 'NEWS_ADD_IMAGE';
export const NEWS_ADD_IMAGE_SUCCESS = 'NEWS_ADD_IMAGE_SUCCESS';
export const NEWS_ADD_IMAGE_FAIL = 'NEWS_ADD_IMAGE_FAIL';
export const NEWS_REMOVE_IMAGE = 'NEWS_REMOVE_IMAGE';
export const NEWS_REMOVE_IMAGE_SUCCESS = 'NEWS_REMOVE_IMAGE_SUCCESS';
export const NEWS_REMOVE_IMAGE_FAIL = 'NEWS_REMOVE_IMAGE_FAIL';
export const NEWS_CHANGE_ORDER_IMAGE = 'NEWS_CHANGE_ORDER_IMAGE';
export const NEWS_CHANGE_ORDER_IMAGE_SUCCESS = 'NEWS_CHANGE_ORDER_IMAGE_SUCCESS';
export const NEWS_CHANGE_ORDER_IMAGE_FAIL = 'NEWS_CHANGE_ORDER_IMAGE_FAIL';
export const NEWS_SET_SHOW_SNACKBAR = 'NEWS_SET_SHOW_SNACKBAR';
