export const OPTION_GET_ALL = 'OPTION_GET_ALL';
export const OPTION_GET_ALL_SUCCESS = 'OPTION_GET_ALL_SUCCESS';
export const OPTION_GET_ALL_FAIL = 'OPTION_GET_ALL_FAIL';

export const OPTION_UPDATE_ABOUT = 'OPTION_UPDATE_ABOUT';
export const OPTION_UPDATE_ABOUT_SUCCESS = 'OPTION_UPDATE_ABOUT_SUCCESS';
export const OPTION_UPDATE_ABOUT_FAIL = 'OPTION_UPDATE_ABOUT_FAIL';

export const OPTION_UPDATE_CONTACT = 'OPTION_UPDATE_CONTACT';
export const OPTION_UPDATE_CONTACT_SUCCESS = 'OPTION_UPDATE_CONTACT_SUCCESS';
export const OPTION_UPDATE_CONTACT_FAIL = 'OPTION_UPDATE_CONTACT_FAIL';
