import React, { useState, useEffect, useMemo } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import SubCategroyHeaderButton from 'components/SubCategroyHeaderButton/SubCategroyHeaderButton';
import Divider from '@mui/material/Divider';
import { makeSelectRoutesNestedCategory } from 'services/routes/selectors';
import {
  makeSelectDashboardCategoryList, makeSelectDashboardSubcategoryList, makeSelectDashboardProductList, makeSelectDashboardProductLoading, makeSelectDashboardSubcategoryLoading, makeSelectDashboardCategoryLoading,
} from 'services/dashboard/selectors';
// import ProductCatagoryIcon from './components/ProductCatagoryIcon';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import Stack from '@mui/material/Stack';
import SwipeableCarouselNew from 'components/SwipeableCarousel/SwipeableCarouselNew';
import { restylePath } from 'utils/path.utils';
import _ from 'lodash';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import { tIntermediateWithoutTranslate } from 'utils/language.utils';
import { tify, sify } from 'chinese-conv';

const DownloadPDFButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: '#F5F5F5',
  border: '1px solid #DCDCDC',
  borderRadius: '25px',
  width: '180px',
  boxShadow: 'inset 0px 4px 4px rgba(208, 208, 210, 0.5)',
  backdropFilter: 'blur(10px)',
  textTransform: 'none',
  position: 'absolute',
  bottom: 0,
}));

function ProductDetail(props) {
  const {
    nestedCategory, categoryList, productList, subcategoryList,
    productLoading, categoryLoading, subcategoryLoading,
  } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id, category } = useParams();
  // const [product, setProduct] = useState(_.find(productList, { id: parseInt(id, 10) }));
  const [activeStep, setActiveStep] = useState(0);
  const [validImage, setValidImage] = useState([]);

  const navigateToCategory = () => {
    navigate(-1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep + 1 < validImage.length) {
        return prevActiveStep + 1;
      }
      return prevActiveStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep - 1 >= 0) {
        return prevActiveStep - 1;
      }
      return prevActiveStep;
    });
  };

  // const getCurrentCategory = useMemo(() => _.find(categoryList, { id: parseInt(category, 10) }), [categoryList]);
  const getCurrentCategory = () => {
    const current = _.find(categoryList, { id: parseInt(category, 10) });
    if (!current) {
      return {
        en: { name: '' },
        zh: { name: '' },
      };
    }
    return current;
  };

  const product = () => {
    const current = _.find(productList, { id: parseInt(id, 10) });
    if (!current) {
      return {
        en: { name: '' },
        zh: { name: '' },
        images: [],
        pdf: null,
      };
    }
    return current;
  };

  useEffect(() => {
  }, [i18n.language]);

  return (
    <Container
      maxWidth="false"
      sx={{
        width: '100vw',
        paddingBottom: '150px',
      }}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        {(!productLoading && !categoryLoading && !subcategoryLoading) && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <SubCategroyHeaderButton
              text={i18n.language === 'en' ? getCurrentCategory().en?.name : tIntermediateWithoutTranslate(i18n, getCurrentCategory().zh?.name)}
              icon={getCurrentCategory().icon}
              onClickEvent={navigateToCategory}
            />
          </Grid>
          <Grid item xs={12} md={12} mt={5} sx={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={7.5}>
                <Typography component="h2" variant="h5">
                  {i18n.language === 'en' ? product().en?.name : tIntermediateWithoutTranslate(i18n, product().zh?.name)}
                </Typography>
                <Divider
                  sx={{
                    border: '1px solid #4CB3AA',
                    width: '100px',
                    marginTop: '5px !important',
                  }}
                />
              </Grid>
              <Grid item xs={3.5}>
                <Stack spacing={5} direction="row">
                  <IconButton onClick={handleBack}>
                    <ArrowBackIosNewIcon />
                  </IconButton>
                  <IconButton onClick={handleNext}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} mt={5} sx={{ width: '100%' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={9}>
                <SwipeableCarouselNew
                  images={_.orderBy(product().images, ['order'], ['asc']) || []}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  validImage={validImage}
                  setValidImage={setValidImage}
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ position: 'relative' }}>
                <Typography variant="h6">
                  {i18n.language === 'en' ? getCurrentCategory()?.en?.name : tIntermediateWithoutTranslate(i18n, getCurrentCategory()?.zh?.name)}
                </Typography>
                <Typography variant="subtitle1" mt={3}>
                  {i18n.language === 'en' ? product().en?.description : tIntermediateWithoutTranslate(i18n, product().zh?.description)}
                </Typography>
                { product().pdf
                && (
                <DownloadPDFButton
                  endIcon={<DownloadIcon />}
                  onClick={() => window.open(product().pdf, '_blank')}
                >
                  {t('products.downloadPdf')}
                </DownloadPDFButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        )}
      </Container>
    </Container>
  );
}

const mapStateToProps = createStructuredSelector({
  nestedCategory: makeSelectRoutesNestedCategory(),
  categoryList: makeSelectDashboardCategoryList(),
  subcategoryList: makeSelectDashboardSubcategoryList(),
  productList: makeSelectDashboardProductList(),
  subcategoryLoading: makeSelectDashboardSubcategoryLoading(),
  categoryLoading: makeSelectDashboardCategoryLoading(),
  productLoading: makeSelectDashboardProductLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

ProductDetail.propTypes = {
  nestedCategory: PropTypes.string,
  productList: PropTypes.arrayOf(PropTypes.shape()),
  categoryList: PropTypes.arrayOf(PropTypes.shape()),
  subcategoryList: PropTypes.arrayOf(PropTypes.shape()),
  categoryLoading: PropTypes.bool.isRequired,
  subcategoryLoading: PropTypes.bool.isRequired,
  productLoading: PropTypes.bool.isRequired,
};

export default compose(
  withConnect,
)(ProductDetail);
