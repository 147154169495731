import React, { useState } from 'react';
import PropTypes from 'prop-types';

export default function ImageWithFallback({
  fallback, callback, src, ...props
}) {
  const [imgSrc, setImgSrc] = useState(src);
  const [fallCount, setFallCount] = useState(0);
  const [isShow, setIsShow] = useState(true);
  const onError = () => {
    if (fallCount === 0) {
      setImgSrc(imgSrc.replace('.jpg', '.jpeg'));
    }
    if (fallCount === 1) {
      setImgSrc(imgSrc.replace('.jpeg', '.png'));
    }
    if (fallCount === 2) { // hit the limit, hide image
      setIsShow(false);
      if (callback) {
        callback(imgSrc.replace('.png', '.jpg'));
      }
    }
    setFallCount(fallCount + 1);
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment
  // return (isShow ? (<img src={imgSrc || fallback} onError={onError} {...props} />) : (<></>));
  return (isShow && (
  <img
    src={imgSrc || fallback}
    // srcSet={imgSrc || fallback}
    onError={onError}
    {...props}
  />
  ));
}

ImageWithFallback.propTypes = {
  fallback: PropTypes.string,
  callback: PropTypes.func,
  src: PropTypes.string,
};
