/* eslint-disable camelcase */
import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';

import {
  getAllOptionSuccess,
  getAllOptionFail,
  updateOptionAboutSuccess,
  updateOptionAboutFail,
  updateOptionContactSuccess,
  updateOptionContactFail,
} from './actions';

import {
  OPTION_GET_ALL,
  OPTION_UPDATE_ABOUT,
  OPTION_UPDATE_CONTACT,
} from './constants';

const api = API.create();

export function* getAllOption() {
  const result = yield call(api.option.getAll, {});
  if (result.ok) {
    yield put(updateOptionAboutSuccess(result.data));
  } else {
    yield put(updateOptionAboutFail(result.error));
  }
}

export function* updateOptionAbout(data) {
  const result = yield call(api.option.updateAbout, data.data);
  if (result.ok) {
    yield put(getAllOptionSuccess(result.data));
  } else {
    yield put(getAllOptionFail(result.error));
  }
}

export function* updateOptionContact(data) {
  const result = yield call(api.option.updateContact, data.data);
  if (result.ok) {
    yield put(updateOptionContactSuccess(result.data));
  } else {
    yield put(updateOptionContactFail(result.error));
  }
}

export default function* optionSaga() {
  yield all([
    takeLatest(OPTION_GET_ALL, getAllOption),
    takeLatest(OPTION_UPDATE_ABOUT, updateOptionAbout),
    takeLatest(OPTION_UPDATE_CONTACT, updateOptionContact),
  ]);
}
