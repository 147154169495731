/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  Outlet,
} from 'react-router-dom';
import _ from 'lodash';
import { makeSelectNewsOutletLoading } from 'services/news/selectors';
import {
  getAll,
} from 'services/news/actions';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function NewsOutlet(props) {
  const { loading } = props;

  useEffect(() => {
    props.getAll();
  }, []);

  return (
    loading ? (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : <Outlet />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectNewsOutletLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAll,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

NewsOutlet.propTypes = {
  loading: PropTypes.bool.isRequired,
  getAll: PropTypes.func,
};

export default compose(
  withConnect,
)(NewsOutlet);
