/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */
import { produce } from 'immer';
import _ from 'lodash';
import {
  PROJECTS_ADD,
  PROJECTS_ADD_SUCCESS,
  PROJECTS_ADD_FAIL,
  PROJECTS_UPDATE,
  PROJECTS_UPDATE_SUCCESS,
  PROJECTS_UPDATE_FAIL,
  PROJECTS_GET_ALL,
  PROJECTS_GET_ALL_SUCCESS,
  PROJECTS_GET_ALL_FAIL,
  PROJECTS_REMOVE,
  PROJECTS_REMOVE_SUCCESS,
  PROJECTS_REMOVE_FAIL,
  PROJECTS_ADD_IMAGE,
  PROJECTS_ADD_IMAGE_SUCCESS,
  PROJECTS_ADD_IMAGE_FAIL,
  PROJECTS_REMOVE_IMAGE,
  PROJECTS_REMOVE_IMAGE_SUCCESS,
  PROJECTS_REMOVE_IMAGE_FAIL,
  PROJECTS_CHANGE_ORDER_IMAGE,
  PROJECTS_CHANGE_ORDER_IMAGE_SUCCESS,
  PROJECTS_CHANGE_ORDER_IMAGE_FAIL,
  PROJECTS_SET_SHOW_SNACKBAR,
} from './constants';

export const initialState = {
  list: [],
  error: null,
  loading: false,
  outletLoading: true,
  snackbar: {
    title: 'Successfully updated',
    isShow: false,
  },
};

const projectsReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case PROJECTS_GET_ALL:
      draft.error = null;
      draft.outletLoading = true;
      break;
    case PROJECTS_REMOVE:
    case PROJECTS_ADD:
    case PROJECTS_UPDATE:
      draft.error = null;
      draft.loading = true;
      break;
    case PROJECTS_GET_ALL_SUCCESS:
      draft.list = action.data;
      draft.error = null;
      draft.outletLoading = false;
      break;
    case PROJECTS_ADD_SUCCESS:
      draft.error = null;
      draft.loading = false;
      window.location.replace('/admin/projects');
      break;
    case PROJECTS_UPDATE_SUCCESS: {
      draft.error = null;
      draft.loading = false;
      draft.snackbar = {
        title: 'Successfully updated',
        isShow: true,
      };
      break;
    }
    case PROJECTS_REMOVE_SUCCESS:
      draft.error = null;
      draft.loading = false;
      window.location.replace('/admin/projects');
      break;
    case PROJECTS_ADD_FAIL:
    case PROJECTS_GET_ALL_FAIL:
    case PROJECTS_REMOVE_FAIL:
    case PROJECTS_UPDATE_FAIL:
      draft.error = action.error;
      draft.loading = false;
      break;
    case PROJECTS_ADD_IMAGE:
    case PROJECTS_CHANGE_ORDER_IMAGE:
    case PROJECTS_REMOVE_IMAGE:
      draft.error = null;
      draft.loading = true;
      break;
    case PROJECTS_ADD_IMAGE_SUCCESS:
    case PROJECTS_REMOVE_IMAGE_SUCCESS:
    case PROJECTS_CHANGE_ORDER_IMAGE_SUCCESS:
      window.location.reload();
      break;
    case PROJECTS_SET_SHOW_SNACKBAR: {
      const temp = _.cloneDeep(draft.snackbar);
      temp.isShow = action.bool;
      draft.snackbar = temp;
      break;
    }
    default:
  }
});

export default projectsReducer;
