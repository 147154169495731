/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useTranslation } from 'react-i18next';
import PageHeaderButton from 'components/PageHeaderButton/PageHeaderButton';
import SubCategroyHeaderButton from 'components/SubCategroyHeaderButton/SubCategroyHeaderButton';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { isChinese, tIntermediate, tIntermediateWithoutTranslate } from 'utils/language.utils';
import { useNavigate, useParams } from 'react-router-dom';
import { setProductsNestedCategory } from 'services/routes/actions';
import {
  makeSelectDashboardCategoryList, makeSelectDashboardSubcategoryList, makeSelectDashboardCategoryLoading, makeSelectDashboardSubcategoryLoading,
} from 'services/dashboard/selectors';
import { getCategoryList, getSubcategoryList } from 'services/dashboard/actions';
import PageContainer from 'components/PageContainer/PageContainer';
import _ from 'lodash';

function Products(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isMiddleCategory, setIsMiddleCategory] = useState(false);
  const [index, setIndex] = useState(0);
  const {
    categoryList, subcategoryList, categoryLoading, subcategoryLoading,
  } = props;

  const getMiddleCategoryById = (id) => _.filter(categoryList, { parent_id: id });
  const hasMiddleCategory = (id) => getMiddleCategoryById(id).length > 0;

  const onClickCategory = (category, i) => {
    setIndex(i);
    if (!hasMiddleCategory(category.id)) {
      navigate(`${category.id}`);
    } else if (hasMiddleCategory(category.id)) {
      setIsMiddleCategory(true);
    } else {
      navigate(`${category.id}`);
      // setIsMiddleCategory(false);
    }
  };

  const getMiddleCategoryList = () => getMiddleCategoryById(categoryList[index].id);

  useEffect(() => {
  }, []);

  return (
    <PageContainer>
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            {isMiddleCategory
              ? (
                <SubCategroyHeaderButton
                  icon={categoryList[index].icon}
                  text={i18n.language === 'en' ? categoryList[index].en?.name : tIntermediateWithoutTranslate(i18n, categoryList[index].zh?.name)}
                  onClick={() => navigate(-1)}
                />
              )
              : <PageHeaderButton text={t('products.header')} />}
          </Grid>
          <Grid item xs={12} md={12} mt={5} sx={{ width: '100%' }}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            mt={5}
            sx={{
              width: '100%',
              overflowY: {
                sm: 'scroll', md: 'scroll', lg: 'visible', xl: 'visible',
              },
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent={
                { sm: 'flex-start', lg: 'flex-start' }
              }
              sx={{
                overflowX: 'scroll',
                height: {
                  sm: 'auto', md: '425px',
                },
              }}
              alignItems="center"
              spacing={2}
            >
              {(isMiddleCategory ? getMiddleCategoryList() : _.filter(categoryList, { parent_id: -1 })).map((category, i) => (
                <Card key={`${isMiddleCategory ? category?.id : category?.name}`} sx={{ minWidth: 210 }}>
                  <CardActionArea
                    onClick={() => onClickCategory(category, i)}
                  >
                    <CardMedia
                      component="img"
                      alt={category.en?.name}
                      height="350"
                      sx={{ width: '215px' }}
                      image={category.image}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {category?.icon && (
                        <img
                          alt="icon"
                          src={category.icon}
                          style={{
                            width: '47px',
                            height: '32px',
                            paddingRight: '10px',
                          }}
                          loading="lazy"
                        />
                        )}
                        { i18n.language === 'en' ? category.en?.name : tIntermediateWithoutTranslate(i18n, category.zh?.name)}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} mt={5} mb={3} sx={{ width: '100%' }}>
            <Divider />
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  categoryList: makeSelectDashboardCategoryList(),
  categoryLoading: makeSelectDashboardCategoryLoading(),
  subcategoryLoading: makeSelectDashboardSubcategoryLoading(),
  subcategoryList: makeSelectDashboardSubcategoryList(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setProductsNestedCategory,
    getCategoryList,
    getSubcategoryList,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Products.propTypes = {
  // setProductsNestedCategory: PropTypes.func,
  categoryList: PropTypes.any,
  subcategoryList: PropTypes.any,
  categoryLoading: PropTypes.bool.isRequired,
  subcategoryLoading: PropTypes.bool.isRequired,
};

export default compose(
  withConnect,
)(Products);
