/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { makeSelectSubcategoryWithi18n, makeSelectSubcategorySubcategoryList } from 'services/subcategory/selectors';
import {
  getAllSubcategory, getOneSubcategoryWithi18n, emptyOneSubcategoryWithi18n, setCategoryId,
} from 'services/subcategory/actions';
import { makeSelectProductsProductsList } from 'services/products/selectors';
import { makeSelectCategoryCategoryWithi18n } from 'services/category/selectors';
import { getAllProducts } from 'services/products/actions';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AdminPageContainer from 'components/AdminPageContainer/AdminPageContainer';
import Button from '@mui/material/Button';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import _ from 'lodash';
import { isMiddleCategory } from 'utils/category.utils';
import { Typography } from '@mui/material';
import SubcategoryHeader from './SubcategoryHeader';
import { TYPE_MIDDLE_SUBCATEGORY, TYPE_SUBCATEGORY } from './constants';

function Subcategory(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { categoryId, subcategoryId } = useParams();
  const { currentSubcategory } = props;
  const isNew = location.state?.new || false;
  const type = location.state?.type || TYPE_SUBCATEGORY;
  // const isMiddleCategory = location.state?.isMiddleCategory || false;

  useEffect(() => {
    props.setCategoryId(categoryId);
    if (!isNew) {
      props.getOneSubcategoryWithi18n(subcategoryId);
      if (isMiddleCategory(type)) {
        // props.getAllSubcategory(subcategoryId);
      } else {
        props.getAllProducts(subcategoryId, 'subcategory');
      }
    }
    return () => props.emptyOneSubcategoryWithi18n();
  }, []);

  const subcategoryColumns = [
    {
      field: 'id',
      headerName: 'Subcategory Id',
      width: 150,
      editable: false,
    },
    {
      field: 'en',
      headerName: 'English',
      width: 150,
      editable: false,
    },
    {
      field: 'zh',
      headerName: 'Chinese(Tranditional)',
      width: 150,
      editable: false,
    },
    {
      field: 'image',
      headerName: 'Image',
      width: 150,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Edit S1',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon sx={{ fontSize: 28 }} />}
          label="Edit"
          onClick={() => {
            // props.getOneSubcategoryWithi18n(params.id);
            window.location.replace(`/admin/products/category/${categoryId}/subcategory/${params.id}`, { state: { new: false } });
          }}
        />,
      ],
    },
  ];

  const productColumns = [
    {
      field: 'id',
      headerName: 'Product Id',
      width: 150,
      editable: false,
    },
    {
      field: 'en_title',
      headerName: 'Title(English)',
      width: 150,
      editable: false,
    },
    {
      field: 'zh_title',
      headerName: 'Title(Tranditional Chinese)',
      width: 200,
      editable: false,
    },
    {
      field: 'images',
      headerName: 'Image',
      width: 150,
      editable: false,
      renderCell: (params) => (
        params.value
          ? (
            <img
              style={{
                width: '100%',
                maxHeight: '225px',
                display: 'block',
                position: 'relative',
                overflow: 'hidden',
              }}
              src={params.value[0].url}
            />
          )
          : <></>
      ),
    },
    {
      field: 'actions',
      headerName: 'Edit',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon sx={{ fontSize: 28 }} />}
          label="Edit"
          onClick={() => {
            window.location.replace(`/admin/products/category/${categoryId}/subcategory/${subcategoryId}/product/${params.id}`, { state: { newProduct: false } });
          }}
        />,
      ],
    },
  ];

  const subcategoryRows = _.filter(props.subcategoryList, ['type', 2]);
  const productRows = props.productsList;
  console.log(currentSubcategory);
  console.log(props.productsList);

  return (
    <AdminPageContainer title={`Products/Subcategory${isMiddleCategory(type) ? '/Middle' : ''}${isNew ? '/new' : `/${currentSubcategory?.en}`}`}>
      {(!_.isEmpty(currentSubcategory) || isNew)
      && (
      <SubcategoryHeader
        currentSubcategory={isNew ? {
          subcategoryId,
        } : currentSubcategory}
        isNew={isNew}
        type={type}
        isMiddleCategory={isMiddleCategory}
      />
      )}
      {!isNew
      && (
      <>
        <Divider
          variant="middle"
          sx={{
            marginBottom: 2,
            borderBottomWidth: 2,
            background: '#00A39C',
          }}
        />
        <Button
          variant="contained"
          sx={{ marginBottom: 2 }}
          onClick={() => {
            props.emptyOneSubcategoryWithi18n();
            if (isMiddleCategory(type)) {
              navigate('new', { state: { new: true, isMiddleCategory: true, type: TYPE_SUBCATEGORY } });
            } else {
              navigate('product/new', { state: { newProduct: true } });
            }
          }}
        >
          {`New ${isMiddleCategory(type) ? 'subcategory' : 'product'}`}
        </Button>
      </>
      )}
      {isMiddleCategory(type) && !isNew
        ? (
          <DataGrid
            rows={subcategoryRows}
            columns={subcategoryColumns}
            columnVisibilityModel={{
              image: !isMiddleCategory(type),
            }}
            pageSize={15}
            rowsPerPageOptions={[15]}
            disableSelectionOnClick
          />
        )
        : (!isNew
          && (
          <DataGrid
            rows={productRows}
            columns={productColumns}
            columnVisibilityModel={{
              image: !isMiddleCategory(type),
            }}
            pageSize={15}
            rowsPerPageOptions={[15]}
            rowHeight={250}
            disableSelectionOnClick
          />
          )
        )}
    </AdminPageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  // error: makeSelectUserError(),
  // loading: makeSelectUserLoading(),
  productsList: makeSelectProductsProductsList(),
  subcategoryList: makeSelectSubcategorySubcategoryList(),
  currentSubcategory: makeSelectSubcategoryWithi18n(),
  currentCategory: makeSelectCategoryCategoryWithi18n(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAllProducts,
    // getAllSubcategory,
    getOneSubcategoryWithi18n,
    emptyOneSubcategoryWithi18n,
    setCategoryId,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Subcategory.propTypes = {
  // loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // error: PropTypes.any,
  productsList: PropTypes.any,
  subcategoryList: PropTypes.any,
  // getAllSubcategory: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  currentSubcategory: PropTypes.shape(),
  // currentCategory: PropTypes.shape(),
  emptyOneSubcategoryWithi18n: PropTypes.func.isRequired,
  getOneSubcategoryWithi18n: PropTypes.func.isRequired,
  setCategoryId: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(Subcategory);
