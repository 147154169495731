import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import userSaga from 'services/user/saga';
import categorySaga from './services/category/saga';
import subcategorySaga from './services/subcategory/saga';
import productsSaga from './services/products/saga';
import dashboardSaga from './services/dashboard/saga';
import optionSaga from './services/option/saga';
import newsSaga from './services/news/saga';
import projectsSaga from './services/projects/saga';
import store from './store';
import Dashboard from './routes/dashboard/Dashboard';
import TestDashboard from './routes/test/TestDashboard';
import Admin from './routes/admin/Admin';

import './i18n';

const browserHistory = createBrowserHistory({ window });

[
  userSaga,
  productsSaga,
  newsSaga,
  categorySaga,
  subcategorySaga,
  dashboardSaga,
  optionSaga,
  projectsSaga,
].map(store.runSaga);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes history={browserHistory}>
        <Route path="/admin/*" element={<Admin />} />
        <Route path="/*" element={<Dashboard />} />
        <Route path="/test/*" element={<TestDashboard />} />
      </Routes>
    </BrowserRouter>
  </Provider>,
);

if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
  module.hot.accept();
}
