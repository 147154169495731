import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import SubCategroyHeaderButton from 'components/SubCategroyHeaderButton/SubCategroyHeaderButton';
import Divider from '@mui/material/Divider';
import { makeSelectRoutesNestedCategory } from 'services/routes/selectors';
// import ProductCatagoryIcon from './components/ProductCatagoryIcon';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import Stack from '@mui/material/Stack';
import SwipeableCarousel from 'components/SwipeableCarousel/SwipeableCarousel';
import { restylePath } from 'utils/path.utils';
import _ from 'lodash';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import { tIntermediate } from 'utils/language.utils';
import { sify } from 'chinese-conv';
import { PRODUCT_LIST } from '../product.constants';

const DownloadPDFButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: '#F5F5F5',
  border: '1px solid #DCDCDC',
  borderRadius: '25px',
  width: '180px',
  boxShadow: 'inset 0px 4px 4px rgba(208, 208, 210, 0.5)',
  backdropFilter: 'blur(10px)',
  textTransform: 'none',
  position: 'absolute',
  bottom: 0,
}));

function ProductDetail(props) {
  const { nestedCategory } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id, category } = useParams();
  const [product, setProduct] = useState(_.filter(PRODUCT_LIST, { id: parseInt(id, 10) }));
  const [activeStep, setActiveStep] = useState(0);
  const [validImage, setValidImage] = useState([]);

  const getCorrectLanguageProduct = () => _.find(product, { language: i18n.language === 'en' ? i18n.language : 'zh' });

  const generateImagePath = (item, index, imageExtension = 'jpg') => {
    let path = `/assets/Products/${restylePath(item.category)}/${!_.isEmpty(item.subcategory) ? `${restylePath(item.subcategory)}/` : ''}${restylePath(item.title)}/${index}.${imageExtension}`;
    if (item.category.toUpperCase() === 'DESKING') {
      path = `/assets/Products/${restylePath(item.category)}/${item.nestedcategory ? `${restylePath(item.nestedcategory)}/` : ''}${!_.isEmpty(item.subcategory) ? `${restylePath(item.subcategory)}/` : ''}${restylePath(item.title)}/${index}.${imageExtension}`;
    }
    return path;
  };

  const generatePdfPath = (item) => {
    const i = _.find(product, { id: item.id, language: 'en' });
    const path = `/assets/Products/${restylePath(i.category)}/${restylePath(i.subcategory)}/${restylePath(i.title)}/${i.pdf}`;
    return path;
  };

  const generateImageList = () => {
    const englishProduct = _.find(product, { language: 'en' });
    const list = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 10; i++) {
      list.push(generateImagePath(englishProduct, i + 1));
    }
    return list;
  };

  const navigateToCategory = () => {
    navigate(-1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep + 1 < validImage.length) {
        return prevActiveStep + 1;
      }
      return prevActiveStep;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep - 1 >= 0) {
        return prevActiveStep - 1;
      }
      return prevActiveStep;
    });
  };

  const getCorrectLanguageText = (text) => (i18n.language === 'cn' ? sify(text) : text);

  useEffect(() => {
  }, [i18n.language]);

  return (
    <Container
      maxWidth="false"
      sx={{
        width: '100vw',
        paddingBottom: '150px',
      }}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <SubCategroyHeaderButton
              text={getCorrectLanguageText(getCorrectLanguageProduct().category)}
              icon={`/assets/Icon/${restylePath(category).replaceAll('-', '')}_c.svg`}
              subText={getCorrectLanguageText(getCorrectLanguageProduct().subcategory)}
              onClickEvent={navigateToCategory}
            />
          </Grid>
          <Grid item xs={12} md={12} mt={5} sx={{ width: '100%' }}>
            <Grid container>
              <Grid item xs={7.5}>
                <Typography component="h2" variant="h5">
                  {getCorrectLanguageText(getCorrectLanguageProduct().title)}
                </Typography>
                <Divider
                  sx={{
                    border: '1px solid #4CB3AA',
                    width: '100px',
                    marginTop: '5px !important',
                  }}
                />
              </Grid>
              <Grid item xs={3.5}>
                <Stack spacing={5} direction="row">
                  <IconButton onClick={handleBack}>
                    <ArrowBackIosNewIcon />
                  </IconButton>
                  <IconButton onClick={handleNext}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} mt={5} sx={{ width: '100%' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={9}>
                <SwipeableCarousel
                  images={generateImageList()}
                  activeStep={activeStep}
                  setActiveStep={setActiveStep}
                  validImage={validImage}
                  setValidImage={setValidImage}
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{ position: 'relative' }}>
                <Typography variant="h6">
                  {getCorrectLanguageText(getCorrectLanguageProduct().category)}
                </Typography>
                <Typography variant="subtitle1" mt={3}>
                  {getCorrectLanguageText(getCorrectLanguageProduct().description)}
                </Typography>
                { getCorrectLanguageProduct().pdf
                && (
                <DownloadPDFButton
                  endIcon={<DownloadIcon />}
                  onClick={() => window.open(generatePdfPath(getCorrectLanguageProduct()), '_blank')}
                >
                  {tIntermediate('products.downloadPdf')}
                </DownloadPDFButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

const mapStateToProps = createStructuredSelector({
  nestedCategory: makeSelectRoutesNestedCategory(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

ProductDetail.propTypes = {
  nestedCategory: PropTypes.string,
};

export default compose(
  withConnect,
)(ProductDetail);
