import {
  PRODUCTS_GET_ALL,
  PRODUCTS_GET_ALL_SUCCESS,
  PRODUCTS_GET_ALL_FAIL,
  PRODUCTS_GET_ONE_WITH_I18N,
  PRODUCTS_GET_ONE_WITH_I18N_SUCCESS,
  PRODUCTS_GET_ONE_WITH_I18N_FAIL,
  PRODUCTS_GET_ONE_WITH_I18N_EMPTY,
  PRODUCTS_UPDATE,
  PRODUCTS_UPDATE_SUCCESS,
  PRODUCTS_UPDATE_FAIL,
  PRODUCTS_ADD,
  PRODUCTS_ADD_SUCCESS,
  PRODUCTS_ADD_FAIL,
  PRODUCTS_REMOVE,
  PRODUCTS_REMOVE_SUCCESS,
  PRODUCTS_REMOVE_FAIL,
  PRODUCTS_ADD_IMAGE,
  PRODUCTS_ADD_IMAGE_SUCCESS,
  PRODUCTS_ADD_IMAGE_FAIL,
  PRODUCTS_REMOVE_IMAGE,
  PRODUCTS_REMOVE_IMAGE_SUCCESS,
  PRODUCTS_REMOVE_IMAGE_FAIL,
  PRODUCTS_CHANGE_ORDER_IMAGE,
  PRODUCTS_CHANGE_ORDER_IMAGE_SUCCESS,
  PRODUCTS_CHANGE_ORDER_IMAGE_FAIL,
  PRODUCTS_REMOVE_PDF,
  PRODUCTS_ADD_PDF,
} from './constants';

export const getAllProducts = (id, sub) => ({
  type: PRODUCTS_GET_ALL,
  id,
  sub,
});

export const getAllProductsSuccess = (data) => ({
  type: PRODUCTS_GET_ALL_SUCCESS,
  data,
});

export const getAllProductsFail = (error) => ({
  type: PRODUCTS_GET_ALL_FAIL,
  error,
});

export const getOneProductsWithi18n = (id) => ({
  type: PRODUCTS_GET_ONE_WITH_I18N,
  id,
});

export const getOneProductsWithi18nSuccess = (data) => ({
  type: PRODUCTS_GET_ONE_WITH_I18N_SUCCESS,
  data,
});

export const emptyOneProductsWithi18n = () => ({
  type: PRODUCTS_GET_ONE_WITH_I18N_EMPTY,
});

export const getOneProductsWithi18nFail = (error) => ({
  type: PRODUCTS_GET_ONE_WITH_I18N_FAIL,
  error,
});

export const updateProducts = (id, product) => ({
  type: PRODUCTS_UPDATE,
  id,
  product,
});

export const updateProductsSuccess = (data) => ({
  type: PRODUCTS_UPDATE_SUCCESS,
  data,
});

export const updateProductsFail = (error) => ({
  type: PRODUCTS_UPDATE_FAIL,
  error,
});

export const addProducts = (subcategoryId, products) => ({
  type: PRODUCTS_ADD,
  subcategoryId,
  products,
});

export const addProductsSuccess = (data) => ({
  type: PRODUCTS_ADD_SUCCESS,
  data,
});

export const addProductsFail = (error) => ({
  type: PRODUCTS_ADD_FAIL,
  error,
});

export const removeProducts = (id) => ({
  type: PRODUCTS_REMOVE,
  id,
});

export const removeProductsSuccess = (data) => ({
  type: PRODUCTS_REMOVE_SUCCESS,
  data,
});

export const removeProductsFail = (error) => ({
  type: PRODUCTS_REMOVE_FAIL,
  error,
});

export const addProductsImage = (productId, product) => ({
  type: PRODUCTS_ADD_IMAGE,
  productId,
  product,
});

export const addProductsImageSuccess = (data) => ({
  type: PRODUCTS_ADD_IMAGE_SUCCESS,
  data,
});

export const addProductsImageFail = (error) => ({
  type: PRODUCTS_ADD_IMAGE_FAIL,
  error,
});

export const removeProductsImage = (id) => ({
  type: PRODUCTS_REMOVE_IMAGE,
  id,
});

export const removeProductsImageSuccess = (data) => ({
  type: PRODUCTS_REMOVE_IMAGE_SUCCESS,
  data,
});

export const removeProductsImageFail = (error) => ({
  type: PRODUCTS_REMOVE_IMAGE_FAIL,
  error,
});

export const changeProductsImageOrder = (product) => ({
  type: PRODUCTS_CHANGE_ORDER_IMAGE,
  product,
});

export const changeProductsImageOrderSuccess = (data) => ({
  type: PRODUCTS_CHANGE_ORDER_IMAGE_SUCCESS,
  data,
});

export const changeProductsImageOrderFail = (error) => ({
  type: PRODUCTS_CHANGE_ORDER_IMAGE_FAIL,
  error,
});

export const addProductsPdf = (productId, product) => ({
  type: PRODUCTS_ADD_PDF,
  productId,
  product,
});

export const removeProductsPdf = (id) => ({
  type: PRODUCTS_REMOVE_PDF,
  id,
});
