/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation, withTranslation } from 'react-i18next';
import SubCategroyHeaderButton from 'components/SubCategroyHeaderButton/SubCategroyHeaderButton';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
// import ProductCategoryIcon from './components/ProductCategoryIcon';
import Pagination from '@mui/material/Pagination';
import {
  useLocation, useParams, useNavigate, useSearchParams,
} from 'react-router-dom';
import _ from 'lodash';
import { makeSelectRoutesNestedCategory } from 'services/routes/selectors';
import {
  makeSelectDashboardCategoryList, makeSelectDashboardSubcategoryList, makeSelectDashboardProductList, makeSelectDashboardProductLoading, makeSelectDashboardSubcategoryLoading, makeSelectDashboardCategoryLoading,
} from 'services/dashboard/selectors';
import { getProductList } from 'services/dashboard/actions';
import { returnCorrectLanguage, tIntermediateWithoutTranslate } from 'utils/language.utils';
import SubCategoryMenuButton from '../components/SubCategoryMenuButton/SubCategoryMenuButton';
import SubCategoryMenu from '../components/SubCategoryMenu/SubCategoryMenu';
import TitlebarBelowImageList from '../components/TitlebarBelowImageList/TitlebarBelowImageList';

function Category(props) {
  const {
    nestedCategory, categoryList, productList, subcategoryList,
    productLoading, categoryLoading, subcategoryLoading,
  } = props;
  const { t, i18n } = useTranslation();
  const { category } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMiddle = searchParams.get('middle') === 'true';
  const navigate = useNavigate();
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [page, setPage] = useState(1);
  const numberPerPage = 12;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const navigateToCategory = () => {
    navigate(-1);
  };

  const getMenu = () => {
    const subcategory = _.filter(subcategoryList, { category_id: category });
    // const subcategory = [subcategoryList];
    subcategory.unshift({
      id: 'all', en: { name: 'All Subcategories' }, zh: { name: '所有產品' }, cn: { name: '所有产品' },
    });
    return subcategory;
  };

  const getProductListByCategory = () => {
    let temp = _.filter(props.productList, { categoryId: parseInt(category, 10) });
    // if (isMiddle) {
    //   const subList = _.filter(subcategoryList, { subId: category }).map((l) => l.id);
    //   temp = _.filter(productList, (p) => subList.includes(p.subcategoryId));
    // }
    // temp.images;
    temp = temp.map((te) => {
      // eslint-disable-next-line no-param-reassign
      const a = _.cloneDeep(te);
      a.images = _.orderBy(te.images, ['order'], ['asc']);
      return a;
    });
    return temp;
  };

  const getProductListBySubcategory = () => {
    if (getMenu()[selectedMenuIndex].id === 'all') {
      return getProductListByCategory();
    }
    return getProductListByCategory().filter((product) => product.subcategoryId === getMenu()[selectedMenuIndex].id);
  };

  const getCurrentCategory = () => {
    const current = _.find(categoryList, { id: parseInt(category, 10) });
    // middle
    // if (current.middle_category.length > 0) {
    //   // current = _.find(subcategoryList, { id: parseInt(category, 10) });
    //   // const parentCategory = _.find(categoryList, { id: parseInt(current.subId, 10) });
    //   // const temp = _.cloneDeep(current);
    //   // temp.icon = parentCategory.icon;
    //   current = categoryList.middle_category;
    // }
    if (!current) {
      return {
        en: { name: '' },
        zh: { name: '' },
      };
    }

    return current;
  };

  const menuText = (index) => {
    const menu = getMenu()[index][returnCorrectLanguage(i18n.language)]?.name;
    return tIntermediateWithoutTranslate(i18n, menu);
  };

  useEffect(() => {
  }, [i18n.language]);

  return (
    <Container
      maxWidth="false"
      disableGutters
      sx={{
        width: '100vw',
        paddingBottom: '50px',
      }}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        {(!productLoading && !categoryLoading && !subcategoryLoading) && (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <SubCategroyHeaderButton
              text={i18n.language === 'en' ? getCurrentCategory().en?.name : tIntermediateWithoutTranslate(i18n, getCurrentCategory().zh?.name)}
              icon={getCurrentCategory().icon}
              onClickEvent={navigateToCategory}
            />
          </Grid>
          <Grid item xs={12} md={12} mt={5} sx={{ width: '100%' }}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12} mt={5} sx={{ width: '100%' }}>
            <Stack spacing={3}>
              <SubCategoryMenuButton
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                disableElevation
                onClick={handleClick}
                text={menuText(selectedMenuIndex)}
              />
              <SubCategoryMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                items={getMenu()}
                selectedMenuIndex={selectedMenuIndex}
                setSelectedMenuIndex={setSelectedMenuIndex}
                setPage={setPage}
                lang={i18n.language}
              />
              <Typography component="h3" variant="h5">
                {menuText(selectedMenuIndex)}
              </Typography>
              <Divider
                sx={{
                  border: '1px solid #4CB3AA',
                  width: '100px',
                  marginTop: '5px !important',
                }}
              />
              {!_.isEmpty(props.productList)
              && (
              <TitlebarBelowImageList
                selectedSubcategory={getMenu()[selectedMenuIndex]}
                productListBySubcategory={getProductListBySubcategory()}
                language={i18n.language}
                navigate={navigate}
              />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} mt={5} mb={3} sx={{ width: '100%' }}>
            <Divider />
          </Grid>
          <Pagination
            shape="rounded"
            count={Math.ceil(getProductListBySubcategory().length / numberPerPage)}
            onChange={handleChangePage}
            hidePrevButton
          />
        </Grid>
        )}
      </Container>
    </Container>
  );
}

const mapStateToProps = createStructuredSelector({
  nestedCategory: makeSelectRoutesNestedCategory(),
  categoryList: makeSelectDashboardCategoryList(),
  productList: makeSelectDashboardProductList(),
  subcategoryList: makeSelectDashboardSubcategoryList(),
  subcategoryLoading: makeSelectDashboardSubcategoryLoading(),
  categoryLoading: makeSelectDashboardCategoryLoading(),
  productLoading: makeSelectDashboardProductLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getProductList,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Category.propTypes = {
  nestedCategory: PropTypes.string,
  productList: PropTypes.arrayOf(PropTypes.shape()),
  categoryList: PropTypes.any,
  subcategoryList: PropTypes.any,
  categoryLoading: PropTypes.bool.isRequired,
  subcategoryLoading: PropTypes.bool.isRequired,
  productLoading: PropTypes.bool.isRequired,
};

export default compose(
  withConnect,
)(Category);
