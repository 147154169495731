export const DASHBOARD_GET_PRODUCTLIST = 'DASHBOARD_GET_PRODUCTLIST';
export const DASHBOARD_GET_PRODUCTLIST_SUCCESS = 'DASHBOARD_GET_PRODUCTLIST_SUCCESS';
export const DASHBOARD_GET_PRODUCTLIST_FAIL = 'DASHBOARD_GET_PRODUCTLIST_FAIL';
export const DASHBOARD_GET_OPTIONLIST = 'DASHBOARD_GET_OPTIONLIST';
export const DASHBOARD_GET_OPTIONLIST_SUCCESS = 'DASHBOARD_GET_OPTIONLIST_SUCCESS';
export const DASHBOARD_GET_OPTIONLIST_FAIL = 'DASHBOARD_GET_OPTIONLIST_FAIL';
export const DASHBOARD_GET_SUBCATEGORYLIST = 'DASHBOARD_GET_SUBCATEGORYLIST';
export const DASHBOARD_GET_SUBCATEGORYLIST_SUCCESS = 'DASHBOARD_GET_SUBCATEGORYLIST_SUCCESS';
export const DASHBOARD_GET_SUBCATEGORYLIST_FAIL = 'DASHBOARD_GET_SUBCATEGORYLIST_FAIL';
export const DASHBOARD_GET_CATEGORYLIST = 'DASHBOARD_GET_CATEGORYLIST';
export const DASHBOARD_GET_CATEGORYLIST_SUCCESS = 'DASHBOARD_GET_CATEGORYLIST_SUCCESS';
export const DASHBOARD_GET_CATEGORYLIST_FAIL = 'DASHBOARD_GET_CATEGORYLIST_FAIL';
export const DASHBOARD_GET_NEWSLIST = 'DASHBOARD_GET_NEWSLIST';
export const DASHBOARD_GET_NEWSLIST_SUCCESS = 'DASHBOARD_GET_NEWSLIST_SUCCESS';
export const DASHBOARD_GET_NEWSLIST_FAIL = 'DASHBOARD_GET_NEWSLIST_FAIL';
export const DASHBOARD_GET_PROJECTLIST = 'DASHBOARD_GET_PROJECTLIST';
export const DASHBOARD_GET_PROJECTLIST_SUCCESS = 'DASHBOARD_GET_PROJECTLIST_SUCCESS';
export const DASHBOARD_GET_PROJECTLIST_FAIL = 'DASHBOARD_GET_PROJECTLIST_FAIL';
