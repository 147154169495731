/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  makeSelectNewsAll,
} from 'services/news/selectors';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AdminPageContainer from 'components/AdminPageContainer/AdminPageContainer';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';

function News(props) {
  const navigate = useNavigate();
  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 150,
      editable: false,
    },
    {
      field: 'en',
      headerName: 'Title(English)',
      width: 150,
      editable: false,
      renderCell: (params) => (
        <>{params.value.name}</>
      ),
    },
    {
      field: 'zh',
      headerName: 'Title(Tranditional Chinese)',
      width: 200,
      editable: false,
      renderCell: (params) => (
        <>{params.value.name}</>
      ),
    },
    {
      field: 'actions',
      headerName: 'Edit',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon sx={{ fontSize: 28 }} />}
          label="Edit"
          onClick={() => {
            navigate(`${params.id}`, { state: { new: false } });
          }}
        />,
      ],
    },
  ];
  const rows = props.list;

  useEffect(() => {
  }, []);

  return (
    <AdminPageContainer title="News" isEnableTitleCallback={false}>
      <Button
        variant="contained"
        sx={{ marginBottom: 2 }}
        onClick={() => navigate('new')}
      >
        New News
      </Button>
      <Divider
        variant="middle"
        sx={{
          marginBottom: 2,
          borderBottomWidth: 2,
          background: '#00A39C',
        }}
      />
      <DataGrid
        rows={rows}
        rowHeight={50}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[15]}
        disableSelectionOnClick
      />
    </AdminPageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  list: makeSelectNewsAll(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

News.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
};

export default compose(
  withConnect,
)(News);
