/* eslint-disable default-param-last */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { updater } from 'utils/reducer';
import _ from 'lodash';

import {
  OPTION_GET_ALL,
  OPTION_GET_ALL_SUCCESS,
  OPTION_GET_ALL_FAIL,
  OPTION_UPDATE_ABOUT,
  OPTION_UPDATE_ABOUT_SUCCESS,
  OPTION_UPDATE_ABOUT_FAIL,
  OPTION_UPDATE_CONTACT,
  OPTION_UPDATE_CONTACT_SUCCESS,
  OPTION_UPDATE_CONTACT_FAIL,
} from './constants';

export const initialState = {
  data: [],
  error: null,
  loading: false,
};

const optionReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case OPTION_GET_ALL:
    case OPTION_UPDATE_ABOUT:
    case OPTION_UPDATE_CONTACT:
      draft.loading = true;
      break;
    case OPTION_GET_ALL_SUCCESS:
      draft.loading = false;
      draft.data = action.data;
      draft.error = null;
      break;
    case OPTION_UPDATE_ABOUT_SUCCESS:
    case OPTION_UPDATE_CONTACT_SUCCESS:
      draft.loading = false;
      draft.data = action.data;
      draft.error = null;
      break;
    case OPTION_GET_ALL_FAIL:
    case OPTION_UPDATE_ABOUT_FAIL:
    case OPTION_UPDATE_CONTACT_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    default:
  }
});

export default optionReducer;
