/* eslint-disable default-param-last */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import {
  ROUTES_PRODUCTS_NESTEDCATEGORY,
} from './constants';

export const initialState = {
  product_nestedCategory: '',
};

const routesReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case 'ROUTES_PRODUCTS_NESTEDCATEGORY':
      draft.product_nestedCategory = action.data;
      break;
    default:
  }
});

export default routesReducer;
