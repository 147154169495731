import * as React from 'react';
import Typography from '@mui/material/Typography';

function Copyright() {
  return (
    <Typography variant="body2" align="center" mt={{ xs: 5, md: 0 }}>
      COPYRIGHT © INNOMOBEL LTD.  ALL RIGHTS RESERVED
      {' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default Copyright;
