/* eslint-disable camelcase */
import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';

import {
  addProjectsSuccess,
  addProjectsFail,
  updateProjectsSuccess,
  updateProjectsFail,
  removeProjectsSuccess,
  removeProjectsFail,
  getAllSuccess,
  getAllFail,
  addProjectsImageSuccess,
  addProjectsImageFail,
  removeProjectsImageSuccess,
  removeProjectsImageFail,
  changeProjectsImageOrderSuccess,
  changeProjectsImageOrderFail,
} from './actions';

import {
  PROJECTS_ADD,
  PROJECTS_REMOVE,
  PROJECTS_UPDATE,
  PROJECTS_GET_ALL,
  PROJECTS_ADD_IMAGE,
  PROJECTS_REMOVE_IMAGE,
  PROJECTS_CHANGE_ORDER_IMAGE,
} from './constants';

const api = API.create();

export function* addProjects(data) {
  const result = yield call(api.projects.add, data.data);
  if (result.ok) {
    yield put(addProjectsSuccess(result.data));
  } else {
    yield put(addProjectsFail(result.data));
  }
}

export function* updateProjects(data) {
  const result = yield call(api.projects.update, data.data);
  if (result.ok) {
    yield put(updateProjectsSuccess(result.data));
  } else {
    yield put(updateProjectsFail(result.data));
  }
}

export function* removeProjects(data) {
  const result = yield call(api.projects.remove, data.data);
  if (result.ok) {
    yield put(removeProjectsSuccess(result.data));
  } else {
    yield put(removeProjectsFail(result.data));
  }
}

export function* getAll() {
  const result = yield call(api.projects.getAll, {});
  if (result.ok) {
    yield put(getAllSuccess(result.data));
  } else {
    yield put(getAllFail(result.data));
  }
}

export function* addProjectsImage(data) {
  const result = yield call(api.projects.addImage, data.projectId, data.project);
  if (result.ok) {
    yield put(addProjectsImageSuccess(result.data));
  } else {
    yield put(addProjectsImageFail(result.data));
  }
}

export function* removeProjectsImage(data) {
  const result = yield call(api.projects.removeImage, { id: data.id });
  if (result.ok) {
    yield put(removeProjectsImageSuccess(result.data));
  } else {
    yield put(removeProjectsImageFail(result.data));
  }
}

export function* changeProjectsImageOrder(data) {
  const result = yield call(api.projects.changeImageOrder, data.project);
  if (result.ok) {
    yield put(changeProjectsImageOrderSuccess(result.data));
  } else {
    yield put(changeProjectsImageOrderFail(result.data));
  }
}

export default function* projectsSaga() {
  yield all([
    takeLatest(PROJECTS_ADD, addProjects),
    takeLatest(PROJECTS_REMOVE, removeProjects),
    takeLatest(PROJECTS_UPDATE, updateProjects),
    takeLatest(PROJECTS_GET_ALL, getAll),
    takeLatest(PROJECTS_ADD_IMAGE, addProjectsImage),
    takeLatest(PROJECTS_REMOVE_IMAGE, removeProjectsImage),
    takeLatest(PROJECTS_CHANGE_ORDER_IMAGE, changeProjectsImageOrder),
  ]);
}
