/* eslint-disable react/forbid-prop-types */
import React, { Suspense, useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  Routes,
  Route,
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getOptionList } from 'services/dashboard/actions';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Home from './Home/Home';
import Contact from './Contact/Contact';
import Products from './Products/Products';
import Category from './Products/Category/Category';
import About from './About/About';
import News from './News/News';
import Projects from './Projects/Projects';
import ProductDetail from './Products/ProductDetail/ProductDetail';
import ProductsContainer from './Products/ProductsContainer';
import ESG from './ESG/ESG';

const theme = createTheme();

function TestDashboard(props) {
  useEffect(() => {
    props.getOptionList();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback="loading">
        <Header />
        <main>
          <Routes>
            <Route index element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="about" element={<About />} />
            <Route path="news" element={<News />} />
            <Route path="projects" element={<Projects />} />
            <Route path="products" element={<ProductsContainer />}>
              <Route index element={<Products />} />
              <Route path=":category" element={<Category />} />
              <Route path=":category/:id" element={<ProductDetail />} />
            </Route>
            <Route path="ESG" element={<ESG />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </main>
        <Footer />
      </Suspense>
    </ThemeProvider>
  );
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getOptionList,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

TestDashboard.propTypes = {
  getOptionList: PropTypes.func,
};

export default compose(
  withConnect,
)(TestDashboard);
