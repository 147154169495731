import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import StarIcon from 'assets/Icon/CatagoryIcon.svg';

const PageHeaderButton = styled(({ text, ...other }) => (
  <Button
    {...other}
    startIcon={(
      <SvgIcon component={StarIcon} inheritViewBox />
  )}
  >
    {text}
  </Button>
))(({ theme }) => ({
  color: '#4CB3AA',
  backgroundColor: '#FFFFFF',
  border: '1px solid #FFFFFF',
  borderRadius: 25,
  width: '180px',
  textTransform: 'none',
  boxShadow: '0px 4px 4px rgba(208, 208, 210, 0.5)',
  fontWeight: 800,
  fontSize: '20px',
  fontStyle: 'normal',
  padding: '0px 10px 0px 10px',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #FFFFFF',
  },
  '& .MuiButton-startIcon': {
    marginRight: '5px',
    paddingLeft: '5px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  },
}));

export default PageHeaderButton;
