/* eslint-disable camelcase */
import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';

import {
  loginSuccess,
  loginFail,
  getProfile,
  getProfileSuccess,
  getProfileFail,
  getAllUserSuccess,
  getAllUserFail,
  getUserByIdSuccess,
  getUserByIdFail,
  createUserSuccess,
  createUserFail,
  updateUserSuccess,
  updateUserFail,
  updateUserPasswordSuccess,
  updateUserPasswordFail,
  removeUserSuccess,
  removeUserFail,
} from './actions';

import {
  USER_LOGIN,
  USER_GET_PROFILE,
  USER_GET_ALL_USER,
  USER_GET_USER_BY_ID,
  USER_CREATE,
  USER_UPDATE,
  USER_UPDATE_PASSWORD,
  USER_REMOVE,
} from './constants';

const api = API.create();

export function* login(data) {
  const { callback } = data;
  const result = yield call(api.auth.login, data.data);
  if (result.ok) {
    yield put(loginSuccess(result.data));
    yield put(getProfile(callback));
  } else {
    yield put(loginFail(result.data));
  }
}

export function* getProfileSaga(data) {
  const { callback } = data;
  const result = yield call(api.auth.profile, {});
  if (result.ok) {
    yield put(getProfileSuccess(result.data));
    callback();
  } else {
    yield put(getProfileFail(result.data));
  }
}

export function* getAllUser() {
  const result = yield call(api.user.getAllUser);
  if (result.ok) {
    yield put(getAllUserSuccess(result.data));
  } else {
    yield put(getAllUserFail(result.data));
    window.location.replace('/admin/login');
  }
}

export function* getUserById(data) {
  const { id } = data;
  const result = yield call(api.user.getUserById, id);
  if (result.ok) {
    yield put(getUserByIdSuccess(result.data));
  } else {
    yield put(getUserByIdFail(result.data));
  }
}

export function* createUser(data) {
  const { user } = data;
  const result = yield call(api.user.register, user);
  if (result.ok) {
    yield put(createUserSuccess(result.data));
    window.location.replace('/admin/user');
  } else {
    yield put(createUserFail(result.data));
  }
}

export function* updateUser(data) {
  const { id, user } = data;
  const result = yield call(api.user.update, { id, data: user });
  if (result.ok) {
    yield put(updateUserSuccess(result.data));
    window.location.replace('/admin/user');
  } else {
    yield put(updateUserFail(result.data));
  }
}

export function* removeUser(data) {
  const { id } = data;
  const result = yield call(api.user.remove, { id });
  if (result.ok) {
    yield put(removeUserSuccess(result.data));
    window.location.replace('/admin/user');
  } else {
    yield put(removeUserFail(result.data));
  }
}

export function* updateUserPassword(data) {
  const { id, user } = data;
  const result = yield call(api.user.updatePassword, { id, user });
  if (result.ok) {
    yield put(updateUserPasswordSuccess(result.data));
    window.location.replace('/admin/user');
  } else {
    yield put(updateUserPasswordFail(result.data));
  }
}

export default function* userSaga() {
  yield all([
    takeLatest(USER_CREATE, createUser),
    takeLatest(USER_LOGIN, login),
    takeLatest(USER_GET_PROFILE, getProfileSaga),
    takeLatest(USER_GET_ALL_USER, getAllUser),
    takeLatest(USER_GET_USER_BY_ID, getUserById),
    takeLatest(USER_UPDATE, updateUser),
    takeLatest(USER_REMOVE, removeUser),
    takeLatest(USER_UPDATE_PASSWORD, updateUserPassword),
  ]);
}
