export const restylePath = (path) => {
  let arr = path.toLowerCase();

  arr = arr.replaceAll('#', '%23').replaceAll('/', '');
  arr = (arr.split('-').map((e) => e.charAt(0).toUpperCase() + e.slice(1))).join('-');
  arr = arr.split(' ');

  for (let i = 0; i < arr.length; i += 1) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
};
