import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import { makeSelectOptionAll, makeSelectOptionError } from 'services/option/selectors';
import { updateOptionAbout } from 'services/option/actions';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import AdminPageContainer from 'components/AdminPageContainer/AdminPageContainer';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import {
  EditorState, convertToRaw, convertFromHTML, ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './About.scss';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import _ from 'lodash';
import { LANG } from '../constants';

function About(props) {
  const { error, data } = props;
  const [newImage, setNewImage] = useState(null);
  const [about, setAbout] = useState(data);
  const blocksFromHtmlEn = htmlToDraft(_.find(data, (o) => o.option === 'about_content' && o.lang.id === LANG.EN)?.value || '');
  const blocksFromHtmlZh = htmlToDraft(_.find(data, (o) => o.option === 'about_content' && o.lang.id === LANG.ZH)?.value || '');
  const [valueEn, setValueEn] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHtmlEn.contentBlocks, blocksFromHtmlEn.entityMap)));
  const [valueZh, setValueZh] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHtmlZh.contentBlocks, blocksFromHtmlZh.entityMap)));
  const customziedToolbar = { options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker'] };

  const getAboutContent = (key, langId) => _.find(about, (o) => o.option === key && o.lang.id === langId);
  const getAboutKeyIndex = (key, langId) => _.findIndex(about, (o) => o.option === key && o.lang.id === langId);

  const handleChangeValue = (event) => {
    const { name, value } = event.target;
    const newAbout = _.cloneDeep(about);
    switch (name) {
      case 'about_page_name_en': {
        const index = getAboutKeyIndex('about_page_name', LANG.EN);
        newAbout[index].value = value;
        break;
      }
      case 'about_page_name_zh': {
        const index = getAboutKeyIndex('about_page_name', LANG.ZH);
        newAbout[index].value = value;
        break;
      }
      default:
    }
    setAbout(newAbout);
  };

  const handleFileChange = (event) => {
    const { files } = event.target;
    const [file] = files;
    if (file) {
      setNewImage(file);
    }
  };

  useEffect(() => {
  }, []);

  return (
    <AdminPageContainer title="About" isEnableTitleCallback={false}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { width: '50ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          required
          id="filled-required"
          label="Page Name(en):"
            // defaultValue="About"
          value={getAboutContent('about_page_name', LANG.EN)?.value}
          name="about_page_name_en"
          onChange={handleChangeValue}
          variant="filled"
          helperText="Required*"
          sx={{ mr: 1 }}
        />
        <TextField
          required
          id="filled-required"
          name="about_page_name_zh"
          onChange={handleChangeValue}
          label="Page Name(zh):"
          value={getAboutContent('about_page_name', LANG.ZH)?.value}
          variant="filled"
          helperText="Required*"
        />
      </Box>
      <Typography variant="h6" gutterBottom>
        Content (en):
      </Typography>
      <div className="">
        <Editor
          editorState={valueEn}
          toolbarClassName="rdw-storybook-toolbar"
          wrapperClassName="rdw-storybook-wrapper"
          editorClassName="editor"
          onEditorStateChange={setValueEn}
          toolbar={customziedToolbar}
        />
      </div>
      <Typography variant="h6" gutterBottom mt={1}>
        Content (zh):
      </Typography>
      <div className="">
        <Editor
          editorState={valueZh}
          toolbarClassName="rdw-storybook-toolbar"
          wrapperClassName="rdw-storybook-wrapper"
          editorClassName="editor"
          onEditorStateChange={setValueZh}
          toolbar={customziedToolbar}
        />
      </div>
      <Button
        variant="contained"
        sx={{ marginTop: 2 }}
        onClick={() => {
          const en = draftToHtml(convertToRaw(valueEn.getCurrentContent()));
          const zh = draftToHtml(convertToRaw(valueZh.getCurrentContent()));
          const temp = {
            en_page_name: getAboutContent('about_page_name', LANG.EN).value,
            zh_page_name: getAboutContent('about_page_name', LANG.ZH).value,
            en_content_value: en,
            zh_content_value: zh,
            newImage,
          };
          props.updateOptionAbout(temp);
        }}
      >
        Update
      </Button>
      <br />
      <br />
      <img
        width="1000"
        src={newImage ? URL.createObjectURL(newImage) : getAboutContent('about_content_image', LANG.EN)?.value}
      />
      <br />
      <br />
      <Button
        size="small"
        variant="outlined"
        component="label"
      >
        Upload New Image
        <input
          accept="image/*"
          type="file"
          name="image"
          hidden
          onChange={handleFileChange}
        />
      </Button>
      <br />
      {/* <div
        // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: data }}
        /> */}
    </AdminPageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectOptionError(),
  data: makeSelectOptionAll(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    // getAllUser,
    updateOptionAbout,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

About.propTypes = {
  updateOptionAbout: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    option: PropTypes.string,
    value: PropTypes.string,
  })),
};

export default compose(
  withConnect,
)(About);
