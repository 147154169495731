/* eslint-disable default-param-last */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { produce } from 'immer';
import { updater } from 'utils/reducer';
import _ from 'lodash';
import {
  SUBCATEGORY_GET_ALL,
  SUBCATEGORY_GET_ALL_SUCCESS,
  SUBCATEGORY_GET_ALL_FAIL,
  SUBCATEGORY_GET_ONE_WITH_I18N,
  SUBCATEGORY_GET_ONE_WITH_I18N_SUCCESS,
  SUBCATEGORY_GET_ONE_WITH_I18N_FAIL,
  SUBCATEGORY_GET_ONE_WITH_I18N_EMPTY,
  SUBCATEGORY_UPDATE,
  SUBCATEGORY_UPDATE_SUCCESS,
  SUBCATEGORY_UPDATE_FAIL,
  SUBCATEGORY_ADD,
  SUBCATEGORY_ADD_SUCCESS,
  SUBCATEGORY_ADD_FAIL,
  SUBCATEGORY_REMOVE,
  SUBCATEGORY_REMOVE_SUCCESS,
  SUBCATEGORY_REMOVE_FAIL,
  SUBCATEGORY_SET_CATEGORY_ID,
} from './constants';

export const initialState = {
  subcategoryList: [],
  currentSubcategory: {},
  categoryId: '',
  error: null,
  loading: false,
};

const subcategoryReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case SUBCATEGORY_SET_CATEGORY_ID:
      // console.log(action);
      draft.categoryId = action.id;
      break;
    case SUBCATEGORY_GET_ALL:
    case SUBCATEGORY_UPDATE:
      draft.loading = true;
      break;
    case SUBCATEGORY_GET_ALL_SUCCESS:
      draft.loading = false;
      draft.subcategoryList = action.data;
      draft.error = null;
      break;
    case SUBCATEGORY_GET_ALL_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case SUBCATEGORY_GET_ONE_WITH_I18N:
      draft.loading = true;
      break;
    case SUBCATEGORY_GET_ONE_WITH_I18N_SUCCESS:
      draft.loading = false;
      console.log(action.data);
      draft.currentSubcategory = action.data;
      draft.error = null;
      break;
    case SUBCATEGORY_GET_ONE_WITH_I18N_FAIL:
    case SUBCATEGORY_UPDATE_FAIL:
    case SUBCATEGORY_ADD_FAIL:
    case SUBCATEGORY_REMOVE_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case SUBCATEGORY_GET_ONE_WITH_I18N_EMPTY:
      draft.currentSubcategory = {};
      break;
    case SUBCATEGORY_UPDATE_SUCCESS:
    case SUBCATEGORY_REMOVE_SUCCESS:
    case SUBCATEGORY_ADD_SUCCESS: {
      // const { subId, type } = action.data;
      const { categoryId } = state;
      const path = `/admin/products/category/${categoryId}`;
      // if (type === 2) {
      //   path = `${path}/subcategory/${subId}`;
      // }
      window.location.replace(path);
      break;
    }
    case SUBCATEGORY_ADD:
      draft.loading = true;
      break;
    case SUBCATEGORY_REMOVE:
      draft.loading = true;
      break;
    default:
  }
});

export default subcategoryReducer;
