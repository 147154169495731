/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-danger */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Container from '@mui/material/Container';
import BackgroundImage from 'assets/Background/Projects.png';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import PageHeaderButton from 'components/PageHeaderButton/PageHeaderButton';
import { isChinese } from 'utils/language.utils';
import PageContainer from 'components/PageContainer/PageContainer';
import InformationCardContent from 'components/InformationCardContent/InformationCardContent';
import InformationModal from 'components/InformationModal/InformationModal';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  makeSelectDashboardProjectList,
} from 'services/dashboard/selectors';
import { getProjectList } from 'services/dashboard/actions';
import { PROJECTS_LIST } from './constants';

function Projects(props) {
  const { projectList } = props;
  const { t, i18n } = useTranslation();
  const AddMore = 10;

  const [open, setOpen] = useState(false);
  const [activeProjects, setActiveProjects] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState(AddMore);

  const imagePrefix = (project) => `/assets/Projects/${project.title.replaceAll('\'', '_').replaceAll('&', '_')}_${project.date}/`;

  const getActiveProjectsList = (projectCount) => {
    const list = PROJECTS_LIST.filter((x) => x.language === i18n.language && !_.isEmpty(x.title));
    if (projectCount) {
      return _.take(list, projectCount);
    }
    return list;
  };

  const getEnglishProjectList = () => PROJECTS_LIST.filter((x) => x.language === 'en');

  // const handleOpen = (id) => {
  //   const temp = _.cloneDeep(_.find(getActiveProjectsList(), { id }));
  //   const tempEnglish = _.find(getEnglishProjectList(), { id: temp.id });
  //   temp.images = tempEnglish.images.map((x) => `${imagePrefix(tempEnglish)}${x}`);
  //   setActiveProjects(temp);
  //   setOpen(true);
  // };

  const getCorrectLanguageFromChinese = () => (i18n.language === 'cn' ? 'zh' : i18n.language);

  const handleOpen = (id) => {
    const re = _.cloneDeep(_.find(projectList, { id }));
    re.date = re.date.split('T')[0];
    re.title = re[getCorrectLanguageFromChinese()].name;
    re.description = re[getCorrectLanguageFromChinese()].description;
    re.images = re.images ? re.images.map((x) => x.url) : null;
    setActiveProjects(re);
    setOpen(true);
  };

  const getImages = (project) => {
    if (isChinese(i18n.language)) {
      const { id } = project;
      const temp = _.find(getEnglishProjectList(), { id });
      return temp.images;
    }
    return project.images;
  };

  const handleClose = () => setOpen(false);

  const fetchMoreData = () => {
    if (count + AddMore >= projectList.length) {
      setHasMore(false);
    }

    setTimeout(() => {
      setCount(count + AddMore);
    }, 200);
  };

  useState(() => {
    props.getProjectList();
  }, []);

  return (
    <PageContainer
      backgroundImage={BackgroundImage}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <PageHeaderButton text={t('projects.header')} />
          </Grid>
          <Grid item xs={12} md={12} mt={2} sx={{ width: { xs: '100%' } }}>
            <InfiniteScroll
              dataLength={projectList.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<h3>Loading...</h3>}
            >
              {projectList.map((project) => (
                <InformationCardContent
                  key={project.id}
                  // image={`${imagePrefix(_.find(getEnglishProjectList(), { id: project.id }))}${getImages(project)[0]}` || ''}
                  image={project.images ? project.images[0].url : null}
                  date={project.date.split('T')[0]}
                  title={project[getCorrectLanguageFromChinese()].name}
                  description={project[getCorrectLanguageFromChinese()].description}
                  callback={() => handleOpen(project.id)}
                />
              ))}
            </InfiniteScroll>
          </Grid>
        </Grid>
      </Container>
      <InformationModal open={open} handleClose={handleClose} data={activeProjects} />
    </PageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  projectList: makeSelectDashboardProjectList(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getProjectList,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Projects.propTypes = {
  projectList: PropTypes.any,
  getProjectList: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(Projects);
