import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useTranslation } from 'react-i18next';
import PageHeaderButton from 'components/PageHeaderButton/PageHeaderButton';
import SubCategroyHeaderButton from 'components/SubCategroyHeaderButton/SubCategroyHeaderButton';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { restylePath } from 'utils/path.utils';
import { useNavigate, useParams } from 'react-router-dom';
import { setProductsNestedCategory } from 'services/routes/actions';
import PageContainer from 'components/PageContainer/PageContainer';
import { tIntermediate } from 'utils/language.utils';
import { sify } from 'chinese-conv';
import { PRODUCT_CATEGORY, PRODUCT_SUBCATEGORY } from './constants';

function Products(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isSubCategory, setIsSubCategory] = useState(false);

  const onClickCategory = (category) => {
    const { hasSubCategory } = category;
    if (isSubCategory) {
      props.setProductsNestedCategory(category.nestedCategory);
    }
    if (hasSubCategory) {
      setIsSubCategory(true);
    } else {
      navigate(`${category.category}`);
    }
  };

  const navigateToCategory = () => {
    navigate(-1);
  };

  const getLanguageText = (category) => {
    // if (i18n.language === 'en')
    if (i18n.language === 'zh') return category.title_zh;
    if (i18n.language === 'cn') return sify(category.title_zh);
    return category.title;
  };

  useEffect(() => {
  });

  return (
    <PageContainer>
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            {isSubCategory
              ? (
                <SubCategroyHeaderButton
                  icon={`/assets/Icon/${restylePath('Desking').replaceAll('-', '')}_c.svg`}
                  text={tIntermediate('products.category.desking')}
                  onClickEvent={navigateToCategory}
                />
              )
              : <PageHeaderButton text={tIntermediate('products.header')} />}
          </Grid>
          <Grid item xs={12} md={12} mt={5} sx={{ width: '100%' }}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            mt={5}
            sx={{
              width: '100%',
              overflowY: {
                sm: 'scroll', md: 'scroll', lg: 'visible', xl: 'visible',
              },
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent={
                { md: 'flex-start', lg: 'center' }
              }
              alignItems="center"
              spacing={2}
            >
              {(isSubCategory ? PRODUCT_SUBCATEGORY : PRODUCT_CATEGORY).map((category) => (
                <Card key={`${category.category}-${category.title}`} sx={{ minWidth: 215 }}>
                  <CardActionArea
                    onClick={() => onClickCategory(category)}
                  >
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      height="350"
                      image={category.image}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {!isSubCategory && (
                        <img
                          alt="icon"
                          src={category.icon}
                          style={{
                            width: '47px',
                            height: '32px',
                            paddingRight: '10px',
                          }}
                          loading="lazy"
                        />
                        )}
                        {getLanguageText(category)}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} mt={5} mb={3} sx={{ width: '100%' }}>
            <Divider />
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setProductsNestedCategory,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Products.propTypes = {
  setProductsNestedCategory: PropTypes.func,
};

export default compose(
  withConnect,
)(Products);
