import * as React from 'react';
import { PropTypes } from 'prop-types';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

function CustomNavLink({
  to, primary, icon, disable = false,
}) {
  const handleClick = (e) => {
    if (disable) e.preventDefault();
  };
  return (
    <NavLink
      onClick={handleClick}
      to={to}
      style={{
        textDecoration: 'none',
      }}
    >
      <ListItemButton disabled={disable}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={primary}
          style={{
            color: 'black',
          }}
        />
      </ListItemButton>
    </NavLink>
  );
}

CustomNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  disable: PropTypes.bool.isRequired,
};

export default CustomNavLink;
