/* eslint-disable camelcase */
import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';
import {
  getAllSubcategorySuccess,
  getAllSubcategoryFail,
  getOneSubcategoryWithi18nSuccess,
  getOneSubcategoryWithi18nFail,
  updateSubcategorySuccess,
  updateSubcategoryFail,
  addSubcategorySuccess,
  addSubcategoryFail,
  removeSubcategorySuccess,
  removeSubcategoryFail,
} from './actions';

import {
  SUBCATEGORY_GET_ALL,
  SUBCATEGORY_GET_ONE_WITH_I18N,
  SUBCATEGORY_UPDATE,
  SUBCATEGORY_ADD,
  SUBCATEGORY_REMOVE,
} from './constants';

const api = API.create();

export function* getAllSubcategory(data) {
  const { id } = data;
  const result = yield call(api.subcategory.getAll, id);
  if (result.ok) {
    yield put(getAllSubcategorySuccess(result.data));
  } else {
    yield put(getAllSubcategoryFail(result.error));
  }
}

export function* getOneWithi18n(data) {
  const { id } = data;
  const result = yield call(api.subcategory.getOneWithi18n, id);
  if (result.ok) {
    yield put(getOneSubcategoryWithi18nSuccess(result.data));
  } else {
    yield put(getOneSubcategoryWithi18nFail(result.error));
  }
}

export function* updateSubcategory(data) {
  const { id, subcategory } = data;
  const result = yield call(api.subcategory.update, { id, subcategory });
  if (result.ok) {
    yield put(updateSubcategorySuccess(result.data));
  } else {
    yield put(updateSubcategoryFail(result.data));
  }
}

export function* addSubcategory(data) {
  const { categoryId, subcategory } = data;
  const result = yield call(api.subcategory.add, { category_id: categoryId, subcategory });
  if (result.ok) {
    yield put(addSubcategorySuccess(result.data));
  } else {
    yield put(addSubcategoryFail(result.data));
  }
}

export function* removeSubcategory(data) {
  const { id } = data;
  const result = yield call(api.subcategory.remove, { id });
  if (result.ok) {
    yield put(removeSubcategorySuccess(result.data));
  } else {
    yield put(removeSubcategoryFail(result.data));
  }
}

export default function* subcategorySaga() {
  yield all([
    takeLatest(SUBCATEGORY_GET_ONE_WITH_I18N, getOneWithi18n),
    takeLatest(SUBCATEGORY_GET_ALL, getAllSubcategory),
    takeLatest(SUBCATEGORY_UPDATE, updateSubcategory),
    takeLatest(SUBCATEGORY_ADD, addSubcategory),
    takeLatest(SUBCATEGORY_REMOVE, removeSubcategory),
  ]);
}
