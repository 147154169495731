export const PRODUCT_CATEGORY = [
  {
    title: 'Desking',
    title_zh: '桌子',
    category: 'desking',
    icon: '/assets/Icon/Desking.svg',
    image: '/assets/Category/Desking.png',
    hasSubCategory: true,
  },
  {
    title: 'Office Chairs',
    title_zh: '辦公椅',
    category: 'office-chair',
    icon: '/assets/Icon/OfficeChair.svg',
    image: '/assets/Category/OfficeChair.png',
  },
  {
    title: 'Storage',
    title_zh: '貯存空間',
    category: 'storage',
    icon: '/assets/Icon/Storage.svg',
    image: '/assets/Category/Storage.png',
  },
  {
    title: 'Sofa',
    title_zh: '梳化',
    category: 'sofa',
    icon: '/assets/Icon/Sofa.svg',
    image: '/assets/Category/Sofa.png',
  },
  {
    title: 'Leisure',
    title_zh: '休閒傢具',
    category: 'leisure',
    icon: '/assets/Icon/Leisure.svg',
    image: '/assets/Category/Leisure.png',
  },
  {
    title: 'Phone Booth',
    title_zh: '電話亭',
    category: 'phone-booth',
    icon: '/assets/Icon/PhoneBooth.svg',
    image: '/assets/Category/PhoneBooth.png',
  },
];

export const PRODUCT_SUBCATEGORY = [
  {
    title: 'Workstation',
    title_zh: '工作站',
    nestedCategory: 'workstation',
    category: 'desking',
    image: '/assets/Type/Workstation.png',
  },
  {
    title: 'Executive Desk',
    title_zh: '行政桌',
    nestedCategory: 'executive desk',
    category: 'desking',
    image: '/assets/Type/ExecutiveDesk.png',
  },
  {
    title: 'Conference',
    title_zh: '會議',
    nestedCategory: 'conference',
    category: 'desking',
    image: '/assets/Type/Conference.png',
  },
  {
    title: 'Height Adjustable Desk',
    title_zh: '高度調節升降檯',
    nestedCategory: 'height adjustable desk',
    category: 'desking',
    image: '/assets/Type/HeightAdjustableDesk.png',
  },
];

export const CATEGORY_LIST = {
  DESKING: {
    en: 'Desking',
    zh: '桌子',
  },
  'OFFICE CHAIR': {
    en: 'Office Chair',
    zh: '辦公椅',
  },
  LEISURE: {
    en: 'Leisure',
    zh: '休閒傢具',
  },
  SOFA: {
    en: 'Sofa',
    zh: '梳化',
  },
  'PHONE BOOTH': {
    en: 'Phone Booth',
    zh: '電話亭',
  },
  STORAGE: {
    en: 'Storage',
    zh: '貯存空間',
  },
};
