import * as React from 'react';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { PropTypes } from 'prop-types';

function ErrorComponent(props) {
  const { error } = props;
  if (typeof error.message === 'string') {
    return (
      <Typography variant="subtitle1" component="h6" sx={{ color: 'red', marginTop: 1, marginBottom: 1 }}>{`*${error?.message}`}</Typography>
    );
  }
  return error?.message.map((m) => <Typography key={m} variant="subtitle1" component="h6" sx={{ color: 'red', marginTop: 1, marginBottom: 1 }}>{`*${m}`}</Typography>);
}

ErrorComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
};

export default ErrorComponent;
