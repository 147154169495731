import * as React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';

const PageContainer = styled(({ backgroundImage, ...other }) => (
  <Container
    {...other}
    maxWidth="false"
    disableGutters
    sx={{
      background: `url(${backgroundImage}) no-repeat center center fixed`,
    }}
  />
))(({ theme }) => ({
  width: '100vw',
  backgroundSize: 'cover',
  paddingBottom: '150px',
}));

export default PageContainer;
