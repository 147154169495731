import * as React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import InfoIcon from '@mui/icons-material/Info';
import SellIcon from '@mui/icons-material/Sell';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import StoreIcon from '@mui/icons-material/Store';
import CustomNavLink from 'components/CustomNavLink/CustomNavLink';

export const mainListItems = (
  <>
    <CustomNavLink to="" primary="Home" icon={<DashboardIcon />} disable={false} />
    <CustomNavLink to="user" primary="User" icon={<PersonIcon />} disable={false} />
    <CustomNavLink to="products" primary="Products" icon={<StoreIcon />} disable={false} />
    <CustomNavLink to="contact" primary="Contact" icon={<ContactPageIcon />} disable={false} />
    <CustomNavLink to="about" primary="About" icon={<InfoIcon />} disable={false} />
    <CustomNavLink to="news" primary="News" icon={<NewspaperIcon />} disable={false} />
    <CustomNavLink to="projects" primary="Projects" icon={<NewspaperIcon />} disable={false} />
    {/* <CustomNavLink to="promotion" primary="Promotion" icon={<SellIcon />} disable={false} /> */}
    {/* <CustomNavLink to="projects" primary="Projects" icon={<NewspaperIcon />} disable /> */}
    {/* <CustomNavLink to="/career" primary="Career" icon={<DashboardIcon />} />
    <CustomNavLink to="/job-reference" primary="Job Reference" icon={<DashboardIcon />} /> */}
  </>
);
