import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import { makeSelectCategoryError, makeSelectCategorLoading } from 'services/category/selectors';
import { updateCategory, addCategory, removeCategory } from 'services/category/actions';
import {
  Typography, Input, Button, FormGroup, FormControlLabel, FormHelperText,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';

function CategoryHeader(props) {
  const navigate = useNavigate();
  const {
    currentCategory, loading, isNew,
  } = props;
  const [category, setCategory] = useState({
    name: currentCategory.name || '',
    oldIcon: currentCategory.icon || '',
    oldImage: currentCategory.image || '',
    en: currentCategory.en?.name || '',
    en_id: currentCategory.en?.id || '',
    zh: currentCategory.zh?.name || '',
    zh_id: currentCategory.zh?.id || '',
    isActive: currentCategory?.isActive,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCategory({ ...category, [name]: value });
  };

  const handleCheckBoxChange = (name, value) => {
    console.log(value);
    console.log(category);
    setCategory({ ...category, [name]: value });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const [file] = files;
    if (file) {
      setCategory({ ...category, [name === 'image' ? 'oldImage' : 'oldIcon']: URL.createObjectURL(file), [name]: file });
    }
  };

  useEffect(() => {
  }, []);

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '50ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        required
        id="filled-required"
        label="Category Title(name):"
        value={category.name}
        variant="filled"
        helperText="Required*"
        name="name"
        sx={{ mr: 1 }}
        onChange={handleChange}
      />
      <br />
      <TextField
        required
        id="filled-required"
        label="Category Name(en):"
        value={category.en}
        name="en"
        variant="filled"
        helperText="Required*"
        sx={{ mr: 1 }}
        onChange={handleChange}
      />
      <TextField
        required
        id="filled-required"
        label="Category Name(zh):"
        value={category.zh}
        name="zh"
        variant="filled"
        helperText="Required*"
        onChange={handleChange}
      />
      {/* <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              disabled={!isNew}
              name="hasMiddleSubcategory"
              checked={category.hasMiddleSubcategory}
              onChange={() => { handleCheckBoxChange('hasMiddleSubcategory', !category.hasMiddleSubcategory); }}
            />
      )}
          label="Middle Subcategory / 中層Subcategory"
        />
        {isNew
        && <FormHelperText sx={{ color: 'red' }}>Be careful, you cannot change Middle Subcategory later </FormHelperText>}
        <FormHelperText sx={{ color: '#ff9100' }}>For example, category -&gt; middle subcategory -&gt; subcategory </FormHelperText>
      </FormGroup> */}
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              name="isActive"
              checked={category.isActive === 1 || category.isActive}
              onChange={() => { handleCheckBoxChange('isActive', !category.isActive); }}
            />
      )}
          label="Active"
        />
        <FormHelperText>If active, this category will be shown on the website.</FormHelperText>
      </FormGroup>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        mt={1}
      >
        <Typography variant="h6" component="h6">Icon: </Typography>
        {category.oldIcon
        && (
        <img
          width="50"
          src={category.oldIcon}
        />
        )}
        <Button
          size="small"
          variant="outlined"
          component="label"
        >
          Upload Icon
          <input
            accept="image/*"
            type="file"
            name="icon"
            hidden
            onChange={handleFileChange}
          />
        </Button>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        mt={1}
        spacing={2}
      >
        <Typography variant="h6" component="h6">Image: </Typography>
        {category.oldImage && (
        <img
          width="250"
          src={category.oldImage}
        />
        )}
        <Button
          size="small"
          variant="outlined"
          component="label"
        >
          Upload Image
          <input
            accept="image/*"
            type="file"
            name="image"
            hidden
            onChange={handleFileChange}
          />
        </Button>
      </Stack>
      {props.error && <ErrorComponent error={props.error} />}
      <LoadingButton
        loading={loading}
        variant="contained"
        size="large"
        sx={{ marginTop: 2, marginBottom: 2 }}
        onClick={() => {
          if (isNew) {
            props.addCategory(category);
          } else {
            props.updateCategory(currentCategory.id, category);
          }
        }}
      >
        {props.isNew ? 'New' : 'Update'}
      </LoadingButton>
      {
        !props.isNew
      && (
      <LoadingButton
        loading={loading}
        variant="outlined"
        size="large"
        color="warning"
        sx={{ marginLeft: 2, marginTop: 2, marginBottom: 2 }}
        onClick={() => {
          props.removeCategory(currentCategory.id);
        }}
      >
        Delete
      </LoadingButton>
      )
      }
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectCategoryError(),
  loading: makeSelectCategorLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateCategory,
    addCategory,
    removeCategory,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

CategoryHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  updateCategory: PropTypes.func.isRequired,
  addCategory: PropTypes.func.isRequired,
  removeCategory: PropTypes.func.isRequired,
  currentCategory: PropTypes.shape(),
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
};

export default compose(
  withConnect,
)(CategoryHeader);
