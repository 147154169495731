import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Copyright from 'components/Copyright/Copyright';
import { makeSelectUserList } from 'services/user/selectors';

function DashboardContent(props) {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) => (theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900]),
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Paper variant="outlined">
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              Welcome
            </Grid>
          </Grid>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Paper>
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
  // error: makeSelectUserError(),
  // loading: makeSelectUserLoading(),
  userList: makeSelectUserList(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    // getAllUser,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

DashboardContent.propTypes = {
  // loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // error: PropTypes.any,
  // userList: PropTypes.arrayOf(PropTypes.shape()),
  // getAllUser: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(DashboardContent);
