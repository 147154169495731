export const PRODUCTS_GET_ALL = 'PRODUCTS_GET_ALL';
export const PRODUCTS_GET_ALL_SUCCESS = 'PRODUCTS_GET_ALL_SUCCESS';
export const PRODUCTS_GET_ALL_FAIL = 'PRODUCTS_GET_ALL_FAIL';
export const PRODUCTS_GET_ONE_WITH_I18N = 'PRODUCTS_GET_ONE_WITH_I18N';
export const PRODUCTS_GET_ONE_WITH_I18N_SUCCESS = 'PRODUCTS_GET_ONE_WITH_I18N_SUCCESS';
export const PRODUCTS_GET_ONE_WITH_I18N_FAIL = 'PRODUCTS_GET_ONE_WITH_I18N_FAIL';
export const PRODUCTS_GET_ONE_WITH_I18N_EMPTY = 'PRODUCTS_GET_ONE_WITH_I18N_EMPTY';
export const PRODUCTS_UPDATE = 'PRODUCTS_UPDATE';
export const PRODUCTS_UPDATE_SUCCESS = 'PRODUCTS_UPDATE_SUCCESS';
export const PRODUCTS_UPDATE_FAIL = 'PRODUCTS_UPDATE_FAIL';
export const PRODUCTS_ADD = 'PRODUCTS_ADD';
export const PRODUCTS_ADD_SUCCESS = 'PRODUCTS_ADD_SUCCESS';
export const PRODUCTS_ADD_FAIL = 'PRODUCTS_ADD_FAIL';
export const PRODUCTS_REMOVE = 'PRODUCTS_REMOVE';
export const PRODUCTS_REMOVE_SUCCESS = 'PRODUCTS_REMOVE_SUCCESS';
export const PRODUCTS_REMOVE_FAIL = 'PRODUCTS_REMOVE_FAIL';
export const PRODUCTS_ADD_IMAGE = 'PRODUCTS_ADD_IMAGE';
export const PRODUCTS_ADD_IMAGE_SUCCESS = 'PRODUCTS_ADD_IMAGE_SUCCESS';
export const PRODUCTS_ADD_IMAGE_FAIL = 'PRODUCTS_ADD_IMAGE_FAIL';
export const PRODUCTS_REMOVE_IMAGE = 'PRODUCTS_REMOVE_IMAGE';
export const PRODUCTS_REMOVE_IMAGE_SUCCESS = 'PRODUCTS_REMOVE_IMAGE_SUCCESS';
export const PRODUCTS_REMOVE_IMAGE_FAIL = 'PRODUCTS_REMOVE_IMAGE_FAIL';
export const PRODUCTS_CHANGE_ORDER_IMAGE = 'PRODUCTS_CHANGE_ORDER_IMAGE';
export const PRODUCTS_CHANGE_ORDER_IMAGE_SUCCESS = 'PRODUCTS_CHANGE_ORDER_IMAGE_SUCCESS';
export const PRODUCTS_CHANGE_ORDER_IMAGE_FAIL = 'PRODUCTS_CHANGE_ORDER_IMAGE_FAIL';
export const PRODUCTS_ADD_PDF = 'PRODUCTS_ADD_PDF';
export const PRODUCTS_REMOVE_PDF = 'PRODUCTS_REMOVE_PDF';
