/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  Outlet, useLocation, useParams, useNavigate,
} from 'react-router-dom';
import _ from 'lodash';
import { makeSelectOptionLoading, makeSelectOptionAll, makeSelectOptionError } from 'services/option/selectors';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function AboutOutlet(props) {
  const { loading } = props;
  const { t, i18n } = useTranslation();

  useEffect(() => {
  }, [i18n.language]);

  return (
    loading ? (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : <Outlet />
  );
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectOptionLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

AboutOutlet.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default compose(
  withConnect,
)(AboutOutlet);
