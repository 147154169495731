/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageWithFallback from 'components/ImageWithFallback/ImageWithFallback';
import _ from 'lodash';
import { restylePath } from 'utils/path.utils';
import { makeSelectRoutesNestedCategory } from 'services/routes/selectors';
import { useTranslation } from 'react-i18next';
import { sify } from 'chinese-conv';

function TitlebarBelowImageList(props) {
  const { t, i18n } = useTranslation();

  const {
    activeProductList_en, activeProductList_zh, language, navigate, nestedCategory,
  } = props;

  const generateImagePath = (item, imageExtension = 'jpg') => {
    let path = `/assets/Products/${restylePath(item.category)}/${!_.isEmpty(item.subcategory) ? `${restylePath(item.subcategory)}/` : ''}${restylePath(item.title)}/1.${imageExtension}`;
    if (item.category.toUpperCase() === 'DESKING') {
      path = `/assets/Products/${restylePath(item.category)}/${nestedCategory ? `${restylePath(item.nestedcategory)}/` : ''}${!_.isEmpty(item.subcategory) ? `${restylePath(item.subcategory)}/` : ''}${restylePath(item.title)}/1.${imageExtension}`;
    }
    return path;
  };

  const getCorrectLanguageProductById = (index) => {
    const lang = language === 'en' ? language : 'zh';

    return (lang === 'en' ? activeProductList_en[index] : activeProductList_zh[index]);
  };

  useEffect(() => {
  });

  return (
    <ImageList
      sx={{
        width: 'auto',
        paddingBottom: '5px',
      }}
      cols={5}
      gap={20}
    >
      {activeProductList_en && activeProductList_en.map((item, index) => (
        <ImageListItem
          key={`${item.id}-${item.language}`}
          sx={{
            width: '212px',
            cursor: 'pointer',
            marginBottom: '40px',
          }}
          onClick={() => navigate(`${item.id}`)}
        >
          <ImageWithFallback
            src={`${generateImagePath(item)}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${generateImagePath(item)}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt=""
            style={{
              borderRadius: '5px',
              height: '237px',
              objectFit: 'contain',
            }}
            loading="lazy"
          />
          <ImageListItemBar
            title={(
              <span style={{ paddingLeft: '7px' }}>
                {language === 'cn' ? sify((getCorrectLanguageProductById(index).title)) : (getCorrectLanguageProductById(index)).title}
              </span>
          )}
            sx={{
              background: '#FFFFFF',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '5px',
            }}
            position="below"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

TitlebarBelowImageList.propTypes = {
  activeProductList_en: PropTypes.arrayOf(PropTypes.shape({})),
  activeProductList_zh: PropTypes.arrayOf(PropTypes.shape({})),
  language: PropTypes.string,
  navigate: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  nestedCategory: makeSelectRoutesNestedCategory(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

TitlebarBelowImageList.propTypes = {
  nestedCategory: PropTypes.string,
};

export default compose(
  withConnect,
)(TitlebarBelowImageList);
