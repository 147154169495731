/* eslint-disable camelcase */
import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';
import {
  getAllCategorySuccess,
  getAllCategoryFail,
  getOneCategoryWithi18nSuccess,
  getOneCategoryWithi18nFail,
  updateCategorySuccess,
  updateCategoryFail,
  addCategorySuccess,
  addCategoryFail,
  removeCategorySuccess,
  removeCategoryFail,
} from './actions';

import {
  CATEGORY_GET_ALL,
  CATEGORY_GET_ONE_WITH_I18N,
  CATEGORY_UPDATE,
  CATEGORY_ADD,
  CATEGORY_REMOVE,
} from './constants';

const api = API.create();

export function* getAllCategory() {
  const result = yield call(api.category.getAll, {});
  if (result.ok) {
    yield put(getAllCategorySuccess(result.data));
  } else {
    yield put(getAllCategoryFail(result.data));
  }
}

export function* getOneWithi18n(data) {
  const { id } = data;
  const result = yield call(api.category.getOneWithi18n, id);
  if (result.ok) {
    yield put(getOneCategoryWithi18nSuccess(result.data));
  } else {
    yield put(getOneCategoryWithi18nFail(result.data));
  }
}

export function* updateCategory(data) {
  const { id, category } = data;
  const result = yield call(api.category.update, { id, category });
  if (result.ok) {
    yield put(updateCategorySuccess(result.data));
  } else {
    yield put(updateCategoryFail(result.data));
  }
}

export function* addCategory(data) {
  const { category } = data;
  const result = yield call(api.category.add, { category });
  if (result.ok) {
    yield put(addCategorySuccess(result.data));
    const { id } = result.data;
    window.location.replace(`/admin/products/category/${id}`);
  } else {
    yield put(addCategoryFail(result.data));
  }
}

export function* removeCategory(data) {
  const { id } = data;
  const result = yield call(api.category.remove, { id });
  if (result.ok) {
    yield put(removeCategorySuccess(result.data));
  } else {
    yield put(removeCategoryFail(result.error));
  }
}

export default function* categorySaga() {
  yield all([
    takeLatest(CATEGORY_GET_ONE_WITH_I18N, getOneWithi18n),
    takeLatest(CATEGORY_GET_ALL, getAllCategory),
    takeLatest(CATEGORY_UPDATE, updateCategory),
    takeLatest(CATEGORY_ADD, addCategory),
    takeLatest(CATEGORY_REMOVE, removeCategory),
  ]);
}
