import * as React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Innomobel from 'assets/Innomobel.png';
import { useNavigate } from 'react-router-dom';

const ColorButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#191919',
  border: '1px solid #FFFFFF',
  borderRadius: 70,
  borderShadow: 'box-shadow: inset 0px 3px 3px 1px rgba(0, 0, 0, 0.3)',
  '&:hover': {
    backgroundColor: '#191919',
    border: '1px solid #FFFFFF',
  },
}));

function Home() {
  const navigate = useNavigate();

  const onClickDiscoverNow = () => {
    navigate('products');
  };

  return (
    <Container maxWidth="false" style={{ background: 'linear-gradient(180deg, #000000 10.32%, #0D0D0D 45.23%, #000000 80.14%)' }}>
      <Box sx={{ background: 'linear-gradient(180deg, #000000 10.32%, #0D0D0D 45.23%, #000000 80.14%)' }}>
        <Grid
          container
          spacing={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '90vh', marginTop: '0' }}
        >
          <Grid item xs={3}>
            <Box
              component="img"
              sx={{
                height: 230,
                width: 366,
                maxHeight: { xs: 180, md: 230 },
                maxWidth: { xs: 263, md: 366 },
              }}
              alt="Innomobel"
              src={Innomobel}
            />
          </Grid>
          <Grid item xs={3} mt={3}>
            <ColorButton
              variant="outlined"
              onClick={onClickDiscoverNow}
            >
              Discover now
            </ColorButton>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Home;
