import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views';
import ImageWithFallback from 'components/ImageWithFallback/ImageWithFallback';
import _ from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';

const styles = {
  root: {
    padding: '0px 440px 0px 0px',
  },
  rootSmall: {
    padding: '0px 0px 0px 0px',
  },
  slideContainer: {
    padding: '0px 0px',
  },
  slide: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
};

function SwipeableCarousel(props) {
  const {
    images, activeStep, setActiveStep, validImage, setValidImage,
  } = props;
  const theme = useTheme();
  const isMatchedXsScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const removeInvalidImage = (invalidImage) => {
    const temp = _.cloneDeep(validImage);
    _.remove(temp, (n) => n === invalidImage);
    setValidImage(temp);
  };

  useEffect(() => {
    setValidImage(images);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SwipeableViews
        enableMouseEvents
        index={activeStep}
        onChangeIndex={handleStepChange}
        style={isMatchedXsScreen ? styles.rootSmall : styles.root}
        slideStyle={styles.slideContainer}
      >
        {validImage.map((image, index) => (
          <div key={image} style={styles.slide}>
            {Math.abs(activeStep - index) <= 2 ? (
              <ImageWithFallback
                src={image}
                alt=""
                style={{
                  borderRadius: '15px',
                  objectFit: 'contain',
                  maxHeight: '600px',
                  maxWidth: '400px',
                  display: 'block',
                  width: '-webkit-fill-available',
                  paddingLeft: '3px',
                  paddingRight: '3px',
                }}
                callback={removeInvalidImage}
              />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
    </Box>
  );
}

SwipeableCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  setActiveStep: PropTypes.func,
  setValidImage: PropTypes.func,
  activeStep: PropTypes.number,
  validImage: PropTypes.arrayOf(PropTypes.string),
};
export default SwipeableCarousel;
