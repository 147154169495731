import {
  CATEGORY_GET_ALL,
  CATEGORY_GET_ALL_SUCCESS,
  CATEGORY_GET_ALL_FAIL,
  CATEGORY_GET_ONE_WITH_I18N,
  CATEGORY_GET_ONE_WITH_I18N_SUCCESS,
  CATEGORY_GET_ONE_WITH_I18N_FAIL,
  CATEGORY_GET_ONE_WITH_I18N_EMPTY,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_ADD,
  CATEGORY_ADD_SUCCESS,
  CATEGORY_ADD_FAIL,
  CATEGORY_REMOVE,
  CATEGORY_REMOVE_SUCCESS,
  CATEGORY_REMOVE_FAIL,
} from './constants';

export const getAllCategory = () => ({
  type: CATEGORY_GET_ALL,
});

export const getAllCategorySuccess = (data) => ({
  type: CATEGORY_GET_ALL_SUCCESS,
  data,
});

export const getAllCategoryFail = (error) => ({
  type: CATEGORY_GET_ALL_FAIL,
  error,
});

export const getOneCategoryWithi18n = (id) => ({
  type: CATEGORY_GET_ONE_WITH_I18N,
  id,
});

export const getOneCategoryWithi18nSuccess = (data) => ({
  type: CATEGORY_GET_ONE_WITH_I18N_SUCCESS,
  data,
});

export const emptyOneCategoryWithi18n = () => ({
  type: CATEGORY_GET_ONE_WITH_I18N_EMPTY,
});

export const getOneCategoryWithi18nFail = (error) => ({
  type: CATEGORY_GET_ONE_WITH_I18N_FAIL,
  error,
});

export const updateCategory = (id, category) => ({
  type: CATEGORY_UPDATE,
  id,
  category,
});

export const updateCategorySuccess = (data) => ({
  type: CATEGORY_UPDATE_SUCCESS,
  data,
});

export const updateCategoryFail = (error) => ({
  type: CATEGORY_UPDATE_FAIL,
  error,
});

export const addCategory = (category) => ({
  type: CATEGORY_ADD,
  category,
});

export const addCategorySuccess = (data) => ({
  type: CATEGORY_ADD_SUCCESS,
  data,
});

export const addCategoryFail = (error) => ({
  type: CATEGORY_ADD_FAIL,
  error,
});

export const removeCategory = (id) => ({
  type: CATEGORY_REMOVE,
  id,
});

export const removeCategorySuccess = (data) => ({
  type: CATEGORY_REMOVE_SUCCESS,
  data,
});

export const removeCategoryFail = (error) => ({
  type: CATEGORY_REMOVE_FAIL,
  error,
});
