import {
  OPTION_GET_ALL,
  OPTION_GET_ALL_SUCCESS,
  OPTION_GET_ALL_FAIL,
  OPTION_UPDATE_ABOUT,
  OPTION_UPDATE_ABOUT_SUCCESS,
  OPTION_UPDATE_ABOUT_FAIL,
  OPTION_UPDATE_CONTACT,
  OPTION_UPDATE_CONTACT_SUCCESS,
  OPTION_UPDATE_CONTACT_FAIL,
} from './constants';

export const getAllOption = () => ({
  type: OPTION_GET_ALL,
});

export const getAllOptionSuccess = (data) => ({
  type: OPTION_GET_ALL_SUCCESS,
  data,
});

export const getAllOptionFail = (error) => ({
  type: OPTION_GET_ALL_FAIL,
  error,
});

export const updateOptionAbout = (data) => ({
  type: OPTION_UPDATE_ABOUT,
  data,
});

export const updateOptionAboutSuccess = (data) => ({
  type: OPTION_UPDATE_ABOUT_SUCCESS,
  data,
});

export const updateOptionAboutFail = (data) => ({
  type: OPTION_UPDATE_ABOUT_FAIL,
  data,
});

export const updateOptionContact = (data) => ({
  type: OPTION_UPDATE_CONTACT,
  data,
});

export const updateOptionContactSuccess = (data) => ({
  type: OPTION_UPDATE_CONTACT_SUCCESS,
  data,
});

export const updateOptionContactFail = (data) => ({
  type: OPTION_UPDATE_CONTACT_FAIL,
  data,
});
