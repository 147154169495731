import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  makeSelectUserCurrentUser, makeSelectUserError, makeSelectUserLoading, makeSelectUserEditingUser,
} from 'services/user/selectors';
import {
  updateUserPassword, getUserById,
} from 'services/user/actions';
import AdminPageContainer from 'components/AdminPageContainer/AdminPageContainer';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import UserHeaderTop from './UserHeaderTop';

function UserHeader(props) {
  const { userId } = useParams();
  const location = useLocation();
  const {
    currentUser, editingUser, error, loading,
  } = props;
  const isNew = location.state?.newUser || false;

  useEffect(() => {
    if (!isNew) {
      props.getUserById(userId);
    }
  }, []);

  return (
    <AdminPageContainer title={`User/${isNew ? 'New' : editingUser.id}`}>
      {(!_.isEmpty(editingUser) || isNew)
      && <UserHeaderTop />}
    </AdminPageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectUserError(),
  loading: makeSelectUserLoading(),
  currentUser: makeSelectUserCurrentUser(),
  editingUser: makeSelectUserEditingUser(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateUserPassword,
    getUserById,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

UserHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
  currentUser: PropTypes.shape(),
  editingUser: PropTypes.shape(),
  getUserById: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(UserHeader);
