import {
  DASHBOARD_GET_CATEGORYLIST,
  DASHBOARD_GET_CATEGORYLIST_SUCCESS,
  DASHBOARD_GET_CATEGORYLIST_FAIL,
  DASHBOARD_GET_PRODUCTLIST,
  DASHBOARD_GET_PRODUCTLIST_SUCCESS,
  DASHBOARD_GET_PRODUCTLIST_FAIL,
  DASHBOARD_GET_SUBCATEGORYLIST,
  DASHBOARD_GET_SUBCATEGORYLIST_SUCCESS,
  DASHBOARD_GET_SUBCATEGORYLIST_FAIL,
  DASHBOARD_GET_OPTIONLIST,
  DASHBOARD_GET_OPTIONLIST_SUCCESS,
  DASHBOARD_GET_OPTIONLIST_FAIL,
  DASHBOARD_GET_NEWSLIST,
  DASHBOARD_GET_NEWSLIST_SUCCESS,
  DASHBOARD_GET_NEWSLIST_FAIL,
  DASHBOARD_GET_PROJECTLIST,
  DASHBOARD_GET_PROJECTLIST_SUCCESS,
  DASHBOARD_GET_PROJECTLIST_FAIL,
} from './constants';

export const getCategoryList = () => ({
  type: DASHBOARD_GET_CATEGORYLIST,
});

export const getCategoryListSuccess = (data) => ({
  type: DASHBOARD_GET_CATEGORYLIST_SUCCESS,
  data,
});

export const getCategoryListFail = (error) => ({
  type: DASHBOARD_GET_CATEGORYLIST_FAIL,
  error,
});

export const getSubcategoryList = () => ({
  type: DASHBOARD_GET_SUBCATEGORYLIST,
});

export const getSubcategoryListSuccess = (data) => ({
  type: DASHBOARD_GET_SUBCATEGORYLIST_SUCCESS,
  data,
});

export const getSubcategoryListFail = (error) => ({
  type: DASHBOARD_GET_SUBCATEGORYLIST_FAIL,
  error,
});

export const getOptionList = () => ({
  type: DASHBOARD_GET_OPTIONLIST,
});

export const getOptionListSuccess = (data) => ({
  type: DASHBOARD_GET_OPTIONLIST_SUCCESS,
  data,
});

export const getOptionListFail = (error) => ({
  type: DASHBOARD_GET_OPTIONLIST_FAIL,
  error,
});

export const getProductList = () => ({
  type: DASHBOARD_GET_PRODUCTLIST,
});

export const getProductListSuccess = (data) => ({
  type: DASHBOARD_GET_PRODUCTLIST_SUCCESS,
  data,
});

export const getProductListFail = (error) => ({
  type: DASHBOARD_GET_PRODUCTLIST_FAIL,
  error,
});

export const getNewsList = () => ({
  type: DASHBOARD_GET_NEWSLIST,
});

export const getNewsListSuccess = (data) => ({
  type: DASHBOARD_GET_NEWSLIST_SUCCESS,
  data,
});

export const getNewsListFail = (error) => ({
  type: DASHBOARD_GET_NEWSLIST_FAIL,
  error,
});

export const getProjectList = () => ({
  type: DASHBOARD_GET_PROJECTLIST,
});

export const getProjectListSuccess = (data) => ({
  type: DASHBOARD_GET_PROJECTLIST_SUCCESS,
  data,
});

export const getProjectListFail = (error) => ({
  type: DASHBOARD_GET_PROJECTLIST_FAIL,
  error,
});
