/* eslint-disable camelcase */
import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';

import {
  addNewsSuccess,
  addNewsFail,
  updateNewsSuccess,
  updateNewsFail,
  removeNewsSuccess,
  removeNewsFail,
  getAllSuccess,
  getAllFail,
  addNewsImageSuccess,
  addNewsImageFail,
  removeNewsImageSuccess,
  removeNewsImageFail,
  changeNewsImageOrderSuccess,
  changeNewsImageOrderFail,
} from './actions';

import {
  NEWS_ADD,
  NEWS_REMOVE,
  NEWS_UPDATE,
  NEWS_GET_ALL,
  NEWS_ADD_IMAGE,
  NEWS_REMOVE_IMAGE,
  NEWS_CHANGE_ORDER_IMAGE,
} from './constants';

const api = API.create();

export function* addNews(data) {
  const result = yield call(api.news.add, data.data);
  if (result.ok) {
    yield put(addNewsSuccess(result.data));
  } else {
    yield put(addNewsFail(result.data));
  }
}

export function* updateNews(data) {
  const result = yield call(api.news.update, data.data);
  if (result.ok) {
    yield put(updateNewsSuccess(result.data));
  } else {
    yield put(updateNewsFail(result.data));
  }
}

export function* removeNews(data) {
  const result = yield call(api.news.remove, data.data);
  if (result.ok) {
    yield put(removeNewsSuccess(result.data));
  } else {
    yield put(removeNewsFail(result.data));
  }
}

export function* getAll() {
  const result = yield call(api.news.getAll, {});
  if (result.ok) {
    yield put(getAllSuccess(result.data));
  } else {
    yield put(getAllFail(result.data));
  }
}

export function* addNewsImage(data) {
  const result = yield call(api.news.addImage, data.newsId, data.news);
  if (result.ok) {
    yield put(addNewsImageSuccess(result.data));
  } else {
    yield put(addNewsImageFail(result.data));
  }
}

export function* removeNewsImage(data) {
  const result = yield call(api.news.removeImage, { id: data.id });
  if (result.ok) {
    yield put(removeNewsImageSuccess(result.data));
  } else {
    yield put(removeNewsImageFail(result.data));
  }
}

export function* changeNewsImageOrder(data) {
  const result = yield call(api.news.changeImageOrder, data.news);
  if (result.ok) {
    yield put(changeNewsImageOrderSuccess(result.data));
  } else {
    yield put(changeNewsImageOrderFail(result.data));
  }
}

export default function* newsSaga() {
  yield all([
    takeLatest(NEWS_ADD, addNews),
    takeLatest(NEWS_REMOVE, removeNews),
    takeLatest(NEWS_UPDATE, updateNews),
    takeLatest(NEWS_GET_ALL, getAll),
    takeLatest(NEWS_ADD_IMAGE, addNewsImage),
    takeLatest(NEWS_REMOVE_IMAGE, removeNewsImage),
    takeLatest(NEWS_CHANGE_ORDER_IMAGE, changeNewsImageOrder),
  ]);
}
