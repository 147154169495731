export const USER_GROUP = [
  {
    id: 1, name: 'Administrators', backend_access: 1, manage_user: 1,
  },
  {
    id: 2, name: 'Editor', backend_access: 1, manage_user: 0,
  },
];
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_GET_PROFILE = 'USER_GET_PROFILE';
export const USER_GET_PROFILE_SUCCESS = 'USER_GET_PROFILE_SUCCESS';
export const USER_GET_PROFILE_FAIL = 'USER_GET_PROFILE_FAIL';
export const USER_GET_ALL_USER = 'USER_GET_ALL_USER';
export const USER_GET_ALL_USER_SUCCESS = 'USER_GET_ALL_USER_SUCCESS';
export const USER_GET_ALL_USER_FAIL = 'USER_GET_ALL_USER_FAIL';
export const USER_GET_USER_BY_ID = 'USER_GET_USER_BY_ID';
export const USER_GET_USER_BY_ID_SUCCESS = 'USER_GET_USER_BY_ID_SUCCESS';
export const USER_GET_USER_BY_ID_FAIL = 'USER_GET_USER_BY_ID_FAIL';
export const USER_CREATE = 'USER_CREATE';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD';
export const USER_UPDATE_PASSWORD_SUCCESS = 'USER_UPDATE_PASSWORD_SUCCESS';
export const USER_UPDATE_PASSWORD_FAIL = 'USER_UPDATE_PASSWORD_FAIL';
export const USER_REMOVE = 'USER_REMOVE';
export const USER_REMOVE_SUCCESS = 'USER_REMOVE_SUCCESS';
export const USER_REMOVE_FAIL = 'USER_REMOVE_FAIL';
export const USER_EMPTY_EDITING_USER = 'USER_EMPTY_EDITING_USER';
