import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_GET_PROFILE_SUCCESS,
  USER_GET_PROFILE_FAIL,
  USER_GET_PROFILE,
  USER_GET_ALL_USER,
  USER_GET_ALL_USER_SUCCESS,
  USER_GET_ALL_USER_FAIL,
  USER_CREATE,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PASSWORD,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_FAIL,
  USER_GET_USER_BY_ID,
  USER_GET_USER_BY_ID_SUCCESS,
  USER_GET_USER_BY_ID_FAIL,
  USER_EMPTY_EDITING_USER,
  USER_REMOVE,
  USER_REMOVE_SUCCESS,
  USER_REMOVE_FAIL,
} from './constants';

export const login = (data, callback) => ({
  type: USER_LOGIN,
  data,
  callback,
});

export const loginSuccess = (data) => ({
  type: USER_LOGIN_SUCCESS,
  data,
});

export const loginFail = (error) => ({
  type: USER_LOGIN_FAIL,
  error,
});

export const getProfile = (callback) => ({
  type: USER_GET_PROFILE,
  callback,
});

export const getProfileSuccess = (data) => ({
  type: USER_GET_PROFILE_SUCCESS,
  data,
});

export const getProfileFail = (error) => ({
  type: USER_GET_PROFILE_FAIL,
  error,
});

export const getAllUser = () => ({
  type: USER_GET_ALL_USER,
});

export const getUserById = (id) => ({
  type: USER_GET_USER_BY_ID,
  id,
});

export const getUserByIdSuccess = (data) => ({
  type: USER_GET_USER_BY_ID_SUCCESS,
  data,
});

export const getUserByIdFail = (error) => ({
  type: USER_GET_USER_BY_ID,
  error,
});

export const getAllUserSuccess = (data) => ({
  type: USER_GET_ALL_USER_SUCCESS,
  data,
});

export const getAllUserFail = (error) => ({
  type: USER_GET_ALL_USER_FAIL,
  error,
});

export const createUser = (user) => ({
  type: USER_CREATE,
  user,
});

export const createUserSuccess = (data) => ({
  type: USER_CREATE_SUCCESS,
  data,
});

export const createUserFail = (error) => ({
  type: USER_CREATE_FAIL,
  error,
});

export const updateUser = (id, user) => ({
  type: USER_UPDATE,
  id,
  user,
});

export const updateUserSuccess = (data) => ({
  type: USER_UPDATE_SUCCESS,
  data,
});

export const updateUserFail = (error) => ({
  type: USER_UPDATE_FAIL,
  error,
});

export const updateUserPassword = (id, user) => ({
  type: USER_UPDATE_PASSWORD,
  id,
  user,
});

export const updateUserPasswordSuccess = (data) => ({
  type: USER_UPDATE_PASSWORD_SUCCESS,
  data,
});

export const updateUserPasswordFail = (error) => ({
  type: USER_UPDATE_PASSWORD_FAIL,
  error,
});

export const emptyEditingUser = () => ({
  type: USER_EMPTY_EDITING_USER,
});

export const removeUser = (id, user) => ({
  type: USER_REMOVE,
  id,
  user,
});

export const removeUserSuccess = (data) => ({
  type: USER_REMOVE_SUCCESS,
  data,
});

export const removeUserFail = (error) => ({
  type: USER_REMOVE_FAIL,
  error,
});
