import React, { Suspense } from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SignIn from './SignIn/SignIn';
import Dashboard from './Dashboard/Dashboard';
import DashboardContent from './DashboardContent/DashboardContent';
import User from './User/User';
import UserHeader from './User/UserHeader';
import About from './About/About';
import Products from './Products/Products';
import Category from './Products/Category';
import Subcategory from './Products/Subcategory';
import ProductsDetail from './Products/ProductsDetail';
import AboutOutlet from './About/AboutOutlet';
import ContactOutlet from './Contact/ContactOutlet';
import Contact from './Contact/Contact';
import News from './News/News';
import NewsHeader from './News/NewsHeader';
import NewsOutlet from './News/NewsOutlet';
import Projects from './Projects/Projects';
import ProjectsHeader from './Projects/ProjectsHeader';
import ProjectsOutlet from './Projects/ProjectsOutlet';

const theme = createTheme();

export default function Admin() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback="loading">
        <main>
          <Routes>
            <Route path="/" element={<Dashboard />}>
              <Route index element={<DashboardContent />} />
              <Route path="user" element={<User />} />
              <Route path="user/new" element={<UserHeader />} />
              <Route path="user/edit/:userId" element={<UserHeader />} />
              <Route path="products" element={<Products />} />
              <Route path="products/category/:categoryId" element={<Category />} />
              <Route path="products/category/new" element={<Category />} />
              <Route path="products/category/:categoryId/subcategory/:subcategoryId" element={<Subcategory />} />
              <Route path="products/category/:categoryId/subcategory/new" element={<Subcategory />} />
              <Route path="products/category/:categoryId/subcategory/middle/:subcategoryId/new" element={<Subcategory />} />
              <Route path="products/category/:categoryId/subcategory/:subcategoryId/new" element={<Subcategory />} />
              <Route path="products/category/:categoryId/subcategory/:subcategoryId/product/new" element={<ProductsDetail />} />
              <Route path="products/category/:categoryId/subcategory/:subcategoryId/product/:productId" element={<ProductsDetail />} />
              <Route path="about" element={<AboutOutlet />}>
                <Route index element={<About />} />
              </Route>
              <Route path="contact" element={<ContactOutlet />}>
                <Route index element={<Contact />} />
              </Route>
              <Route path="news" element={<NewsOutlet />}>
                <Route index element={<News />} />
                <Route path=":newsId" element={<NewsHeader />} />
                <Route path="new" element={<NewsHeader />} />
              </Route>
              <Route path="projects" element={<ProjectsOutlet />}>
                <Route index element={<Projects />} />
                <Route path=":projectId" element={<ProjectsHeader />} />
                <Route path="new" element={<ProjectsHeader />} />
              </Route>
            </Route>
            <Route path="/login" element={<SignIn />} />
            <Route path="/*" element={<Dashboard />} />
          </Routes>
        </main>
      </Suspense>
    </ThemeProvider>
  );
}
