import React, { useState } from 'react';
import Container from '@mui/material/Container';
import BackgroundImage from 'assets/Background/News.png';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation, withTranslation } from 'react-i18next';
import PageHeaderButton from 'components/PageHeaderButton/PageHeaderButton';
import { isChinese, tIntermediate } from 'utils/language.utils';
import AboutImage from 'assets/News/Alan_s Recommendation/1.jpg';
import PageContainer from 'components/PageContainer/PageContainer';
import InformationCardContent from 'components/InformationCardContent/InformationCardContent';
import InformationModal from 'components/InformationModal/InformationModal';
import _ from 'lodash';
import { tify, sify } from 'chinese-conv';
import { NEWS_LIST } from './constants';

function News() {
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const [activeNews, setActiveNews] = useState({});

  const getActiveNewsList = () => {
    const lang = i18n.language === 'en' ? 'en' : 'zh';
    return NEWS_LIST.filter((x) => x.language === lang && !_.isEmpty(x.title));
  };

  const handleOpen = (id) => {
    setActiveNews(_.find(getActiveNewsList(), { id }));
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  return (
    <PageContainer
      backgroundImage={BackgroundImage}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <PageHeaderButton text={tIntermediate('news.header')} />
          </Grid>
          <Grid item xs={12} md={12} mt={2} sx={{ width: { xs: '100%' } }}>
            {getActiveNewsList().map((news) => (
              <InformationCardContent
                key={news.id}
                image={news.images[0]}
                date={news.date}
                title={i18n.language === 'zh' ? news.title : sify(news.title)}
                description={i18n.language === 'zh' ? news.description : sify(news.description || '')}
                callback={() => handleOpen(news.id)}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
      <InformationModal open={open} handleClose={handleClose} data={activeNews} />
    </PageContainer>
  );
}

export default News;
