import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const SubCategroyHeaderButton = styled(({
  text, icon, subText, onClickEvent, ...other
}) => (
  <Button
    onClick={() => {
      onClickEvent();
    }}
    {...other}
    startIcon={(
      <img
        alt={text}
        src={icon}
        style={{
          height: '20px',
          width: '20px',
          color: '#4CB3AA',
          display: icon ? 'block' : 'none',
        }}
      />
  )}
  >
    {text}
  </Button>
))(({ theme }) => ({
  color: '#4CB3AA',
  backgroundColor: '#FAFAFA',
  border: '1px solid #FFFFFF',
  borderRadius: 25,
  minWidth: '180px',
  textTransform: 'none',
  boxShadow: 'inset 0px 4px 4px rgba(208, 208, 210, 0.5)',
  backdropFilter: 'blur(10px)',
  fontWeight: 800,
  fontSize: '20px',
  fontStyle: 'normal',
  padding: '0px 10px 0px 10px',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #FFFFFF',
  },
  '& .MuiButton-startIcon': {
    marginRight: '5px',
    paddingLeft: '5px',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  },
}));

export default SubCategroyHeaderButton;
