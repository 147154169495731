/* eslint-disable default-param-last */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { updater } from 'utils/reducer';
import _ from 'lodash';
import {
  USER_LOGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_GET_PROFILE_SUCCESS,
  USER_GET_PROFILE_FAIL,
  USER_GET_PROFILE,
  USER_GET_ALL_USER,
  USER_GET_ALL_USER_SUCCESS,
  USER_GET_ALL_USER_FAIL,
  USER_CREATE,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PASSWORD,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_FAIL,
  USER_GET_USER_BY_ID,
  USER_GET_USER_BY_ID_SUCCESS,
  USER_GET_USER_BY_ID_FAIL,
  USER_EMPTY_EDITING_USER,
  USER_REMOVE,
  USER_REMOVE_SUCCESS,
  USER_REMOVE_FAIL,
} from './constants';

export const initialState = {
  currentUser: {},
  editingUser: {},
  userList: [],
  access_token: null,
  error: null,
  loading: false,
};

const userReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case USER_LOGIN:
      draft.loading = true;
      break;
    case USER_LOGIN_SUCCESS:
      draft.loading = false;
      draft.access_token = action.data.access_token;
      localStorage.setItem('access_token', action.data.access_token);
      // updater(action.data.data, draft);
      draft.error = null;
      break;
    case USER_LOGIN_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case USER_GET_PROFILE:
      draft.loading = false;
      draft.error = null;
      break;
    case USER_GET_PROFILE_SUCCESS:
      localStorage.setItem('id', action.data.id);
      draft.currentUser = action.data;
      break;
    case USER_GET_PROFILE_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case USER_GET_ALL_USER:
      draft.loading = true;
      draft.error = null;
      break;
    case USER_GET_ALL_USER_SUCCESS: {
      draft.loading = false;
      const id = localStorage.getItem('id');
      // console.log(id);
      // console.log(draft.currentUser.id);
      // console.log(_.find(action.data, { id: _.toInteger(draft.currentUser.id || id) }));
      draft.currentUser = _.find(action.data, { id: _.toInteger(draft.currentUser.id || id) });
      draft.userList = action.data;
      break;
    }
    case USER_GET_USER_BY_ID_FAIL:
    case USER_GET_ALL_USER_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case USER_GET_USER_BY_ID:
      draft.loading = true;
      break;
    case USER_GET_USER_BY_ID_SUCCESS:
      draft.loading = false;
      draft.editingUser = action.data;
      break;
    case USER_CREATE:
    case USER_CREATE_SUCCESS:
      break;
    case USER_CREATE_FAIL:
      draft.error = action.error;
      break;
    case USER_UPDATE:
    case USER_UPDATE_SUCCESS:
      break;
    case USER_UPDATE_FAIL:
      draft.error = action.error;
      break;
    case USER_REMOVE:
    case USER_REMOVE_SUCCESS:
      break;
    case USER_REMOVE_FAIL:
      draft.error = action.error;
      break;
    case USER_UPDATE_PASSWORD:
    case USER_UPDATE_PASSWORD_SUCCESS:
      break;
    case USER_UPDATE_PASSWORD_FAIL:
      draft.error = action.error;
      break;
    case USER_EMPTY_EDITING_USER:
      draft.editingUser = {};
      break;
    default:
  }
});

export default userReducer;
