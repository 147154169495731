/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { updater } from 'utils/reducer';
import _ from 'lodash';
import {
  PRODUCTS_GET_ALL,
  PRODUCTS_GET_ALL_SUCCESS,
  PRODUCTS_GET_ALL_FAIL,
  PRODUCTS_GET_ONE_WITH_I18N,
  PRODUCTS_GET_ONE_WITH_I18N_SUCCESS,
  PRODUCTS_GET_ONE_WITH_I18N_FAIL,
  PRODUCTS_GET_ONE_WITH_I18N_EMPTY,
  PRODUCTS_UPDATE,
  PRODUCTS_UPDATE_SUCCESS,
  PRODUCTS_UPDATE_FAIL,
  PRODUCTS_ADD,
  PRODUCTS_ADD_SUCCESS,
  PRODUCTS_ADD_FAIL,
  PRODUCTS_REMOVE,
  PRODUCTS_REMOVE_SUCCESS,
  PRODUCTS_REMOVE_FAIL,
  PRODUCTS_ADD_IMAGE,
  PRODUCTS_ADD_IMAGE_SUCCESS,
  PRODUCTS_ADD_IMAGE_FAIL,
  PRODUCTS_REMOVE_IMAGE,
  PRODUCTS_REMOVE_IMAGE_SUCCESS,
  PRODUCTS_REMOVE_IMAGE_FAIL,
  PRODUCTS_CHANGE_ORDER_IMAGE,
  PRODUCTS_CHANGE_ORDER_IMAGE_SUCCESS,
  PRODUCTS_CHANGE_ORDER_IMAGE_FAIL,
  PRODUCTS_REMOVE_PDF,
  PRODUCTS_ADD_PDF,
} from './constants';

export const initialState = {
  productsList: [],
  currentProducts: {},
  error: null,
  loading: false,
};

const productsReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case PRODUCTS_GET_ALL:
    case PRODUCTS_UPDATE:
      draft.loading = true;
      break;
    case PRODUCTS_GET_ALL_SUCCESS:
      draft.productsList = action.data;
      draft.error = null;
      draft.loading = false;
      break;
    case PRODUCTS_GET_ALL_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case PRODUCTS_GET_ONE_WITH_I18N:
      draft.loading = true;
      break;
    case PRODUCTS_REMOVE_SUCCESS:
      const { currentProducts } = state;
      window.location.replace(`/admin/products/category/${currentProducts.categoryId}/subcategory/${currentProducts.subcategoryId}`);
      break;
    case PRODUCTS_GET_ONE_WITH_I18N_SUCCESS:
      draft.loading = false;
      draft.currentProducts = action.data;
      draft.error = null;
      break;
    case PRODUCTS_GET_ONE_WITH_I18N_FAIL:
    case PRODUCTS_UPDATE_FAIL:
    case PRODUCTS_ADD_FAIL:
    case PRODUCTS_REMOVE_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case PRODUCTS_GET_ONE_WITH_I18N_EMPTY:
      draft.currentProducts = {};
      break;
    case PRODUCTS_ADD_SUCCESS:
      const { category, subcategory } = action.data;
      window.location.replace(`/admin/products/category/${category.id}/subcategory/${subcategory.id}`);
      break;
    case PRODUCTS_UPDATE_SUCCESS:
      draft.loading = false;
      window.history.back();
      setTimeout(() => {
        window.location.reload();
      }, 100);
      break;
    case PRODUCTS_ADD:
      draft.loading = true;
      break;
    case PRODUCTS_REMOVE:
      draft.loading = true;
      break;
    case PRODUCTS_CHANGE_ORDER_IMAGE_SUCCESS:
    case PRODUCTS_REMOVE_IMAGE_SUCCESS:
    case PRODUCTS_ADD_IMAGE_SUCCESS:
      window.location.reload();
      break;
    case PRODUCTS_ADD_IMAGE:
    case PRODUCTS_REMOVE_IMAGE:
    case PRODUCTS_CHANGE_ORDER_IMAGE:
    case PRODUCTS_REMOVE_PDF:
    case PRODUCTS_ADD_PDF:
      draft.loading = true;
      break;
    default:
  }
});

export default productsReducer;
