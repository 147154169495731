export const NEWS_LIST = [
  {
    id: 1,
    date: '2020-08-12',
    title: 'WORLD GREEN ORGANISATION - GREEN OFFICE & ECO-HEALTHY WORKPLACE AWARDS 2020',
    description: 'It is our honour to announce that, Innomobel won an award of Green Office & Eco-Healthy Workplace 2020 by WGO. We will keep going for Green & Eco-Healthy office in the future',
    language: 'en',
    images: [
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards 2020/1.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards 2020/2.jpg',
    ],
  },
  {
    id: 1,
    date: '2020-08-12',
    title: '世界綠色組織之綠色辦公室及健康工作間獎項2020',
    description: '形諾十分榮幸，能夠獲得世界綠色組織之2020年綠色辦公室及健康工作間獎狀，我們會繼續努力為環保及健康辦公空間作出貢獻。',
    language: 'zh',
    images: [
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards 2020/1.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards 2020/2.jpg',
    ],
  },
  {
    id: 2,
    date: '2019-10-25',
    title: 'INNOMOBEL LIMITED – 2019 ‘PARTNER EMPLOYER ADWARD’',
    description: '‘Partner Employer Adward’ is a function host by The Hong Kong General Chamber of Small and Medium Business, which aims to praise those enterprises who actively employ or offer internship opportunities to newly graduates, and to encourage employers to employ people with different needs in society. Innomobel Limited has been awarded ‘Parter Employer Adward’ in 2019, to praise our company fulfilling social responsibilites actively. And The Adward Ceremony has completed successfully, accompanying by Mr. Law Chi Kwong, the Secretary of Labour and Welfare Bureau on 25 October,2019.',
    language: 'en',
    images: [
      '/assets/News/Innomobel Limited – 2019 ‘Partner Employer Adward’/1.jpg',
      '/assets/News/Innomobel Limited – 2019 ‘Partner Employer Adward’/2.jpg',
      '/assets/News/Innomobel Limited – 2019 ‘Partner Employer Adward’/3.jpg',
      '/assets/News/Innomobel Limited – 2019 ‘Partner Employer Adward’/4.jpg',
      '/assets/News/Innomobel Limited – 2019 ‘Partner Employer Adward’/5.jpg',
    ],
  },
  {
    id: 2,
    date: '2019-10-25',
    title: '',
    description: '',
    language: 'zh',
    images: [
      '/assets/News/Innomobel Limited – 2019 ‘Partner Employer Adward’/1.jpg',
      '/assets/News/Innomobel Limited – 2019 ‘Partner Employer Adward’/2.jpg',
      '/assets/News/Innomobel Limited – 2019 ‘Partner Employer Adward’/3.jpg',
      '/assets/News/Innomobel Limited – 2019 ‘Partner Employer Adward’/4.jpg',
      '/assets/News/Innomobel Limited – 2019 ‘Partner Employer Adward’/5.jpg',
    ],
  },
  {
    id: 3,
    date: '2019-09-22',
    title: 'WORLD GREEN ORGANISATION - GREEN WALK 2019',
    description: 'Global warming is a real problem, and it’s worsening recently. In order to bring the climate change into sharp focus among the public, 「Green Walk Hong Kong City Orienteering Competition 2019」is going to be held by World Green Organisation. 「Discover The Disappearing Winter」 will be the theme in this year, hoping the habit of taking stroll instead of vehicles, will be cultivated among citizens. So then to reduce carbon emissions.',
    language: 'en',
    images: [
      '/assets/News/World Green Organisation - Green Walk 2019/1.jpg',
      '/assets/News/World Green Organisation - Green Walk 2019/2.jpg',
      '/assets/News/World Green Organisation - Green Walk 2019/3.jpg',
      '/assets/News/World Green Organisation - Green Walk 2019/4.jpg',
      '/assets/News/World Green Organisation - Green Walk 2019/5.jpg',
    ],
  },
  {
    id: 3,
    date: '2019-09-22',
    title: '世界綠色組織 - 地球・敢「動」行 2019',
    description: '近年全球暖化日趨嚴重，為提高大眾對氣候變化的關注。世界綠色組織 (World Green Organisation)舉辦「地球・敢『動』行 城市定向比賽 2019」。今年活動以「尋找消失中的冬天」為主題，希望市民培養「多步行，少搭車」習慣，減少碳排放。',
    language: 'zh',
    images: [
      '/assets/News/World Green Organisation - Green Walk 2019/1.jpg',
      '/assets/News/World Green Organisation - Green Walk 2019/2.jpg',
      '/assets/News/World Green Organisation - Green Walk 2019/3.jpg',
      '/assets/News/World Green Organisation - Green Walk 2019/4.jpg',
      '/assets/News/World Green Organisation - Green Walk 2019/5.jpg',
    ],
  },
  {
    id: 4,
    date: '2019-07-03',
    title: 'WORLD GREEN ORGANISATION - GREEN OFFICE & ECO-HEALTHY WORKPLACE AWARDS PRESENTATION CEREMONY 2019',
    description: 'It is our honour to announce that, Innomobel won an award of Green Office & Eco-Healthy Workplace 2019 by WGO. We will keep going for Green & Eco-Healthy office in the future',
    language: 'en',
    images: [
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2019/1.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2019/2.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2019/3.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2019/4.jpg',
    ],
  },
  {
    id: 4,
    date: '2019-07-03',
    title: '世界綠色組織之綠色辦公室及健康工作間頒獎典禮2019',
    description: '形諾十分榮幸，能夠獲得世界綠色組織之2019年綠色辦公室及健康工作間獎狀，我們會繼續努力為環保及健康辦公空間作出貢獻。',
    language: 'zh',
    images: [
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2019/1.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2019/2.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2019/3.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2019/4.jpg',
    ],
  },
  {
    id: 5,
    date: '2018-09-01',
    title: 'LIFE STYLE MAGAZINE - VIE SIMPLE - SEPT 2018',
    description: '',
    language: 'en',
    images: [
      '/assets/News/Life Style Magazine - Vie Simple - Sept 2018/1.jpg',
    ],
  },
  {
    id: 5,
    date: '2018-09-01',
    title: '生活雜誌 - VIE SIMPLE - 2018年9月號',
    description: '',
    language: 'zh',
    images: [
      '/assets/News/Life Style Magazine - Vie Simple - Sept 2018/1.jpg',
    ],
  },
  {
    id: 6,
    date: '2018-07-04',
    title: 'LIFE STYLE MAGAZINE - VIE SIMPLE - JULY 2018',
    description: '',
    language: 'en',
    images: [
      '/assets/News/Life Style Magazine - Vie Simple - July 2018/1.jpg',
    ],
  },
  {
    id: 6,
    date: '2018-07-04',
    title: '生活雜誌 - VIE SIMPLE - 2018年7月號',
    description: '',
    language: 'zh',
    images: [
      '/assets/News/Life Style Magazine - Vie Simple - July 2018/1.jpg',
    ],
  },
  {
    id: 7,
    date: '2018-07-03',
    title: 'WORLD GREEN ORGANISATION - GREEN OFFICE & ECO-HEALTHY WORKPLACE AWARDS PRESENTATION CEREMONY 2018',
    description: 'It is our honour to announce that, Innomobel won an award of Green Office & Eco-Healthy Workplace 2018 by WGO. We will keep going for Green & Eco-Healthy office in the future.',
    language: 'en',
    images: [
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2018/1.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2018/2.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2018/3.jpg',
    ],
  },
  {
    id: 7,
    date: '2018-07-03',
    title: '世界綠色組織之綠色辦公室及健康工作間頒獎典禮2018',
    description: '形諾十分榮幸，能夠獲得世界綠色組織之2018年綠色辦公室及健康工作間獎狀，我們會繼續努力為環保及健康辦公空間作出貢獻。',
    language: 'zh',
    images: [
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2018/1.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2018/2.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony 2018/3.jpg',
    ],
  },
  {
    id: 8,
    date: '2018-05-01',
    title: 'LIFE STYLE MAGAZINE - VIE SIMPLE - MAY 2018',
    description: '',
    language: 'en',
    images: [
      '/assets/News/Life Style Magazine - Vie Simple - May 2018/1.jpg',
    ],
  },
  {
    id: 8,
    date: '2018-05-01',
    title: '生活雜誌 - VIE SIMPLE - 2018年5月號',
    description: '',
    language: 'zh',
    images: [
      '/assets/News/Life Style Magazine - Vie Simple - May 2018/1.jpg',
    ],
  },
  {
    id: 9,
    date: '2018-03-04',
    title: 'LIFE STYLE MAGAZINE - VIE SIMPLE - MAR 2018',
    description: 'Innomobel wrote an article of Healthy Office Workers for life style magazine (March), Vie Simple.',
    language: 'en',
    images: [
      '/assets/News/Life Style Magazine - Vie Simple - Mar 2018/1.jpg',
      '/assets/News/Life Style Magazine - Vie Simple - Mar 2018/2.jpg',
    ],
  },
  {
    id: 9,
    date: '2018-03-04',
    title: '生活雜誌 - VIE SIMPLE - 2018年5月號',
    description: '形諾為生活雜誌VIE SIMPLE的3月號撰寫"健康職人"。',
    language: 'zh',
    images: [
      '/assets/News/Life Style Magazine - Vie Simple - Mar 2018/1.jpg',
      '/assets/News/Life Style Magazine - Vie Simple - Mar 2018/2.jpg',
    ],
  },
  {
    id: 10,
    date: '2018-01-08',
    title: 'LIFE STYLE MAGAZINE - VIE SIMPLE - JAN 2018',
    description: 'Innomobel wrote an article of Healthy Office Workers for life style magazine, Vie Simple.',
    language: 'en',
    images: [
      '/assets/News/Life Style Magazine - Vie Simple - Jan 2018/1.jpg',
      '/assets/News/Life Style Magazine - Vie Simple - Jan 2018/2.jpg',
      '/assets/News/Life Style Magazine - Vie Simple - Jan 2018/3.jpg',
    ],
  },
  {
    id: 10,
    date: '2018-01-08',
    title: '生活雜誌 - VIE SIMPLE - 2018年1月號',
    description: '形諾為生活雜誌VIE SIMPLE的1月號撰寫"健康職人"。',
    language: 'zh',
    images: [
      '/assets/News/Life Style Magazine - Vie Simple - Jan 2018/1.jpg',
      '/assets/News/Life Style Magazine - Vie Simple - Jan 2018/2.jpg',
      '/assets/News/Life Style Magazine - Vie Simple - Jan 2018/3.jpg',
    ],
  },
  {
    id: 11,
    date: '2018-01-01',
    title: "ALAN'S RECOMMENDATION",
    description: 'Cranial Alignment Practitioner - Mr. Alan Ma recommended 3 Innomobel chairs.',
    language: 'en',
    images: [
      '/assets/News/Alan_s Recommendation/1.jpg',
      '/assets/News/Alan_s Recommendation/2.jpg',
      '/assets/News/Alan_s Recommendation/3.jpg',
    ],
  },
  {
    id: 11,
    date: '2018-01-01',
    title: '馬偉麟之選',
    description: '結構整復師(身體結構整復治療)-馬偉麟先生推介的3款形諾椅子',
    language: 'zh',
    images: [
      '/assets/News/Alan_s Recommendation/1.jpg',
      '/assets/News/Alan_s Recommendation/2.jpg',
      '/assets/News/Alan_s Recommendation/3.jpg',
    ],
  },
  {
    id: 12,
    date: '2017-07-07',
    title: 'WORLD GREEN ORGANISATION - GREEN OFFICE & ECO-HEALTHY WORKPLACE AWARDS PRESENTATION CEREMONY',
    description: 'It is our honour to announce that, Innomobel won an award of Green Office & Eco-Healthy Workplace by WGO. We will keep going for Green & Eco-Healthy office in the future.',
    language: 'en',
    images: [
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony/1.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony/2.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony/3.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony/4.jpg',
    ],
  },
  {
    id: 12,
    date: '2017-07-07',
    title: '世界綠色組織之綠色辦公室及健康工作間頒獎典禮',
    description: '形諾十分榮幸，能夠獲得世界綠色組織之綠色辦公室及健康工作間獎狀，我們會繼續努力為環保及健康辦公空間作出貢獻。',
    language: 'zh',
    images: [
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony/1.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony/2.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony/3.jpg',
      '/assets/News/World Green Organisation - Green Office _ Eco-Healthy Workplace Awards Presentation Ceremony/4.jpg',
    ],
  },
  {
    id: 13,
    date: '2017-05-12',
    title: 'INNOMOBEL X BROTHER - SPECIAL OFFER',
    description: "Brother provides special offer for Innomobel's clients. Once client visit Innomobel showroom & access QR code by brother, client can enjoy the special price for selected items at Brother eshop. If you have any interest, please visit our showroom immediately!",
    language: 'en',
    images: [],
  },
  {
    id: 13,
    date: '2017-05-12',
    title: 'INNOMOBEL聯乘BROTHER優惠計劃',
    description: 'Innomobel客戶專享Brother的特價優惠，客戶只需到訪Innomobel的展廳，透過Brother提供的二維碼到訪Brother相關產品的網上商店網站，便可以使用相關優恵。 如有興趣，請立即到訪我們展廳了解詳情！',
    language: 'zh',
    images: [],
  },
  {
    id: 14,
    date: '2017-04-03',
    title: 'INNOMOBEL WAS INVITED BY THE WORLD GREEN ORGANISATION, AS THE GUEST SPEAKER FOR THE "ERGONOMIC & HEALTHY OFFICE" SEMINAR.',
    description: 'It is our honour to announce that Innomobel was invited by the WGO as the guest speaker for the "Ergonomic & Healthy Office" seminar. We aim to provide professional knowledge related to this topic to the community, and at the same time we also offer free consultation to various companies and organization regarding "Ergonomic & healthy office.',
    language: 'en',
    images: [
      '/assets/News/Innomobel Was Invited By The World Green Organisation, As The Guest Speaker For The Ergonomic _ Healthy Office Seminar/1.jpg',
      '/assets/News/Innomobel Was Invited By The World Green Organisation, As The Guest Speaker For The Ergonomic _ Healthy Office Seminar/2.jpg',
      '/assets/News/Innomobel Was Invited By The World Green Organisation, As The Guest Speaker For The Ergonomic _ Healthy Office Seminar/3.jpg',
      '/assets/News/Innomobel Was Invited By The World Green Organisation, As The Guest Speaker For The Ergonomic _ Healthy Office Seminar/4.jpg',
      '/assets/News/Innomobel Was Invited By The World Green Organisation, As The Guest Speaker For The Ergonomic _ Healthy Office Seminar/5.jpg',
    ],
  },
  {
    id: 14,
    date: '2017-04-03',
    title: '形諾榮獲世界綠色組織之送請, 擔任"人體工學及健康辦公室"講座之客席講師',
    description: '形諾十分榮幸，獲得世界綠色組織的邀請，擔任"人體工學及健康辦公室"講座之客席講師。 我們務求把相關的專業知識帶給社會各界，同時我們亦開始為不同公司及機構免費提供"人體工學及健康辦公室＂之審查，並提供意見。',
    language: 'zh',
    images: [
      '/assets/News/Innomobel Was Invited By The World Green Organisation, As The Guest Speaker For The Ergonomic _ Healthy Office Seminar/1.jpg',
      '/assets/News/Innomobel Was Invited By The World Green Organisation, As The Guest Speaker For The Ergonomic _ Healthy Office Seminar/2.jpg',
      '/assets/News/Innomobel Was Invited By The World Green Organisation, As The Guest Speaker For The Ergonomic _ Healthy Office Seminar/3.jpg',
      '/assets/News/Innomobel Was Invited By The World Green Organisation, As The Guest Speaker For The Ergonomic _ Healthy Office Seminar/4.jpg',
      '/assets/News/Innomobel Was Invited By The World Green Organisation, As The Guest Speaker For The Ergonomic _ Healthy Office Seminar/5.jpg',
    ],
  },
];
