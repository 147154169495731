import * as React from 'react';
import Stack from '@mui/material/Stack';
import { PropTypes } from 'prop-types';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export default function SimpleSnackbar(props) {
  const {
    severity, open, title, handleClose, anchorOrigin,
    children,
  } = props;

  const getSeverity = () => {
    const severityList = ['error', 'warning', 'info', 'success'];
    if (severityList.includes(severity)) return severity;
    return 'info';
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose} anchorOrigin={anchorOrigin}>
        <Alert
          icon={false}
          onClose={handleClose}
          severity={getSeverity()}
          sx={{
            width: '100%',
            '& .MuiButtonBase-root': {
              top: '-2px',
            },
          }}
        >
          {children || title}
        </Alert>
      </Snackbar>
    </Stack>
  );
}

SimpleSnackbar.defaultProps = {
  severity: 'success',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  children: null,
};

SimpleSnackbar.propTypes = {
  severity: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.string.isRequired,
    horizontal: PropTypes.string.isRequired,
  }),
  children: PropTypes.node,
};
