export const PROJECTS_UPDATE = 'PROJECTS_UPDATE';
export const PROJECTS_UPDATE_SUCCESS = 'PROJECTS_UPDATE_SUCCESS';
export const PROJECTS_UPDATE_FAIL = 'PROJECTS_UPDATE_FAIL';
export const PROJECTS_ADD = 'PROJECTS_ADD';
export const PROJECTS_ADD_SUCCESS = 'PROJECTS_ADD_SUCCESS';
export const PROJECTS_ADD_FAIL = 'PROJECTS_ADD_FAIL';
export const PROJECTS_REMOVE = 'PROJECTS_REMOVE';
export const PROJECTS_REMOVE_SUCCESS = 'PROJECTS_REMOVE_SUCCESS';
export const PROJECTS_REMOVE_FAIL = 'PROJECTS_REMOVE_FAIL';
export const PROJECTS_GET_ALL = 'PROJECTS_GET_ALL';
export const PROJECTS_GET_ALL_SUCCESS = 'PROJECTS_GET_ALL_SUCCESS';
export const PROJECTS_GET_ALL_FAIL = 'PROJECTS_GET_ALL_FAIL';
export const PROJECTS_GET_ONE = 'PROJECTS_GET_ONE';
export const PROJECTS_GET_ONE_SUCCESS = 'PROJECTS_GET_ONE_SUCCESS';
export const PROJECTS_GET_ONE_FAIL = 'PROJECTS_GET_ONE_FAIL';
export const PROJECTS_ADD_IMAGE = 'PROJECTS_ADD_IMAGE';
export const PROJECTS_ADD_IMAGE_SUCCESS = 'PROJECTS_ADD_IMAGE_SUCCESS';
export const PROJECTS_ADD_IMAGE_FAIL = 'PROJECTS_ADD_IMAGE_FAIL';
export const PROJECTS_REMOVE_IMAGE = 'PROJECTS_REMOVE_IMAGE';
export const PROJECTS_REMOVE_IMAGE_SUCCESS = 'PROJECTS_REMOVE_IMAGE_SUCCESS';
export const PROJECTS_REMOVE_IMAGE_FAIL = 'PROJECTS_REMOVE_IMAGE_FAIL';
export const PROJECTS_CHANGE_ORDER_IMAGE = 'PROJECTS_CHANGE_ORDER_IMAGE';
export const PROJECTS_CHANGE_ORDER_IMAGE_SUCCESS = 'PROJECTS_CHANGE_ORDER_IMAGE_SUCCESS';
export const PROJECTS_CHANGE_ORDER_IMAGE_FAIL = 'PROJECTS_CHANGE_ORDER_IMAGE_FAIL';
export const PROJECTS_SET_SHOW_SNACKBAR = 'PROJECTS_SET_SHOW_SNACKBAR';
