/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { makeSelectProductsProductsWithi18n, makeSelectSubcategorySubcategoryList } from 'services/products/selectors';
import AdminPageContainer from 'components/AdminPageContainer/AdminPageContainer';
import {
  Typography, Input, Button, FormGroup, FormControlLabel, FormHelperText,
} from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { getAllProducts, getOneProductsWithi18n, emptyOneProductsWithi18n } from 'services/products/actions';
import _ from 'lodash';
import ProductsDetailHeader from './ProductsDetailHeader';

function ProductsDetail(props) {
  const location = useLocation();
  const isNew = location.state?.newProduct || false;
  const { categoryId, subcategoryId, productId } = useParams();
  const { currentProduct } = props;

  useEffect(() => {
    console.log(`newProduct: ${isNew}`);
    if (!isNew) {
      props.getOneProductsWithi18n(productId);
    }
  }, []);

  return (
    <AdminPageContainer title="Products/Detail">
      {(!_.isEmpty(currentProduct) || isNew)
      && <ProductsDetailHeader currentProduct={isNew ? {} : currentProduct} isNew={isNew}></ProductsDetailHeader>}
    </AdminPageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  // error: makeSelectUserError(),
  // loading: makeSelectUserLoading(),
  currentProduct: makeSelectProductsProductsWithi18n(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    // getAllProducts,
    getOneProductsWithi18n,
    // emptyOneCategoryWithi18n,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

ProductsDetail.propTypes = {
  // loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // subcategoryList: PropTypes.any,
  currentProduct: PropTypes.shape(),
  // getAllProducts: PropTypes.func.isRequired,
  // emptyOneCategoryWithi18n: PropTypes.func.isRequired,
  getOneProductsWithi18n: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(ProductsDetail);
