import { createSelector } from 'reselect';
import _ from 'lodash';
import { initialState } from './reducer';

export const selectNewsDomain = (state) => state.news || initialState;
export const makeSelectNewsAll = () => createSelector(selectNewsDomain, _.property('list'));
export const makeSelectNewsLoading = () => createSelector(selectNewsDomain, _.property('loading'));
export const makeSelectNewsError = () => createSelector(selectNewsDomain, _.property('error'));
export const makeSelectNewsSnackbar = () => createSelector(selectNewsDomain, _.property('snackbar'));
export const makeSelectNewsOutletLoading = () => createSelector(selectNewsDomain, _.property('outletLoading'));
