/* eslint-disable camelcase */
import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';
import {
  DASHBOARD_GET_SUBCATEGORYLIST,
  DASHBOARD_GET_CATEGORYLIST,
  DASHBOARD_GET_PRODUCTLIST,
  DASHBOARD_GET_OPTIONLIST,
  DASHBOARD_GET_NEWSLIST,
  DASHBOARD_GET_PROJECTLIST,
} from './constants';
import {
  getCategoryListSuccess,
  getCategoryListFail,
  getProductListSuccess,
  getProductListFail,
  getSubcategoryListSuccess,
  getSubcategoryListFail,
  getOptionListSuccess,
  getOptionListFail,
  getNewsListSuccess,
  getNewsListFail,
  getProjectListSuccess,
  getProjectListFail,
} from './actions';

const api = API.create();

export function* getCategoryList() {
  const result = yield call(api.dashboard.getCategoryList, { });
  if (result.ok) {
    yield put(getCategoryListSuccess(result.data));
  } else {
    yield put(getCategoryListFail(result.data));
  }
}

export function* getProductList() {
  const result = yield call(api.dashboard.getProductList, { });
  if (result.ok) {
    yield put(getProductListSuccess(result.data));
  } else {
    yield put(getProductListFail(result.data));
  }
}

export function* getSubcategoryList() {
  const result = yield call(api.dashboard.getSubcategoryList, { });
  if (result.ok) {
    yield put(getSubcategoryListSuccess(result.data));
  } else {
    yield put(getSubcategoryListFail(result.data));
  }
}

export function* getOptionList() {
  const result = yield call(api.dashboard.getOptionList, { });
  if (result.ok) {
    yield put(getOptionListSuccess(result.data));
  } else {
    yield put(getOptionListFail(result.data));
  }
}

export function* getNewsList() {
  const result = yield call(api.dashboard.getNewsList, {});
  if (result.ok) {
    yield put(getNewsListSuccess(result.data));
  } else {
    yield put(getNewsListFail(result.data));
  }
}

export function* getProjectList() {
  const result = yield call(api.dashboard.getProjectList, {});
  console.log(result);
  if (result.ok) {
    yield put(getProjectListSuccess(result.data));
  } else {
    yield put(getProjectListFail(result.data));
  }
}

export default function* dashboardSaga() {
  yield all([
    takeLatest(DASHBOARD_GET_CATEGORYLIST, getCategoryList),
    takeLatest(DASHBOARD_GET_SUBCATEGORYLIST, getSubcategoryList),
    takeLatest(DASHBOARD_GET_PRODUCTLIST, getProductList),
    takeLatest(DASHBOARD_GET_OPTIONLIST, getOptionList),
    takeLatest(DASHBOARD_GET_NEWSLIST, getNewsList),
    takeLatest(DASHBOARD_GET_PROJECTLIST, getProjectList),
  ]);
}
