import React, { Suspense } from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Home from './Home/Home';
import Contact from './Contact/Contact';
import Products from './Products/Products';
import Category from './Products/Category/Category';
import About from './About/About';
import ESG from './ESG/ESG';
import News from './News/News';
import Projects from './Projects/Projects';
import ProductDetail from './Products/ProductDetail/ProductDetail';

const theme = createTheme();

export default function Dashboard() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback="loading">
        <Header />
        <main>
          <Routes>
            <Route index element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="about" element={<About />} />
            <Route path="news" element={<News />} />
            <Route path="projects" element={<Projects />} />
            <Route path="products" element={<Products />} />
            <Route path="products/:category" element={<Category />} />
            <Route path="products/:category/:id" element={<ProductDetail />} />
            <Route path="ESG" element={<ESG />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </main>
        <Footer />
      </Suspense>
    </ThemeProvider>
  );
}
