export const ESG_IMU_LIST = [
  {
    img: 'esg-imu-1.jpg',
    rows: 1,
    cols: 3,
  },
  {
    img: 'esg-imu-2.jpg',
    rows: 1,
    cols: 3,
  },
  {
    img: 'esg-imu-3.jpg',
    title: 'Camera',
    rows: 2,
    cols: 2,
  },
  {
    img: 'esg-imu-4.jpg',
    rows: 2,
    cols: 2,
  },
  {
    img: 'esg-imu-5.jpg',
    rows: 2,
    cols: 2,
  },
  {
    img: 'esg-imu-6.jpg',
    rows: 1,
    cols: 3,
  },
  {
    img: 'esg-imu-7.jpg',
    rows: 1,
    cols: 3,
  },
];
