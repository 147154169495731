import React, { useState, useEffect, useCallback } from 'react';
import Container from '@mui/material/Container';
import BackgroundImage from 'assets/Background/About.png';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTranslation, withTranslation } from 'react-i18next';
import PageHeaderButton from 'components/PageHeaderButton/PageHeaderButton';
import AboutCardContent from 'components/AboutCardContent/AboutCardContent';
import { isChinese, tIntermediate } from 'utils/language.utils';
import ESGTopImage from 'assets/ESG/esg-top.jpg';
import ESGTop2Image from 'assets/ESG/esg-top-2.jpg';
import ESGCoffeeImage from 'assets/ESG/esg-coffee-1.jpg';
import ESGCoffee2Image from 'assets/ESG/esg-coffee-2.jpg';
import ESGGreen1Image from 'assets/ESG/esg-green-1.jpg';
import ESGGreen2Image from 'assets/ESG/esg-green-2.jpg';
import ESGGreen3Image from 'assets/ESG/esg-green-3.jpg';
import ESGGreen4Image from 'assets/ESG/esg-green-4.jpg';
import ESGGreen5Image from 'assets/ESG/esg-green-5.jpg';
import ESGGreen6Image from 'assets/ESG/esg-green-6.jpg';
import ESGCoffee1N from 'assets/ESG/esg-coffee-1-n.svg';
import ESGCoffee1C from 'assets/ESG/esg-coffee-1-c.svg';
import ESGCoffee2N from 'assets/ESG/esg-coffee-2-n.svg';
import ESGCoffee2C from 'assets/ESG/esg-coffee-2-c.svg';
import ESGCoffee3N from 'assets/ESG/esg-coffee-3-n.svg';
import ESGCoffee3C from 'assets/ESG/esg-coffee-3-c.svg';
import ESGCoffee4N from 'assets/ESG/esg-coffee-4-n.svg';
import ESGCoffee4C from 'assets/ESG/esg-coffee-4-c.svg';
import PageContainer from 'components/PageContainer/PageContainer';
import StarIcon from 'assets/ESG/MU-Plus-Anti-Covid-Vinyl.svg';
import PlusIcon from 'assets/ESG/Plus.svg';
import ESGColorIcon from 'assets/ESG/esg-color.svg';
import POEZHIcon from 'assets/ESG/POE_ZH.svg';
import ESGCoffeeDotsIcon from 'assets/ESG/esg-coffee-dots.svg';
import POEENIcon from 'assets/ESG/POE_EN.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { Typography } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageViewer from 'react-simple-image-viewer';
import { ESG_IMU_LIST } from './contants';

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `assets/ESG/${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `assets/ESG/${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

function ESG() {
  const { t, i18n } = useTranslation();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
  }, [i18n.language]);

  return (
    <PageContainer>
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="row"
          spacing={3}
        >
          <Grid xs={12}>
            <Box
              sx={{
                backgroundImage: `url(${ESGTopImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '600px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <StarIcon />
            </Box>
          </Grid>
          <Grid xs={12}>
            <Typography sx={{ color: '#476B69' }}>
              {tIntermediate('ESG.imu.content')}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Box
              sx={{
                backgroundImage: `url(${ESGTop2Image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '600px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </Grid>
          <Grid
            xs={12}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <ImageList
              sx={{ width: 600, height: 850 }}
              variant="quilted"
              cols={6}
              rowHeight={200}
            >
              {ESG_IMU_LIST.map((item, index) => (
                <ImageListItem
                  key={item.img}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                  onClick={() => openImageViewer(index)}
                >
                  <img
                    {...srcset(item.img, 150, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                    style={{ cursor: 'pointer' }}
                  />
                </ImageListItem>
              ))}
              {isViewerOpen && (
              <ImageViewer
                src={ESG_IMU_LIST.map((x) => `assets/ESG/${x.img}`)}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside
                onClose={closeImageViewer}
              />
              )}
            </ImageList>
          </Grid>
          <Grid
            xs={12}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Stack
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                position: 'relative',
              }}
              spacing={2}
            >
              <Box>
                <PlusIcon
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }}
                />
                <Typography variant="h5" component="h5">{tIntermediate('ESG.imu.colorChoose')}</Typography>
              </Box>
              <Box>
                <ESGColorIcon />
              </Box>
              <Box>
                {tIntermediate('ESG.imu.moreColor')}
                <PlusIcon style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                }}
                />
              </Box>
            </Stack>
          </Grid>

          {/* POE */}
          <Grid xs={12} mt={5}>
            <Box
              sx={{
                backgroundImage: `url(${ESGCoffeeImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '350px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {i18n.language === 'en' ? <POEENIcon /> : <POEZHIcon />}
            </Box>
            <Box
              mt={2}
              sx={{
                backgroundImage: `url(${ESGCoffee2Image})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '350px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: 'inset 0 0 0 1000px rgba(164,108,0,.65)',
              }}
            >
              <Grid container>
                <Grid xsOffset={4} xs={2}>
                  <ESGCoffeeDotsIcon />
                </Grid>
                <Grid xs={4}>
                  <Typography variant="h4" component="h4" sx={{ color: 'white' }}>{tIntermediate('ESG.poe.title')}</Typography>
                  <Typography variant="h6" component="h6" sx={{ color: 'white' }}>{tIntermediate('ESG.poe.subtitle')}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid xs={12} container mt={3}>
            <Grid xs={2.5} xsOffset={3}>
              <Stack direction="row" spacing={4}>
                <ESGCoffee1C />
                <ESGCoffee1N />
              </Stack>
            </Grid>
            <Grid xs={5}>
              <Typography>{tIntermediate('ESG.poe.step1.title')}</Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;
                {tIntermediate('ESG.poe.step1.content1')}
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;
                {tIntermediate('ESG.poe.step1.content2')}
              </Typography>
            </Grid>

            <Grid xs={2.5} xsOffset={1} mt={3}>
              <Stack direction="row" spacing={4}>
                <ESGCoffee2C />
                <ESGCoffee2N />
              </Stack>
            </Grid>
            <Grid xs={5} mt={3}>
              <Typography>{tIntermediate('ESG.poe.step2.title')}</Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;
                {tIntermediate('ESG.poe.step2.content1')}
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;
                {tIntermediate('ESG.poe.step2.content2')}
              </Typography>
            </Grid>

            <Grid xs={3} xsOffset={4} mt={3}>
              <Stack direction="row" spacing={4}>
                <ESGCoffee3C />
                <ESGCoffee3N />
              </Stack>
            </Grid>
            <Grid xs={5} mt={3}>
              <Typography>{tIntermediate('ESG.poe.step3.title')}</Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;
                {tIntermediate('ESG.poe.step3.content1')}
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;
                {tIntermediate('ESG.poe.step3.content2')}
              </Typography>
            </Grid>

            <Grid xs={3.5} xsOffset={2.5} mt={3}>
              <Stack direction="row" spacing={4}>
                <ESGCoffee4C />
                <ESGCoffee4N />
              </Stack>
            </Grid>
            <Grid xs={5} mt={3}>
              <Typography>{tIntermediate('ESG.poe.benefit.title')}</Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;
                {tIntermediate('ESG.poe.benefit.content1')}
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;
                {tIntermediate('ESG.poe.benefit.content2')}
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;
                {tIntermediate('ESG.poe.benefit.content3')}
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;
                {tIntermediate('ESG.poe.benefit.content4')}
              </Typography>
              <Typography>
                &nbsp;&nbsp;&nbsp;
                {tIntermediate('ESG.poe.benefit.content5')}
              </Typography>
            </Grid>
          </Grid>

          {/* ESG */}
          <Grid xs={12} mt={5}>
            <Box sx={{
              backgroundColor: '#AAF0D1', height: 600, justifyContent: 'center', alignItems: 'center', display: 'flex',
            }}
            >
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  fontWeight: 600,
                  fontSize: '28rem',
                  color: '#d2d2d2',
                }}
              >
                ESG
              </Typography>
            </Box>
            <Typography
              mt={1}
              sx={{
                color: '#8A8A8A',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {tIntermediate('ESG.esg.title')}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography mt={1} sx={{ color: '#29AB87' }}>
              {tIntermediate('ESG.esg.content1')}
            </Typography>
            <Typography mt={2} sx={{ color: '#29AB87' }}>
              {tIntermediate('ESG.esg.content2')}
            </Typography>
            <Typography mt={2} sx={{ color: '#29AB87' }}>
              {tIntermediate('ESG.esg.content3')}
            </Typography>
            <Typography mt={2} sx={{ color: '#29AB87' }}>
              {tIntermediate('ESG.esg.content4')}
            </Typography>
          </Grid>
          <Grid container xs={12}>
            <Grid xs={4}>
              <Typography sx={{ fontSize: i18n.language === 'en' ? '16px' : '19.5px', color: '#7E7979' }}>{tIntermediate('ESG.esg.innomobel.content1')}</Typography>
            </Grid>
            <Grid xs={4}>
              <Box
                sx={{
                  backgroundImage: `url(${ESGGreen1Image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '280px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </Grid>
            <Grid xs={4}>
              <Box
                sx={{
                  backgroundImage: `url(${ESGGreen2Image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '280px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </Grid>
            <Grid xs={4}>
              <Box
                sx={{
                  backgroundImage: `url(${ESGGreen3Image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: i18n.language === 'en' ? '450px' : '380px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </Grid>
            <Grid xs={4}>
              <Typography sx={{ fontSize: i18n.language === 'en' ? '12px' : '20px', color: '#29AB87' }}>{tIntermediate('ESG.esg.innomobel.content2')}</Typography>
            </Grid>
            <Grid xs={4}>
              <Box
                sx={{
                  backgroundImage: `url(${ESGGreen4Image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: i18n.language === 'en' ? '450px' : '380px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </Grid>
            <Grid xs={4}>
              <Box
                sx={{
                  backgroundImage: `url(${ESGGreen5Image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '280px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </Grid>
            <Grid xs={4}>
              <Box
                sx={{
                  backgroundImage: `url(${ESGGreen6Image})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  height: '280px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </Grid>
            <Grid xs={4}>
              <Typography sx={{ fontSize: i18n.language === 'en' ? '12px' : '20px', color: '#7E7979' }}>{tIntermediate('ESG.esg.innomobel.content3')}</Typography>
            </Grid>
            {/* <Grid xs={12}>
              <Typography sx={{ fontSize: '20px', color: '#29AB87' }}>{tIntermediate('ESG.esg.innomobel.content4')}</Typography>
            </Grid> */}
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
}

export default ESG;
