/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { updater } from 'utils/reducer';
import _ from 'lodash';
import {
  DASHBOARD_GET_CATEGORYLIST,
  DASHBOARD_GET_CATEGORYLIST_SUCCESS,
  DASHBOARD_GET_CATEGORYLIST_FAIL,
  DASHBOARD_GET_PRODUCTLIST,
  DASHBOARD_GET_PRODUCTLIST_SUCCESS,
  DASHBOARD_GET_PRODUCTLIST_FAIL,
  DASHBOARD_GET_SUBCATEGORYLIST,
  DASHBOARD_GET_SUBCATEGORYLIST_SUCCESS,
  DASHBOARD_GET_SUBCATEGORYLIST_FAIL,
  DASHBOARD_GET_OPTIONLIST,
  DASHBOARD_GET_OPTIONLIST_SUCCESS,
  DASHBOARD_GET_OPTIONLIST_FAIL,
  DASHBOARD_GET_NEWSLIST,
  DASHBOARD_GET_NEWSLIST_SUCCESS,
  DASHBOARD_GET_NEWSLIST_FAIL,
  DASHBOARD_GET_PROJECTLIST,
  DASHBOARD_GET_PROJECTLIST_SUCCESS,
  DASHBOARD_GET_PROJECTLIST_FAIL,
} from './constants';

export const initialState = {
  categoryList: [],
  subcategoryList: [],
  productList: [],
  optionList: [],
  newsList: [],
  projectList: [],
  error: null,
  loading: false,
  categoryLoading: true,
  subcategoryLoading: true,
  productLoading: true,
  optionLoading: true,
  newsLoading: true,
  projectsLoading: true,
};

const dashboardReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case DASHBOARD_GET_CATEGORYLIST:
      draft.categoryLoading = true;
      break;
    case DASHBOARD_GET_CATEGORYLIST_SUCCESS:
      draft.categoryLoading = false;
      draft.categoryList = action.data;
      draft.error = null;
      break;
    case DASHBOARD_GET_CATEGORYLIST_FAIL:
      draft.categoryLoading = false;
      draft.error = action.error;
      break;
    case DASHBOARD_GET_PRODUCTLIST:
      draft.productLoading = true;
      break;
    case DASHBOARD_GET_PRODUCTLIST_SUCCESS:
      draft.productLoading = false;
      draft.productList = action.data;
      draft.error = null;
      break;
    case DASHBOARD_GET_PRODUCTLIST_FAIL:
      draft.productLoading = false;
      draft.error = action.error;
      break;
    case DASHBOARD_GET_SUBCATEGORYLIST:
      draft.subcategoryLoading = true;
      break;
    case DASHBOARD_GET_SUBCATEGORYLIST_SUCCESS:
      draft.subcategoryLoading = false;
      draft.subcategoryList = action.data;
      draft.error = null;
      break;
    case DASHBOARD_GET_SUBCATEGORYLIST_FAIL:
      draft.subcategoryLoading = false;
      draft.error = action.error;
      break;
    case DASHBOARD_GET_OPTIONLIST_SUCCESS:
      draft.optionList = action.data;
      break;
    case DASHBOARD_GET_OPTIONLIST_FAIL:
      break;
    case DASHBOARD_GET_NEWSLIST:
      draft.newsLoading = true;
      break;
    case DASHBOARD_GET_NEWSLIST_SUCCESS:
      draft.newsLoading = false;
      draft.newsList = action.data;
      break;
    case DASHBOARD_GET_NEWSLIST_FAIL:
      draft.newsLoading = false;
      draft.error = action.error;
      break;
    case DASHBOARD_GET_PROJECTLIST:
      draft.projectLoading = true;
      break;
    case DASHBOARD_GET_PROJECTLIST_SUCCESS:
      draft.projectLoading = false;
      draft.projectList = action.data;
      break;
    case DASHBOARD_GET_PROJECTLIST_FAIL:
      draft.projectLoading = false;
      draft.error = action.error;
      break;
    default:
  }
});

export default dashboardReducer;
