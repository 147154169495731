export const CATEGORY_GET_ALL = 'CATEGORY_GET_ALL';
export const CATEGORY_GET_ALL_SUCCESS = 'CATEGORY_GET_ALL_SUCCESS';
export const CATEGORY_GET_ALL_FAIL = 'CATEGORY_GET_ALL_FAIL';
export const CATEGORY_GET_ONE_WITH_I18N = 'CATEGORY_GET_ONE_WITH_I18N';
export const CATEGORY_GET_ONE_WITH_I18N_SUCCESS = 'CATEGORY_GET_ONE_WITH_I18N_SUCCESS';
export const CATEGORY_GET_ONE_WITH_I18N_FAIL = 'CATEGORY_GET_ONE_WITH_I18N_FAIL';
export const CATEGORY_GET_ONE_WITH_I18N_EMPTY = 'CATEGORY_GET_ONE_WITH_I18N_EMPTY';
export const CATEGORY_UPDATE = 'CATEGORY_UPDATE';
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS';
export const CATEGORY_UPDATE_FAIL = 'CATEGORY_UPDATE_FAIL';
export const CATEGORY_ADD = 'CATEGORY_ADD';
export const CATEGORY_ADD_SUCCESS = 'CATEGORY_ADD_SUCCESS';
export const CATEGORY_ADD_FAIL = 'CATEGORY_ADD_FAIL';
export const CATEGORY_REMOVE = 'CATEGORY_REMOVE';
export const CATEGORY_REMOVE_SUCCESS = 'CATEGORY_REMOVE_SUCCESS';
export const CATEGORY_REMOVE_FAIL = 'CATEGORY_REMOVE_FAIL';
