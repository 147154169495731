import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import _ from 'lodash';
import {
  Outlet,
} from 'react-router-dom';
import { getAllUser } from 'services/user/actions';
import { getAllOption } from 'services/option/actions';
import { makeSelectUserError, makeSelectUserLoading, makeSelectUserCurrentUser } from 'services/user/selectors';
import { getUserGroupById } from 'utils/user.utils';
import { toDate } from 'utils/date.utils';
import Tooltip from '@mui/material/Tooltip';
import { mainListItems } from '../Listitems/Listitems';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme({
  palette: {
    primary: {
      main: '#00A39C',
      darker: '#053e85',
    },
  },
});

function Dashboard(props) {
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (!token || _.isEmpty(token)) {
      window.location.replace('/admin/login');
    } else {
      console.log('Here Dashboard');
      props.getAllOption();
      props.getAllUser();
    }
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {!_.isEmpty(props.user) && (
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            {`${props.user.name} [${getUserGroupById(props.user.group).name}]`}
            &nbsp;
            <Tooltip title="Last Login time">
              <Box sx={{ textDecoration: 'underline' }}>{`${toDate(props.user.last_login_time)}`}</Box>
            </Tooltip>
            <Button
              sx={{
                marginLeft: 1,
                backgroundColor: '#201E50',
              }}
              variant="contained"
              size="medium"
              onClick={() => {
                localStorage.setItem('access_token', null);
                window.location.replace('/admin/login');
              }}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        )}
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
          </List>
        </Drawer>
        <Outlet />
      </Box>
    </ThemeProvider>
  );
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectUserError(),
  loading: makeSelectUserLoading(),
  user: makeSelectUserCurrentUser(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAllUser,
    getAllOption,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Dashboard.propTypes = {
  // loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // error: PropTypes.any,
  user: PropTypes.shape(),
  getAllUser: PropTypes.func.isRequired,
  getAllOption: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(Dashboard);
