/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import SwipeableViews from 'react-swipeable-views';
import _ from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';

const styles = {
  root: {
    padding: '0px 170px 0px 0px',
  },
  rootSm: {
    padding: '0px 200px 0px 0px',
  },
  rootXs: {
    padding: '0px 0px 0px 0px',
  },
  slideContainer: {
    padding: '0px 0px',
  },
  slide: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  image: {
    borderRadius: '15px',
    objectFit: 'contain',
    maxHeight: '600px',
    maxWidth: '400px',
    display: 'block',
  },
  imageSm: {
    borderRadius: '15px',
    objectFit: 'contain',
    maxHeight: '350px',
    maxWidth: '250px',
    display: 'block',
  },
  imageXs: {
    borderRadius: '15px',
    objectFit: 'contain',
    maxHeight: '300px',
    maxWidth: '200px',
    display: 'block',
  },
};

function SwipeableCarouselWithoutFallback(props) {
  const {
    images, activeStep, setActiveStep,
  } = props;
  const theme = useTheme();
  const isMatchedXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const isMatchedSmScreen = useMediaQuery(theme.breakpoints.only('sm'));

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const getRootStyle = () => {
    if (isMatchedXsScreen) return styles.rootXs;
    if (isMatchedSmScreen) return styles.rootSm;
    return styles.root;
  };

  const getImageStyle = () => {
    if (isMatchedXsScreen) return styles.imageXs;
    if (isMatchedSmScreen) return styles.imageSm;
    return styles.image;
  };

  useEffect(() => {
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SwipeableViews
        enableMouseEvents
        index={activeStep}
        onChangeIndex={handleStepChange}
        style={getRootStyle()}
        // style={styles.rootSm}
        slideStyle={styles.slideContainer}
      >
        {images && (images).map((image, index) => (
          <div key={`${index}-${image}`} style={styles.slide}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img
                src={image}
                alt={image}
                srcSet={image}
                style={getImageStyle()}
              />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
    </Box>
  );
}

SwipeableCarouselWithoutFallback.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  setActiveStep: PropTypes.func,
  activeStep: PropTypes.number,
};
export default SwipeableCarouselWithoutFallback;
