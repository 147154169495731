/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { makeSelectCategoryCategoryList } from 'services/category/selectors';
import { getAllCategory } from 'services/category/actions';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AdminPageContainer from 'components/AdminPageContainer/AdminPageContainer';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

function Products(props) {
  const navigate = useNavigate();

  useEffect(() => {
    props.getAllCategory();
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: false,
    },
    {
      field: 'icon',
      headerName: 'Icon',
      width: 150,
      editable: false,
      renderCell: (params) => (
        params.value
          ? (
            <img
              width="50"
              src={params.value}
            />
          )
          : <></>
      ),
    },
    {
      field: 'image',
      headerName: 'Image',
      width: 150,
      height: 200,
      editable: false,
      renderCell: (params) => (
        params.value
          ? (
            <img
              style={{
                width: '100%',
                maxHeight: '225px',
                display: 'block',
                position: 'relative',
                overflow: 'hidden',
              }}
              src={params.value}
            />
          )
          : <></>

      ),
    },
    {
      field: 'isActive',
      headerName: 'Active',
      width: 75,
      editable: false,
      renderCell: (params) => (
        params.value
          ? <DoneIcon color="success" />
          : <CloseIcon color="error" />
      ),
    },
    {
      field: 'actions',
      headerName: 'Edit',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon sx={{ fontSize: 28 }} />}
          label="Edit"
          onClick={() => {
            navigate(`category/${params.id}`, { state: { new: false } });
          }}
        />,
      ],
    },
  ];
  const rows = props.categoryList;

  return (
    <AdminPageContainer title="Products/Category" isEnableTitleCallback={false}>
      <Button
        variant="contained"
        sx={{ marginBottom: 2 }}
        onClick={() => {
          navigate('category/new', { state: { new: true } });
        }}
      >
        New Category
      </Button>
      <DataGrid
        autoHeight
        rowHeight={250}
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
      />
    </AdminPageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  // error: makeSelectUserError(),
  // loading: makeSelectUserLoading(),
  categoryList: makeSelectCategoryCategoryList(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAllCategory,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Products.propTypes = {
  // loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // error: PropTypes.any,
  categoryList: PropTypes.arrayOf(PropTypes.shape()),
  getAllCategory: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(Products);
