/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import {
  makeSelectUserCurrentUser, makeSelectUserError, makeSelectUserLoading, makeSelectUserEditingUser,
} from 'services/user/selectors';
import {
  updateUserPassword, updateUser, createUser, getUserById, emptyEditingUser, removeUser,
} from 'services/user/actions';
import { useLocation, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { USER_GROUP } from 'services/user/constants';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FilledInput from '@mui/material/FilledInput';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import { LoadingButton } from '@mui/lab';

function UserHeaderTop(props) {
  const { userId } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const {
    currentUser, editingUser, error, loading,
  } = props;
  const isNew = location.state?.newUser || false;

  const [user, setUser] = useState({
    id: !isNew ? editingUser.id || '' : '',
    user: !isNew ? editingUser.user || '' : '',
    group: !isNew ? editingUser.group || 1 : 1,
    name: !isNew ? editingUser.name || '' : '',
    password: !isNew ? editingUser.password || '' : '',
    lang: 1,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const onClickSubmit = () => {
    if (isNew) {
      props.createUser(user);
    } else {
      props.updateUser(user.id, user);
    }
  };

  useEffect(() => {
    return () => props.emptyEditingUser();
  }, []);

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '50ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Stack direction="row" spacing={2}>
        <TextField
          required
          id="filled-required"
          label="Login User:"
          value={user.user}
          variant="filled"
          helperText="Required*"
          name="user"
          sx={{ mr: 1 }}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-required"
          label="Name:"
          value={user.name}
          name="name"
          variant="filled"
          helperText="Required*"
          sx={{ mr: 1 }}
          onChange={handleChange}
        />
        <FormControl sx={{ width: '175px' }}>
          <InputLabel id="demo-simple-select-label">Group</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={user.group}
            label="group"
            name="group"
            onChange={handleChange}
          >
            {USER_GROUP.map((g) => <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>)}
          </Select>
        </FormControl>
      </Stack>
      <FormControl sx={{ width: '25ch' }} variant="filled">
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <FilledInput
          variant="filled"
          id="outlined-adornment-password"
          type={showPassword ? 'text' : 'password'}
          name="password"
          value={user.password}
          onChange={handleChange}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
            )}
          label="Password"
        />
        <FormHelperText>
          Leave blank if you don&apos;t want to change your password
        </FormHelperText>
      </FormControl>
      <br />
      {error && <ErrorComponent error={error} />}
      <br />
      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={() => { onClickSubmit(); }}
      >
        {`${isNew ? 'New' : 'Update'}`}
      </LoadingButton>
      {
        !isNew
      && (
      <LoadingButton
        loading={loading}
        variant="outlined"
        size="large"
        color="warning"
        sx={{ marginLeft: 2, marginTop: 2, marginBottom: 2 }}
        onClick={() => {
          props.removeUser(user.id);
        }}
      >
        Delete
      </LoadingButton>
      )
      }
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectUserError(),
  loading: makeSelectUserLoading(),
  currentUser: makeSelectUserCurrentUser(),
  editingUser: makeSelectUserEditingUser(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    createUser,
    updateUser,
    getUserById,
    emptyEditingUser,
    removeUser,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

UserHeaderTop.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
  currentUser: PropTypes.shape(),
  editingUser: PropTypes.shape(),
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  emptyEditingUser: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(UserHeaderTop);
