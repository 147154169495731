/* eslint-disable camelcase */
import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import API from 'api';
import _ from 'lodash';
import {
  getAllProductsSuccess,
  getAllProductsFail,
  getOneProductsWithi18nSuccess,
  getOneProductsWithi18nFail,
  updateProductsSuccess,
  updateProductsFail,
  addProductsSuccess,
  addProductsFail,
  removeProductsSuccess,
  removeProductsFail,
  addProductsImageSuccess,
  addProductsImageFail,
  removeProductsImageSuccess,
  removeProductsImageFail,
  changeProductsImageOrderSuccess,
  changeProductsImageOrderFail,
} from './actions';

import {
  PRODUCTS_GET_ALL,
  PRODUCTS_GET_ONE_WITH_I18N,
  PRODUCTS_UPDATE,
  PRODUCTS_ADD,
  PRODUCTS_REMOVE,
  PRODUCTS_ADD_IMAGE,
  PRODUCTS_CHANGE_ORDER_IMAGE,
  PRODUCTS_REMOVE_IMAGE,
  PRODUCTS_ADD_PDF,
  PRODUCTS_REMOVE_PDF,
} from './constants';

const api = API.create();

export function* getAllProducts(data) {
  const { id, sub } = data;
  const result = yield call(api.products.getAll, { id, sub });
  if (result.ok) {
    yield put(getAllProductsSuccess(result.data));
  } else {
    yield put(getAllProductsFail(result.data));
  }
}

export function* getOneWithi18n(data) {
  const { id } = data;
  const result = yield call(api.products.getOneWithi18n, id);
  if (result.ok) {
    console.log(result.data);
    yield put(getOneProductsWithi18nSuccess(result.data));
  } else {
    yield put(getOneProductsWithi18nFail(result.data));
  }
}

export function* updateProducts(data) {
  const { id, product } = data;
  console.log(data);
  const result = yield call(api.products.update, { id, product });
  if (result.ok) {
    yield put(updateProductsSuccess(result.data));
  } else {
    yield put(updateProductsFail(result.data));
  }
}

export function* addProducts(data) {
  const { subcategoryId, products } = data;
  const result = yield call(api.products.add, { subcategoryId, products });
  console.log(result);
  if (result.ok) {
    yield put(addProductsSuccess(result.data));
  } else {
    yield put(addProductsFail(result.data));
  }
}

export function* removeProducts(data) {
  const { id } = data;
  const result = yield call(api.products.remove, { id });
  if (result.ok) {
    yield put(removeProductsSuccess(result.data));
  } else {
    yield put(removeProductsFail(result.error));
  }
}

export function* addProductsImage(data) {
  const { productId, product } = data;
  const result = yield call(api.products.addImage, { productId, product });
  if (result.ok) {
    yield put(addProductsImageSuccess(result.data));
  } else {
    yield put(addProductsImageFail(result.data));
  }
}

export function* removeProductsImage(data) {
  const { id } = data;
  const result = yield call(api.products.removeImage, { id });
  if (result.ok) {
    yield put(removeProductsImageSuccess(result.data));
  } else {
    yield put(removeProductsImageFail(result.data));
  }
}

export function* changeProductsImageOrder(data) {
  const { product } = data;
  const result = yield call(api.products.changeImageOrder, { product });
  if (result.ok) {
    yield put(changeProductsImageOrderSuccess(result.data));
  } else {
    yield put(changeProductsImageOrderFail(result.data));
  }
}

export function* addProductsPdf(data) {
  const { productId, product } = data;
  const result = yield call(api.products.addPdf, { productId, product });
  if (result.ok) {
    yield put(addProductsImageSuccess(result.data));
  } else {
    yield put(addProductsImageFail(result.data));
  }
}

export function* removeProductsPdf(data) {
  const { id } = data;
  const result = yield call(api.products.removePdf, { id });
  if (result.ok) {
    yield put(removeProductsImageSuccess(result.data));
  } else {
    yield put(removeProductsImageFail(result.data));
  }
}

export default function* productsSaga() {
  yield all([
    takeLatest(PRODUCTS_GET_ONE_WITH_I18N, getOneWithi18n),
    takeLatest(PRODUCTS_GET_ALL, getAllProducts),
    takeLatest(PRODUCTS_UPDATE, updateProducts),
    takeLatest(PRODUCTS_ADD, addProducts),
    takeLatest(PRODUCTS_REMOVE, removeProducts),
    takeLatest(PRODUCTS_ADD_IMAGE, addProductsImage),
    takeLatest(PRODUCTS_REMOVE_IMAGE, removeProductsImage),
    takeLatest(PRODUCTS_CHANGE_ORDER_IMAGE, changeProductsImageOrder),
    takeLatest(PRODUCTS_ADD_PDF, addProductsPdf),
    takeLatest(PRODUCTS_REMOVE_PDF, removeProductsPdf),
  ]);
}
