import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PinterestIcon from '@mui/icons-material/Pinterest';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Copyright from 'components/Copyright/Copyright';
import { ThemeProvider, createTheme } from '@mui/system';
import { useTranslation, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { tIntermediate } from 'utils/language.utils';
import { PAGES, AVAILABLE_PAGES } from '../constants';

const UpButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: '#F5F5F5',
  border: '1px solid #DCDCDC',
  borderRadius: '25px',
  width: '180px',
  boxShadow: 'inset 0px 4px 4px rgba(208, 208, 210, 0.5)',
  backdropFilter: 'blur(10px)',
  textTransform: 'none',
  position: 'absolute',
  bottom: 0,
  '&:hover': {
    backgroundColor: '#333333',
  },
}));

const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        color: '#fff', // No more ripple, on the whole application 💣!
      },
    },
  },
  palette: {
    background: {
      dark: '#000',
    },
    text: {
      primary: '#fff',
    },
    action: {
      hoverOpacity: 0,
    },
  },
});

function Footer(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Box component="footer" sx={{ bgcolor: 'background.dark', py: 6 }} style={{ color: 'black' }}>
        <Container maxWidth="xl" disableGutters sx={{ pl: 8, pr: 8 }}>
          <Grid container rowSpacing={{ xs: 5, md: 10 }}>
            <Grid item xs={12} md={3}>
              <Stack spacing={2}>
                <Typography variant="h6" component="h2">Innomobel</Typography>
                <Typography variant="h6">
                  {tIntermediate('footer.address')}
                </Typography>
                <Typography variant="h6">
                  T  : +852 3950 8288
                  <br />
                  F  : +852 3186 2827
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  paddingBottom: '10px',
                  visibility: 'hidden',
                  display: { xs: 'none' },
                }}
              >
                Innomobel
              </Typography>
              <Stack
                direction="row"
                spacing={10}
                sx={{
                  overflowY: 'auto',
                }}
              >
                {PAGES.map((page) => (
                  <Typography
                    key={page}
                    variant="body1"
                    sx={{
                      cursor: 'pointer',
                      fontSize: 17,
                    }}
                    onClick={() => {
                      if (AVAILABLE_PAGES.includes(page)) {
                        navigate(page);
                      }
                    }}
                  >
                    {tIntermediate(`pages.${page}`)}
                  </Typography>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="h6">
                Follow Us
              </Typography>
              <Stack direction="row" spacing={2} mt={2} style={{ color: 'white' }}>
                <FacebookIcon
                  fontSize="large"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => window.open('https://www.facebook.com/Innomobelhk/', '_blank')}
                />
                <InstagramIcon
                  fontSize="large"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => window.open('https://www.instagram.com/Innomobel/', '_blank')}
                />
                <PinterestIcon
                  fontSize="large"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => window.open('https://www.pinterest.com/Innomobel/', '_blank')}
                />
              </Stack>
            </Grid>
          </Grid>
          <KeyboardArrowUpIcon
            sx={{
              position: 'fixed',
              right: 25,
              bottom: 25,
              backgroundColor: '#333333',
              color: '#fff',
              width: 50,
              height: 50,
              cursor: 'pointer',
              opacity: '75%',
              display: { sm: 'none', md: 'block' },
            }}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          />
          <Copyright />
        </Container>
      </Box>
    </ThemeProvider>
  );
}

Footer.propTypes = {
};

export default Footer;
