import { createSelector } from 'reselect';
import _ from 'lodash';
import { initialState } from './reducer';

export const selectUserDomain = (state) => state.user || initialState;
export const makeSelectUserError = () => createSelector(selectUserDomain, _.property('error'));
export const makeSelectUserLoading = () => createSelector(selectUserDomain, _.property('loading'));
export const makeSelectUserCurrentUser = () => createSelector(selectUserDomain, _.property('currentUser'));
export const makeSelectUserEditingUser = () => createSelector(selectUserDomain, _.property('editingUser'));
export const makeSelectUserList = () => createSelector(selectUserDomain, _.property('userList'));
