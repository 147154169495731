import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { tIntermediate } from 'utils/language.utils';

function InformationCardContent(props) {
  const {
    image, date, title, description, callback,
  } = props;

  return (
    <Grid
      container
      sx={{
        maxHeight: 300,
        marginBottom: 3,
        cursor: 'pointer',
        borderRadius: '25px',
        background: 'rgba(255, 255, 255, 0.75)',
        boxShadow: ' 0px 5px 5px rgba(208, 208, 210, 0.5)',
        backdropFilter: 'blur(4px)',
      }}
      onClick={callback}
    >
      <Grid
        item
        xs={5}
        md={5}
        sx={{
          padding: '0 !important',
          background: `url('${image}') no-repeat center center`,
          backgroundSize: 'cover',
        }}
      >
        {image
          && (
            <Box
              component="img"
              sx={{
                maxHeight: 300,
                borderRadius: '25px',
                width: '-webkit-fill-available',
                objectFit: 'cover',
                visibility: 'hidden',
              }}
              alt="news"
              src={image}
            />
          )}
      </Grid>
      <Grid
        item
        xs={7}
        md={7}
        mt={2}
        sx={{
          paddingTop: '0 !important',
          paddingLeft: 2,
          maxHeight: 300,
        }}
      >
        <Typography variant="h6" component="h6" sx={{ color: '#C4C4C4' }}>
          {date}
        </Typography>
        <Typography
          mt={1}
          variant="h6"
          paragraph
          sx={{
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 22,
            textTransform: 'uppercase',
            color: '#00A39C',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {tIntermediate(title)}
        </Typography>
        <Typography
          variant="h6"
          component="h6"
          paragraph
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {tIntermediate(description)}
        </Typography>
      </Grid>
    </Grid>
  );
}

InformationCardContent.propTypes = {
  image: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  callback: PropTypes.func,
};

export default InformationCardContent;
