/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { makeSelectProductsError, makeSelectProductsLoading } from 'services/products/selectors';
import {
  updateProducts, addProducts, removeProducts, addProductsImage, removeProductsImage, changeProductsImageOrder, addProductsPdf, removeProductsPdf,
} from 'services/products/actions';
import Box from '@mui/material/Box';
import {
  Stack, TextField, Typography, Input, Button, FormGroup, FormControlLabel, FormHelperText,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DescriptionIcon from '@mui/icons-material/Description';

function ProductsDetailHeader(props) {
  const {
    currentProduct, isNew, loading,
  } = props;
  const { categoryId, subcategoryId, productId } = useParams();

  const [product, setProduct] = useState({
    categoryId,
    subcategoryId,
    newUrlImages: [],
    newImages:
    (
      !_.isEmpty(currentProduct.images) ? _.orderBy(currentProduct.images, ['order'], ['asc']) : []
    )
    || [],
    en_id: currentProduct.en_id || '',
    en_title: currentProduct.en_title || '',
    en_desc: currentProduct.en_description || '',
    zh_id: currentProduct.zh_id || '',
    zh_title: currentProduct.zh_title || '',
    zh_desc: currentProduct.zh_description || '',
    pdf_id: currentProduct.pdf_id || {},
    pdf: currentProduct.pdf || null,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setProduct({ ...product, [name]: value });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const [file] = files;
    if (file) {
      setProduct({ ...product, [name]: file });
      console.log(product);
    }
  };

  const handleNewImagesChange = (event) => {
    const { name, files } = event.target;
    const [file] = files;
    if (file) {
      if (isNew) {
        const clonedImage = _.cloneDeep(product.newImages);
        const urlFile = URL.createObjectURL(file);
        const clonedUrlImage = _.cloneDeep(product.newUrlImages);
        clonedUrlImage.push(urlFile);
        clonedImage.push(file);
        setProduct({ ...product, newUrlImages: clonedUrlImage, newImages: clonedImage });
      } else {
        props.addProductsImage(productId, {
          order: product.newImages.length,
          image: file,
        });
      }
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const handleSwitchChangeForNew = (event) => {
    const { value, name } = event.target;
    const clonedUrlImages = _.cloneDeep(product.newUrlImages);
    const clonedImages = _.cloneDeep(product.newImages);

    const tempUrl = clonedUrlImages[value];
    clonedUrlImages[value] = clonedUrlImages[name];
    clonedUrlImages[name] = tempUrl;

    const temp = clonedImages[value];
    clonedImages[value] = clonedImages[name];
    clonedImages[name] = temp;
    setProduct({ ...product, newUrlImages: clonedUrlImages, newImages: clonedImages });
  };

  const handleSwitchChange = (event) => {
    const { value, name } = event.target;
    const fromId = name;
    const toId = value;

    props.changeProductsImageOrder({
      from_id: fromId,
      to_id: toId,
    });
  };

  const getFilename = () => {
    try {
      const url = product.pdf;
      return url.substring(url.lastIndexOf('/') + 1);
    } catch (error) {
      return '';
    }
  };

  useEffect(() => {
    console.log(product);
  }, []);

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '50ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        required
        id="filled-required"
        label="Product Name(en):"
        value={product.en_title}
        name="en_title"
        variant="filled"
        helperText="Required*"
        sx={{ mr: 1 }}
        onChange={handleChange}
      />
      <TextField
        required
        id="filled-required"
        label="Product Description(en):"
        value={product.en_desc}
        name="en_desc"
        variant="filled"
        helperText="Required*"
        onChange={handleChange}
        multiline
        rows={5}
        sx={{
          width: '400px',
        }}
      />
      <Stack></Stack>
      <TextField
        required
        id="filled-required"
        label="Product Name(zh):"
        value={product.zh_title}
        name="zh_title"
        variant="filled"
        helperText="Required*"
        sx={{ mr: 1 }}
        onChange={handleChange}
      />
      <TextField
        id="filled-required"
        label="Product Description(zh):"
        value={product.zh_desc}
        name="zh_desc"
        variant="filled"
        onChange={handleChange}
        multiline
        rows={5}
        fullWidth
      />
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={4}
        marginBottom="10px"
      >
        <Typography variant="h6" component="h6">PDF: </Typography>
        {_.isNull(product.pdf) ? (
          <Button
            size="small"
            variant="outlined"
            component="label"
          >
            Upload PDF
            <input
              accept="application/pdf"
              type="file"
              name="pdf"
              hidden
              onChange={(event) => {
                if (isNew) {
                  handleFileChange(event);
                } else {
                  const [file] = event.target.files;
                  props.addProductsPdf(productId, { pdf: file });
                }
              }}
            />
          </Button>
        ) : (
          <>
            <IconButton
              sx={{ color: 'black' }}
              aria-label="upload picture"
              component="label"
              onClick={() => {
                window.open(product.pdf);
              }}
            >
              <DescriptionIcon />
              <span style={{ fontSize: '12.5px' }}>{product.pdf.name || getFilename()}</span>
            </IconButton>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
              onClick={() => {
                if (isNew) {
                  setProduct({ ...product, pdf: null });
                } else {
                  props.removeProductsPdf(product.pdf_id);
                }
              }}
            >
              <HighlightOffIcon />
            </IconButton>
          </>
        )}
      </Stack>
      <Stack direction="row" spacing={2}>
        <Typography variant="h6" component="h6">Image: </Typography>
        <Button
          size="small"
          variant="outlined"
          component="label"
        >
          Add Image
          <input
            accept="image/*"
            type="file"
            name="newUrlImages"
            hidden
            onChange={handleNewImagesChange}
          />
        </Button>
      </Stack>
      {isNew
        ? (
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>Id</TableCell> */}
                  <TableCell align="right">Order</TableCell>
                  <TableCell align="right">Image</TableCell>
                  <TableCell align="right">Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {product.newUrlImages.map((image, index) => (
                  <TableRow
                // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Order"
                          value={index}
                          name={_.toString(index)}
                          onChange={handleSwitchChangeForNew}
                        >
                          {product.newUrlImages.map((im, i) => (
                            <MenuItem key={`${index}${i}`} value={i}>
                              {i + 1}
                              {i === 0 ? '(封面)' : ''}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    {/* <TableCell align="right">{row.id}</TableCell> */}
                    <TableCell align="right"><img width="200" src={image}></img></TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        onClick={() => {
                          const cloned = _.cloneDeep(product.newUrlImages);
                          const clonedImages = _.cloneDeep(product.newImages);
                          clonedImages.splice(index, 1);
                          setProduct({ ...product, newUrlImages: _.remove(cloned, (i) => i !== image), newImages: clonedImages });
                        }}
                      >
                        X
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
        : (
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>Id</TableCell> */}
                  <TableCell align="right">Order</TableCell>
                  <TableCell align="right">Image</TableCell>
                  <TableCell align="right">Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {product.newImages.map((image, index) => (
                  <TableRow
                    key={image.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Order"
                          value={image.id}
                          name={_.toString(image.id)}
                          onChange={handleSwitchChange}
                        >
                          {product.newImages.map((im, i) => (
                            <MenuItem key={`${index}${i}`} value={im.id}>
                              {im.order + 1}
                              {im.order === 0 ? '(封面)' : ''}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="right"><img width="200" src={image.url}></img></TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        onClick={() => {
                          props.removeProductsImage(image.id);
                        }}
                      >
                        X
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      {props.error && <ErrorComponent error={props.error} />}
      <LoadingButton
        loading={loading}
        variant="contained"
        size="large"
        sx={{ marginTop: 2, marginBottom: 2 }}
        onClick={() => {
          if (isNew) {
            props.addProducts(subcategoryId, product);
          } else {
            console.log(product);
            props.updateProducts(productId, product);
          }
        }}
      >
        {props.isNew ? 'New' : 'Update'}
      </LoadingButton>
      {
        !props.isNew
      && (
      <LoadingButton
        loading={loading}
        variant="outlined"
        size="large"
        color="warning"
        sx={{ marginLeft: 2, marginTop: 2, marginBottom: 2 }}
        onClick={() => {
          props.removeProducts(productId);
        }}
      >
        Delete
      </LoadingButton>
      )
      }
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectProductsError(),
  loading: makeSelectProductsLoading(),
  // currentCategory: makeSelectCategoryCategoryWithi18n(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addProducts,
    updateProducts,
    removeProducts,
    addProductsImage,
    removeProductsImage,
    changeProductsImageOrder,
    addProductsPdf,
    removeProductsPdf,
    // getAllSubcategory,
    // getOneCategoryWithi18n,
    // emptyOneCategoryWithi18n,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

ProductsDetailHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  isNew: PropTypes.bool.isRequired,
  currentProduct: PropTypes.shape(),
  removeProducts: PropTypes.func.isRequired,
  addProducts: PropTypes.func.isRequired,
  updateProducts: PropTypes.func.isRequired,
  addProductsImage: PropTypes.func.isRequired,
  removeProductsImage: PropTypes.func.isRequired,
  addProductsPdf: PropTypes.func.isRequired,
  removeProductsPdf: PropTypes.func.isRequired,
  changeProductsImageOrder: PropTypes.func.isRequired,
  error: PropTypes.any,
};

export default compose(
  withConnect,
)(ProductsDetailHeader);
