/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable global-require */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import apisauce from 'apisauce';
import _ from 'lodash';

let HTTP_SERVER_ROOT = process.env.SERVER_API_URL;

if (process.env.NODE_ENV === 'development') {
  HTTP_SERVER_ROOT = process.env.SERVER_API_URL_DEV;
}

const create = () => {
  const api = apisauce.create({
    // baseURL: `${HTTP_SERVER_ROOT}/webhook/${ADAPTER}/`,
    baseURL: `${HTTP_SERVER_ROOT}/`,
    // headers: {},
    withCredentials: true,
    timeout: 10000,
  });

  api.addRequestTransform((request) => {
    const reduxStore = require('./store');
    const key = _.get(reduxStore.default.getState(), ['user', 'access_token']) || localStorage.getItem('access_token');
    if (key) {
      request.headers.Authorization = `Bearer ${key}`;
    }
  });

  api.addMonitor((response) => {
    try {
      if (response.status === 401) {
        // de-auth
        const ignoreList = ['/admin/login'];
        const { pathname } = window.location;
        if (!ignoreList.includes(pathname)) window.location.replace('/admin/login');

        // TODO: should put this check to somewhere else, not here
        // if (!ignoreList.includes(pathname)) window.location.replace('/login');
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  });

  const auth = (function authAPI() {
    const url = 'auth/';

    return {
      login: ({ username, password }) => api.post(`${url}login`, { username, password }),
      logout: ({ id }) => api.post(`${url}logout`, { id }),
      profile: () => api.get(`${url}profile`),
    };
  }());

  const user = (function userAPI() {
    const url = 'user/';
    return {
      register: (data) => api.post(url, data),
      getAllUser: () => api.get(`${url}`),
      getUserById: (id) => api.get(`${url}${id}`),
      remove: ({ id }) => api.delete(`${url}${id}`),
      update: ({ id, data }) => api.put(`${url}${id}`, data),
      updatePassword: ({ id, data }) => api.put(`${url}password/${id}`, data),
    };
  }());

  const products = (function productsAPI() {
    const url = 'product/';
    return {
      getAll: ({ id, sub }) => api.get(`${url}all/${sub}/${id}`), // sub: category, subcategory
      getOneWithi18n: (id) => api.get(`${url}${id}`),
      // eslint-disable-next-line no-shadow
      update: ({ id, product }) => api.put(`${url}${id}`, product),
      // eslint-disable-next-line no-shadow
      add: ({ subcategoryId, products }) => {
        console.log(products);
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        const body = new FormData();
        body.append('en_title', products.en_title);
        body.append('en_desc', products.en_desc);
        body.append('zh_title', products.zh_title);
        body.append('zh_desc', products.zh_desc);
        body.append('categoryId', products.categoryId);
        if (products.newImages) {
          products.newImages.map((image) => body.append('images', image));
        }
        if (products.pdf) {
          body.append('pdf', products.pdf);
        }
        return api.post(`${url}${subcategoryId}`, body, config);
      },
      remove: ({ id }) => api.delete(`${url}${id}`, {}),
      changeImageOrder: ({ product }) => api.put(`${url}image/order`, product),
      addImage: ({ productId, product }) => {
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        const body = new FormData();
        body.append('order', product.order);
        body.append('image', product.image);
        return api.post(`${url}image/${productId}`, body, config);
      },
      removeImage: ({ id }) => api.delete(`${url}image/${id}`),
      addPdf: ({ productId, product }) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        body.append('pdf', product.pdf);
        return api.post(`${url}pdf/${productId}`, body, config);
      },
      removePdf: ({ id }) => api.delete(`${url}pdf/${id}`),
    };
  }());

  const category = (function categoryAPI() {
    const url = 'category/';
    return {
      getAll: () => api.get(url),
      getOneWithi18n: (id) => api.get(`${url}${id}`),
      // eslint-disable-next-line no-shadow
      update: ({ id, category }) => {
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        const body = new FormData();
        _.forOwn(category, (v, k) => {
          body.append(k, v);
        });
        return api.put(`${url}${id}`, body, config);
      },
      // eslint-disable-next-line no-shadow
      add: ({ category }) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        _.forOwn(category, (v, k) => {
          console.log(k);
          body.append(k, v);
        });
        return api.post(`${url}`, body, config);
      },
      remove: ({ id }) => api.delete(`${url}${id}`, {}),
    };
  }());

  const subcategory = (function subcategoryAPI() {
    const url = 'subcategory/';
    return {
      getAll: (id) => api.get(`${url}all/${id}`),
      getOneWithi18n: (id) => api.get(`${url}${id}`),
      // eslint-disable-next-line no-shadow
      update: ({ id, subcategory }) => {
        const body = new FormData();
        _.forOwn(subcategory, (v, k) => {
          body.append(k, v);
        });
        return api.put(`${url}${id}`, body);
      },
      // eslint-disable-next-line no-shadow
      add: ({ category_id, subcategory }) => {
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        const body = new FormData();
        _.forOwn(subcategory, (v, k) => {
          body.append(k, v);
        });
        body.append('category_id', category_id);
        return api.post(`${url}${category_id}`, body, config);
      },
      remove: ({ id }) => api.delete(`${url}${id}`, {}),
    };
  }());

  const option = (function optionAPI() {
    const url = 'optioni18n/';
    return {
      getAll: () => api.get(`${url}`),
      updateAbout: (data) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        _.forOwn(data, (v, k) => {
          if (k === 'newImage') {
            body.append('image', v);
          } else {
            body.append(k, v);
          }
        });

        return api.put(`${url}about`, body, config);
      },
      updateContact: (data) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        _.forOwn(data, (v, k) => {
          if (k === 'newImage') {
            body.append('image', v);
          } else {
            body.append(k, v);
          }
        });

        return api.put(`${url}contact`, body, config);
      },
    };
  }());

  const news = (function newsAPI() {
    const url = 'news/';
    return {
      getAll: () => api.get(`${url}`, {}),
      get: (id) => api.get(`${url}${id}`, {}),
      update: ({ id, news }) => api.put(`${url}${id}`, news),
      add: (news) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        body.append('en_name', news.en_name);
        body.append('en_desc', news.en_desc);
        body.append('zh_name', news.zh_name);
        body.append('zh_desc', news.zh_desc);
        body.append('date', news.date);
        if (news.newImages) {
          news.newImages.map((image) => body.append('images', image));
        }
        return api.post(`${url}`, body, config);
      },
      remove: ({ id }) => api.delete(`${url}${id}`, {}),
      changeImageOrder: (news) => api.put(`${url}image/order`, news),
      addImage: (newsId, news) => {
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        const body = new FormData();
        body.append('order', news.order);
        body.append('image', news.image);
        return api.post(`${url}image/${newsId}`, body, config);
      },
      removeImage: ({ id }) => api.delete(`${url}image/${id}`),
    };
  }());

  const projects = (function projectsAPI() {
    const url = 'projects/';
    return {
      getAll: () => api.get(`${url}`, {}),
      get: (id) => api.get(`${url}${id}`, {}),
      update: ({ id, project }) => api.put(`${url}${id}`, project),
      add: (project) => {
        const body = new FormData();
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        body.append('en_name', project.en_name);
        body.append('en_desc', project.en_desc);
        body.append('zh_name', project.zh_name);
        body.append('zh_desc', project.zh_desc);
        body.append('date', project.date);
        if (project.newImages) {
          project.newImages.map((image) => body.append('images', image));
        }
        return api.post(`${url}`, body, config);
      },
      remove: ({ id }) => api.delete(`${url}${id}`, {}),
      changeImageOrder: (project) => api.put(`${url}image/order`, project),
      addImage: (projectId, project) => {
        const config = {};
        config.headers = { 'Content-Type': 'multipart/form-data' };
        const body = new FormData();
        body.append('order', project.order);
        body.append('image', project.image);
        return api.post(`${url}image/${projectId}`, body, config);
      },
      removeImage: ({ id }) => api.delete(`${url}image/${id}`),
    };
  }());

  const dashboard = (function userAPI() {
    const url = 'dashboard/';
    return {
      getCategoryList: () => api.get(`${url}category`, {}),
      getSubcategoryList: () => api.get(`${url}subcategory`, {}),
      getProductList: () => api.get(`${url}product`, {}),
      getOptionList: () => api.get(`${url}option`, {}),
      getNewsList: () => api.get(`${url}news`, {}),
      getProjectList: () => api.get(`${url}projects`, {}),
    };
  }());

  return {
    auth,
    user,
    products,
    category,
    subcategory,
    dashboard,
    option,
    news,
    projects,
  };
};

export default {
  create,
};
