/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Container from '@mui/material/Container';
import BackgroundImage from 'assets/Background/About.png';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { useTranslation, withTranslation } from 'react-i18next';
import PageHeaderButton from 'components/PageHeaderButton/PageHeaderButton';
import AboutCardContent from 'components/AboutCardContent/AboutCardContent';
import { isChinese, tIntermediate } from 'utils/language.utils';
import { getOptionContent } from 'utils/option.utils';
import AboutImage from 'assets/About/1.png';
import PageContainer from 'components/PageContainer/PageContainer';
import {
  makeSelectDashboardOptionList,
} from 'services/dashboard/selectors';
import _ from 'lodash';

function About(props) {
  const { optionList } = props;
  const { t, i18n } = useTranslation();
  const getAboutContent = (key, langId = (isChinese(i18n.language) ? 2 : 1)) => getOptionContent(optionList, key, langId);
  useEffect(() => {
  }, []);

  return (
    <PageContainer
      backgroundImage={BackgroundImage}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <PageHeaderButton text={tIntermediate(getAboutContent('about_page_name')?.value) || t('about.header')} />
          </Grid>
          <Grid item xs={12} md={12} mt={3} sx={{ width: '100%' }}>
            <Card sx={{
              borderRadius: '25px', background: 'rgba(255, 255, 255, 0.75)', boxShadow: ' 0px 5px 5px rgba(208, 208, 210, 0.5)', backdropFilter: 'blur(4px)',
            }}
            >
              <CardContent sx={{
                flex: 1, paddingLeft: '50px', paddingRight: '50px', marginBottom: '25px',
              }}
              >
                <Grid container mt={2}>
                  <Grid item xs={12} mt={1}>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: tIntermediate(getAboutContent('about_content').value) }}
                    />
                    {/* {getAboutContent('about_content', 1)} */}
                  </Grid>
                  {/* <Grid item xs={12} mt={1}>
                    <AboutCardContent title={t('about.subheader1.header')} context={t('about.subheader1.context')} />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <AboutCardContent title={t('about.subheader2.header')} context={t('about.subheader2.context')} />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <AboutCardContent title={t('about.subheader3.header')} context={t('about.subheader3.context')} />
                  </Grid>
                  {isChinese(i18n.language)
                  && (
                  <Grid item xs={12} mt={1}>
                    <AboutCardContent title={t('about.subheader4.header')} context={t('about.subheader4.context')} />
                  </Grid>
                  )} */}
                  <Grid item xs={12}>
                    <Box
                      component="img"
                      sx={{
                        width: '100%',
                      }}
                      alt="about"
                      src={getAboutContent('about_content_image', 1).value || AboutImage}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  optionList: makeSelectDashboardOptionList(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

About.propTypes = {
  optionList: PropTypes.any,
};

export default compose(
  withConnect,
)(About);
