/* eslint-disable default-param-last */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import { updater } from 'utils/reducer';
import _ from 'lodash';
import {
  CATEGORY_GET_ALL,
  CATEGORY_GET_ALL_SUCCESS,
  CATEGORY_GET_ALL_FAIL,
  CATEGORY_GET_ONE_WITH_I18N,
  CATEGORY_GET_ONE_WITH_I18N_SUCCESS,
  CATEGORY_GET_ONE_WITH_I18N_FAIL,
  CATEGORY_GET_ONE_WITH_I18N_EMPTY,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_ADD,
  CATEGORY_ADD_SUCCESS,
  CATEGORY_ADD_FAIL,
  CATEGORY_REMOVE,
  CATEGORY_REMOVE_SUCCESS,
  CATEGORY_REMOVE_FAIL,
} from './constants';

export const initialState = {
  categoryList: [],
  currentCategory: {},
  error: null,
  loading: false,
};

const categoryReducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case CATEGORY_GET_ALL:
    case CATEGORY_UPDATE:
      draft.loading = true;
      break;
    case CATEGORY_GET_ALL_SUCCESS:
      draft.loading = false;
      draft.categoryList = action.data;
      draft.error = null;
      break;
    case CATEGORY_GET_ALL_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case CATEGORY_GET_ONE_WITH_I18N:
      draft.loading = true;
      break;
    case CATEGORY_GET_ONE_WITH_I18N_SUCCESS:
      draft.loading = false;
      draft.currentCategory = action.data;
      draft.error = null;
      break;
    case CATEGORY_GET_ONE_WITH_I18N_FAIL:
    case CATEGORY_UPDATE_FAIL:
    case CATEGORY_ADD_FAIL:
    case CATEGORY_REMOVE_FAIL:
      draft.loading = false;
      draft.error = action.error;
      break;
    case CATEGORY_GET_ONE_WITH_I18N_EMPTY:
      draft.currentCategory = {};
      break;
    case CATEGORY_UPDATE_SUCCESS:
    case CATEGORY_REMOVE_SUCCESS:
    case CATEGORY_ADD_SUCCESS:
      draft.loading = false;
      window.location.reload();
      break;
    case CATEGORY_ADD:
      draft.loading = true;
      break;
    case CATEGORY_REMOVE:
      draft.loading = true;
      break;
    default:
  }
});

export default categoryReducer;
