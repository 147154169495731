import { createSelector } from 'reselect';
import _ from 'lodash';
import { initialState } from './reducer';

export const selectProjectsDomain = (state) => state.projects || initialState;
export const makeSelectProjectsAll = () => createSelector(selectProjectsDomain, _.property('list'));
export const makeSelectProjectsLoading = () => createSelector(selectProjectsDomain, _.property('loading'));
export const makeSelectProjectsError = () => createSelector(selectProjectsDomain, _.property('error'));
export const makeSelectProjectsOutletLoading = () => createSelector(selectProjectsDomain, _.property('outletLoading'));
export const makeSelectProjectsSnackbar = () => createSelector(selectProjectsDomain, _.property('snackbar'));
