import * as React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

function AboutCardContent(props) {
  const { title, context } = props;
  return (
    <>
      <Typography component="h6" variant="h6" sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant="subtitle1" paragraph>
        {context}
      </Typography>
    </>
  );
}

AboutCardContent.propTypes = {
  title: PropTypes.string,
  context: PropTypes.string,
};

export default AboutCardContent;
