/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-danger */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Container from '@mui/material/Container';
import BackgroundImage from 'assets/Background/News.png';
import Grid from '@mui/material/Grid';
import { useTranslation, withTranslation } from 'react-i18next';
import PageHeaderButton from 'components/PageHeaderButton/PageHeaderButton';
import { isChinese, tIntermediate } from 'utils/language.utils';
import {
  makeSelectDashboardNewsList,
} from 'services/dashboard/selectors';
import AboutImage from 'assets/News/Alan_s Recommendation/1.jpg';
import PageContainer from 'components/PageContainer/PageContainer';
import InformationCardContent from 'components/InformationCardContent/InformationCardContent';
import InformationModal from 'components/InformationModal/InformationModal';
import _ from 'lodash';
import { getNewsList } from 'services/dashboard/actions';
import { NEWS_LIST } from './constants';

function News(props) {
  const { newsList } = props;
  const { t, i18n } = useTranslation();

  const [open, setOpen] = useState(false);
  const [activeNews, setActiveNews] = useState({});

  const getCorrectLanguageFromChinese = () => (i18n.language === 'cn' ? 'zh' : i18n.language);

  const handleOpen = (id) => {
    const re = _.cloneDeep(_.find(newsList, { id }));
    re.date = re.date.split('T')[0];
    re.title = re[getCorrectLanguageFromChinese()].name;
    re.description = re[getCorrectLanguageFromChinese()].description;
    re.images = re.images ? re.images.map((x) => x.url) : null;
    setActiveNews(re);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useState(() => {
    props.getNewsList();
  }, []);

  return (
    <PageContainer
      backgroundImage={BackgroundImage}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <PageHeaderButton text={tIntermediate('news.header')} />
          </Grid>
          <Grid item xs={12} md={12} mt={2} sx={{ width: { xs: '100%' } }}>
            {/* {getActiveNewsList().map((news) => (
              <InformationCardContent
                key={news.id}
                image={news.images[0]}
                date={news.date}
                title={news.title}
                description={news.description}
                callback={() => handleOpen(news.id)}
              />
            ))} */}
            {newsList.map((news) => (
              <InformationCardContent
                key={news.id}
                image={news.images ? news.images[0].url : null}
                date={news.date.split('T')[0]}
                title={news[getCorrectLanguageFromChinese()].name}
                description={news[getCorrectLanguageFromChinese()].description}
                callback={() => handleOpen(news.id)}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
      <InformationModal open={open} handleClose={handleClose} data={activeNews} />
    </PageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  newsList: makeSelectDashboardNewsList(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getNewsList,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

News.propTypes = {
  newsList: PropTypes.any,
  getNewsList: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(News);
