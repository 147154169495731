import * as React from 'react';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Check from '@mui/icons-material/Check';

const SubCategoryMenu = styled(({
  items, selectedIndex, setSelectedIndex, setPage, handleClose, ...other
}) => (
  <Menu
    {...other}
    onClose={handleClose}
  >
    {items && items.filter((x) => x !== '').map((item, index) => (
      <MenuItem
        key={item}
        onClick={() => {
          setSelectedIndex(index);
          setPage(1);
          handleClose();
        }}
      >
        <ListItemIcon sx={(index !== selectedIndex) ? {
          visibility: 'hidden',
        } : {}}
        >
          <Check sx={{ color: '#fff' }} />
        </ListItemIcon>

        {item}
      </MenuItem>
    ))}
  </Menu>
))(({ theme }) => ({
  '& .MuiPaper-root': {
    color: '#fff',
    background: '#666666',
    border: '1px solid #666666',
    boxShadow: 'inset 0px 0px 12px 2px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    marginTop: theme.spacing(1),
    minWidth: 180,
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      minHeight: '25px',
    },
  },
}));

export default SubCategoryMenu;
