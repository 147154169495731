/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  makeSelectProjectsError, makeSelectProjectsLoading, makeSelectProjectsAll, makeSelectProjectsSnackbar,
} from 'services/projects/selectors';
import {
  updateProjects, addProjects, removeProjects, addProjectsImage, removeProjectsImage, changeProjectsImageOrder,
  setProjectsShowSnackbar,
} from 'services/projects/actions';
import Box from '@mui/material/Box';
import {
  Stack, TextField, Typography, Input, Button, FormGroup, FormControlLabel, FormHelperText,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import AdminPageContainer from 'components/AdminPageContainer/AdminPageContainer';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SimpleSnackbar from 'components/SimpleSnackbar/SimpleSnackbar';

function ProjectsHeader(props) {
  const {
    loading, list, snackbar,
  } = props;
  const { projectId } = useParams();
  const isNew = !projectId;
  const currentProjects = _.find(list, { id: _.toNumber(projectId) });
  const [project, setProject] = useState({
    newUrlImages: [],
    newImages:
    isNew ? [] : (
      !_.isEmpty(currentProjects.images) ? _.orderBy(currentProjects.images, ['order'], ['asc']) : []
    ),
    en_id: isNew ? '' : currentProjects.en.id,
    en_name: isNew ? '' : currentProjects.en.name,
    en_desc: isNew ? '' : currentProjects.en.description,
    zh_id: isNew ? '' : currentProjects.zh.id,
    zh_name: isNew ? '' : currentProjects.zh.name,
    zh_desc: isNew ? '' : currentProjects.zh.description,
    date: isNew ? dayjs(Date.now()) : dayjs(currentProjects.date),
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setProject({ ...project, [name]: value });
  };

  const handleDayChange = (newValue) => {
    setProject({ ...project, date: dayjs(newValue) });
  };

  const handleNewImagesChange = (event) => {
    const { name, files } = event.target;
    const [file] = files;
    if (file) {
      if (isNew) {
        const clonedImages = _.cloneDeep(project.newImages);
        const urlFile = URL.createObjectURL(file);
        const clonedUrlImages = _.cloneDeep(project.newUrlImages);
        clonedUrlImages.push(urlFile);
        clonedImages.push(file);
        setProject({ ...project, newUrlImages: clonedUrlImages, newImages: clonedImages });
      } else {
        props.addProjectsImage(
          projectId,
          {
            order: project.newImages.length,
            image: file,
          },
        );
      }
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const handleSwitchChangeForNew = (event) => {
    const { value, name } = event.target;
    const clonedUrlImagess = _.cloneDeep(project.newUrlImages);
    const clonedImagess = _.cloneDeep(project.newImages);

    const tempUrl = clonedUrlImagess[value];
    clonedUrlImagess[value] = clonedUrlImagess[name];
    clonedUrlImagess[name] = tempUrl;

    const temp = clonedImagess[value];
    clonedImagess[value] = clonedImagess[name];
    clonedImagess[name] = temp;
    setProject({ ...project, newUrlImages: clonedUrlImagess, newImages: clonedImagess });
  };

  const handleSwitchChange = (event) => {
    const { value, name } = event.target;
    const fromId = name;
    const toId = value;
    props.changeProjectsImageOrder({
      from_id: fromId,
      to_id: toId,
    });
  };

  useEffect(() => {
  }, []);

  return (
    <AdminPageContainer title={`Projects/${isNew ? 'Create' : projectId}`}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { width: '50ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          required
          id="filled-required"
          label="Projects Name(en_name):"
          value={project.en_name}
          name="en_name"
          variant="filled"
          helperText="Required*"
          sx={{ mr: 1 }}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-required"
          label="Projects Description(en_desc):"
          value={project.en_desc}
          name="en_desc"
          variant="filled"
          helperText="Required*"
          onChange={handleChange}
          multiline
          rows={5}
          sx={{
            width: '400px',
          }}
        />
        <TextField
          required
          id="filled-required"
          label="名稱 (zh_name):"
          value={project.zh_name}
          name="zh_name"
          variant="filled"
          helperText="Required*"
          sx={{ mr: 1 }}
          onChange={handleChange}
        />
        <TextField
          id="filled-required"
          label="內容 (zh_desc):"
          value={project.zh_desc}
          name="zh_desc"
          variant="filled"
          onChange={handleChange}
          multiline
          rows={5}
          fullWidth
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="Projects Date"
            inputFormat="MM/DD/YYYY"
            value={project.date}
            onChange={handleDayChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        {!isNew && (
          <div>
            <LoadingButton
              loading={loading}
              variant="contained"
              size="large"
              sx={{ marginTop: 2, marginBottom: 2 }}
              onClick={() => {
                props.updateProjects({ id: projectId, project });
              }}
            >
              Update
            </LoadingButton>
            <LoadingButton
              loading={loading}
              variant="outlined"
              size="large"
              color="warning"
              sx={{ marginLeft: 2, marginTop: 2, marginBottom: 2 }}
              onClick={() => {
                props.removeProjects({ id: projectId });
              }}
            >
              Delete
            </LoadingButton>
          </div>
        )}
        <Typography variant="h6" component="h6" sx={{ mt: 1 }}>Image: </Typography>
        {isNew
          ? (
            <div>
              <Button
                size="small"
                variant="outlined"
                component="label"
              >
                Add Image
                <input
                  accept="image/*"
                  type="file"
                  name="newUrlImages"
                  hidden
                  onChange={handleNewImagesChange}
                />
              </Button>
              <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Id</TableCell> */}
                      <TableCell align="right">Order</TableCell>
                      <TableCell align="right">Image</TableCell>
                      <TableCell align="right">Remove</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {project.newUrlImages.map((image, index) => (
                      <TableRow
                      // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <FormControl>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Order"
                              value={index}
                              name={_.toString(index)}
                              onChange={handleSwitchChangeForNew}
                            >
                              {project.newUrlImages.map((im, i) => (
                                <MenuItem key={`${index}${i}`} value={i}>
                                  {i + 1}
                                  {i === 0 ? '(封面)' : ''}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell align="right"><img width="250" src={image}></img></TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            onClick={() => {
                              const cloned = _.cloneDeep(project.newUrlImages);
                              const clonedImagess = _.cloneDeep(project.newImages);
                              clonedImagess.splice(index, 1);
                              setProject({ ...project, newUrlImages: _.remove(cloned, (i) => i !== image), newImages: clonedImagess });
                            }}
                          >
                            X
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )
          : (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Id</TableCell> */}
                    <TableCell align="right">Order</TableCell>
                    <TableCell align="right">Image</TableCell>
                    <TableCell align="right">Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {project.newImages.map((image, index) => (
                    <TableRow
                      key={image.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Order"
                            value={image.id}
                            name={_.toString(image.id)}
                            onChange={handleSwitchChange}
                          >
                            {project.newImages.map((im, i) => (
                              <MenuItem key={`${index}${i}`} value={im.id}>
                                {im.order + 1}
                                {im.order === 0 ? '(封面)' : ''}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="right"><img width="200" src={image.url}></img></TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          onClick={() => {
                            props.removeProjectsImage(image.id);
                          }}
                        >
                          X
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        {props.error && <ErrorComponent error={props.error} />}
        {isNew ? (
          <LoadingButton
            loading={loading}
            variant="contained"
            size="large"
            sx={{ marginTop: 2, marginBottom: 2 }}
            onClick={() => {
              props.addProjects(project);
            }}
          >
            New
          </LoadingButton>
        ) : (
          <LoadingButton
            loading={loading}
            size="large"
            variant="outlined"
            component="label"
            sx={{
              marginTop: 2,
            }}
          >
            Add Image
            <input
              accept="image/*"
              type="file"
              name="newUrlImages"
              hidden
              onChange={handleNewImagesChange}
            />
          </LoadingButton>
        )}
      </Box>
      <SimpleSnackbar
        open={snackbar.isShow}
        handleClose={() => props.setProjectsShowSnackbar(false)}
        title={snackbar.title}
      />
    </AdminPageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectProjectsError(),
  loading: makeSelectProjectsLoading(),
  list: makeSelectProjectsAll(),
  snackbar: makeSelectProjectsSnackbar(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addProjects,
    updateProjects,
    removeProjects,
    addProjectsImage,
    removeProjectsImage,
    changeProjectsImageOrder,
    setProjectsShowSnackbar,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

ProjectsHeader.defaultProps = {
  currentProjects: {
    images: [],
    en_id: '',
    en_name: '',
    en_description: '',
    zh_id: '',
    zh_name: '',
    zh_description: '',
    date: dayjs(Date.now()),
  },
};

ProjectsHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentProjects: PropTypes.shape(),
  removeProjects: PropTypes.func.isRequired,
  addProjects: PropTypes.func.isRequired,
  updateProjects: PropTypes.func.isRequired,
  addProjectsImage: PropTypes.func.isRequired,
  removeProjectsImage: PropTypes.func.isRequired,
  changeProjectsImageOrder: PropTypes.func.isRequired,
  setProjectsShowSnackbar: PropTypes.func.isRequired,
  error: PropTypes.any,
  list: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
  }),
};

export default compose(
  withConnect,
)(ProjectsHeader);
