/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { makeSelectCategoryCategoryWithi18n } from 'services/category/selectors';
import { makeSelectSubcategorySubcategoryList } from 'services/subcategory/selectors';
import { getOneCategoryWithi18n, emptyOneCategoryWithi18n } from 'services/category/actions';
import { getAllSubcategory } from 'services/subcategory/actions';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import AdminPageContainer from 'components/AdminPageContainer/AdminPageContainer';
import Button from '@mui/material/Button';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import _ from 'lodash';
import CategoryHeader from './CategoryHeader';
import { TYPE_MIDDLE_SUBCATEGORY, TYPE_SUBCATEGORY } from './constants';

function Category(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { categoryId } = useParams();
  const { currentCategory } = props;
  const isNew = location.state?.new || false;

  useEffect(() => {
    if (!isNew) {
      props.getOneCategoryWithi18n(categoryId);
      props.getAllSubcategory(categoryId);
    }
    return () => props.emptyOneCategoryWithi18n();
  }, []);

  const hasMiddleCategory = () => {
    if (typeof currentCategory.middle_category !== 'undefined') {
      return currentCategory.middle_category.length > 0;
    }
    return false;
  };

  const columns = [
    {
      field: 'id',
      headerName: `${hasMiddleCategory() ? 'Middle category' : 'Subcategory'} Id`,
      width: hasMiddleCategory() ? 200 : 150,
      editable: false,
    },
    {
      field: 'en',
      headerName: 'English',
      width: 150,
      editable: false,
      renderCell: (params) => (hasMiddleCategory() ? params.value.name : params.value),
    },
    {
      field: 'zh',
      headerName: 'Chinese(Tranditional)',
      width: 150,
      editable: false,
      renderCell: (params) => (hasMiddleCategory() ? params.value.name : params.value),
    },
    {
      field: 'image',
      headerName: 'Image',
      width: 150,
      editable: false,
      renderCell: (params) => (
        params.value
          ? (
            <img
              style={{
                width: '100%',
                maxHeight: '225px',
                display: 'block',
                position: 'relative',
                overflow: 'hidden',
              }}
              src={params.value}
            />
          )
          : <></>
      ),
    },
    {
      field: 'actions',
      headerName: 'Edit',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon sx={{ fontSize: 28 }} />}
          label="Edit"
          onClick={() => {
            console.log(params);
            if (hasMiddleCategory()) {
              navigate(`/admin/products/category/${params.id}`, { replace: true });
              navigate(0);
            } else {
              navigate(`subcategory/${params.id}`, { state: { new: false, type: hasMiddleCategory() ? TYPE_MIDDLE_SUBCATEGORY : TYPE_SUBCATEGORY } }); // HERE
            }
          }}
        />,
      ],
    },
  ];

  // const rows = currentCategory.hasMiddleSubcategory ? _.filter(props.subcategoryList, ['type', 1]) : _.filter(props.subcategoryList, ['type', 2]);
  const rows = hasMiddleCategory() ? currentCategory.middle_category : props.subcategoryList;

  return (
    <AdminPageContainer title={`Products/Category${isNew ? '/new' : `/${currentCategory.name}`}`}>
      {(!_.isEmpty(currentCategory) || isNew)
      && <CategoryHeader currentCategory={isNew ? {} : currentCategory} isNew={isNew} />}
      {!isNew
      && (
      <>
        <Divider
          variant="middle"
          sx={{
            marginBottom: 2,
            borderBottomWidth: 2,
            background: '#00A39C',
          }}
        />
        {
        props.subcategoryList.length === 0 && currentCategory.parent_id === -1
          && (
          <Button
            variant="contained"
            sx={{ marginBottom: 2 }}
            onClick={() => {
              const path = 'subcategory/new';
              navigate(path, { state: { new: true, type: TYPE_MIDDLE_SUBCATEGORY } });
            }}
          >
            New Middle Category
          </Button>
          )
        }
        &nbsp;&nbsp;&nbsp;
        {!hasMiddleCategory()
        && (
        <Button
          variant="contained"
          sx={{ marginBottom: 2 }}
          onClick={() => {
            const path = 'subcategory/new';
            navigate(path, { state: { new: true, type: TYPE_SUBCATEGORY } });
          }}
        >
          New subcategory
        </Button>
        )}
      </>
      )}
      {!isNew
      && (
      <DataGrid
        rows={rows}
        rowHeight={250}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[15]}
        disableSelectionOnClick
        columnVisibilityModel={{
          image: hasMiddleCategory(),
        }}
      />
      )}
    </AdminPageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  // error: makeSelectUserError(),
  // loading: makeSelectUserLoading(),
  subcategoryList: makeSelectSubcategorySubcategoryList(),
  currentCategory: makeSelectCategoryCategoryWithi18n(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAllSubcategory,
    getOneCategoryWithi18n,
    emptyOneCategoryWithi18n,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Category.propTypes = {
  // loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  subcategoryList: PropTypes.any,
  currentCategory: PropTypes.shape(),
  getAllSubcategory: PropTypes.func.isRequired,
  emptyOneCategoryWithi18n: PropTypes.func.isRequired,
  getOneCategoryWithi18n: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(Category);
