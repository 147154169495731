/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import { makeSelectOptionAll, makeSelectOptionError } from 'services/option/selectors';
import { updateOptionContact } from 'services/option/actions';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import AdminPageContainer from 'components/AdminPageContainer/AdminPageContainer';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import {
  EditorState, convertToRaw, convertFromHTML, ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Contact.scss';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import _ from 'lodash';
import { LANG } from '../constants';

function Contact(props) {
  const { error, data } = props;
  const [newImage, setNewImage] = useState(null);
  const [contact, setContact] = useState(data);
  const customziedToolbar = { options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker'] };
  console.log(data);
  const blocksFromHtmlEn = htmlToDraft(_.find(data, (o) => o.option === 'contact_location_content' && o.lang.id === LANG.EN)?.value || '');
  const blocksFromHtmlZh = htmlToDraft(_.find(data, (o) => o.option === 'contact_location_content' && o.lang.id === LANG.ZH)?.value || '');
  const [valueEn, setValueEn] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHtmlEn.contentBlocks, blocksFromHtmlEn.entityMap)));
  const [valueZh, setValueZh] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHtmlZh.contentBlocks, blocksFromHtmlZh.entityMap)));

  const blocksFromHtmlFactoryLocation1_en = htmlToDraft(_.find(data, (o) => o.option === 'contact_factory_location1' && o.lang.id === LANG.EN)?.value || '');
  const blocksFromHtmlFactoryLocation1_zh = htmlToDraft(_.find(data, (o) => o.option === 'contact_factory_location1' && o.lang.id === LANG.ZH)?.value || '');
  const [valueFactoryLocation1_en, setValueFactoryLocation1_en] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHtmlFactoryLocation1_en.contentBlocks, blocksFromHtmlFactoryLocation1_en.entityMap)));
  const [valueFactoryLocation1_zh, setValueFactoryLocation1_zh] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHtmlFactoryLocation1_zh.contentBlocks, blocksFromHtmlFactoryLocation1_zh.entityMap)));

  const blocksFromHtmlFactoryLocation2_en = htmlToDraft(_.find(data, (o) => o.option === 'contact_factory_location2' && o.lang.id === LANG.EN)?.value || '');
  const blocksFromHtmlFactoryLocation2_zh = htmlToDraft(_.find(data, (o) => o.option === 'contact_factory_location2' && o.lang.id === LANG.ZH)?.value || '');
  const [valueFactoryLocation2_en, setValueFactoryLocation2_en] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHtmlFactoryLocation2_en.contentBlocks, blocksFromHtmlFactoryLocation2_en.entityMap)));
  const [valueFactoryLocation2_zh, setValueFactoryLocation2_zh] = useState(EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHtmlFactoryLocation2_zh.contentBlocks, blocksFromHtmlFactoryLocation2_zh.entityMap)));

  const getContactContent = (key, langId) => _.find(contact, (o) => o.option === key && o.lang.id === langId);
  const getContactKeyIndex = (key, langId) => _.findIndex(contact, (o) => o.option === key && o.lang.id === langId);

  const handleChangeValue = (event) => {
    const { name, value } = event.target;
    const newContact = _.cloneDeep(contact);
    switch (name) {
      case 'contact_page_name_en': {
        const index = getContactKeyIndex('contact_page_name', LANG.EN);
        newContact[index].value = value;
        break;
      }
      case 'contact_page_name_zh': {
        const index = getContactKeyIndex('contact_page_name', LANG.ZH);
        newContact[index].value = value;
        break;
      }
      case 'contact_factory_en': {
        const index = getContactKeyIndex('contact_factory_name', LANG.EN);
        newContact[index].value = value;
        break;
      }
      case 'contact_factory_zh': {
        const index = getContactKeyIndex('contact_factory_name', LANG.ZH);
        newContact[index].value = value;
        break;
      }
      default:
    }
    setContact(newContact);
  };

  const handleFileChange = (event) => {
    const { files } = event.target;
    const [file] = files;
    if (file) {
      setNewImage(file);
    }
  };

  useEffect(() => {
  }, []);

  return (
    <AdminPageContainer title="Contact" isEnableTitleCallback={false}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { width: '50ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          required
          id="filled-required"
          label="Page Name(en):"
            // defaultValue="Contact"
          value={getContactContent('contact_page_name', LANG.EN)?.value}
          name="contact_page_name_en"
          onChange={handleChangeValue}
          variant="filled"
          helperText="Required*"
          sx={{ mr: 1 }}
        />
        <TextField
          required
          id="filled-required"
          name="contact_page_name_zh"
          onChange={handleChangeValue}
          label="Page Name(zh):"
          value={getContactContent('contact_page_name', LANG.ZH)?.value}
          variant="filled"
          helperText="Required*"
        />
      </Box>
      <Typography variant="h6" gutterBottom>
        Location (en):
      </Typography>
      <div className="">
        <Editor
          editorState={valueEn}
          toolbarClassName="rdw-storybook-toolbar"
          wrapperClassName="rdw-storybook-wrapper"
          editorClassName="editor"
          onEditorStateChange={setValueEn}
          toolbar={customziedToolbar}
        />
      </div>
      <Typography variant="h6" gutterBottom mt={1}>
        Location (zh):
      </Typography>
      <div className="">
        <Editor
          editorState={valueZh}
          toolbarClassName="rdw-storybook-toolbar"
          wrapperClassName="rdw-storybook-wrapper"
          editorClassName="editor"
          onEditorStateChange={setValueZh}
          toolbar={customziedToolbar}
        />
      </div>
      <br />
      <img
        width="320"
        src={newImage ? URL.createObjectURL(newImage) : getContactContent('contact_location_image', LANG.EN)?.value}
      />
      <br />
      <Button
        size="small"
        variant="outlined"
        component="label"
      >
        Upload Image
        <input
          accept="image/*"
          type="file"
          name="image"
          hidden
          onChange={handleFileChange}
        />
      </Button>
      <br />
      <Typography variant="h6" gutterBottom mt={1}>
        Factory (en):
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="filled-required"
            label="Factory Title:"
            value={getContactContent('contact_factory_name', LANG.EN)?.value}
            name="contact_factory_en"
            onChange={handleChangeValue}
            variant="filled"
            helperText="Required*"
            sx={{ mr: 1 }}
          />
        </Grid>
        <Grid item xs={6}>
          <Editor
            editorState={valueFactoryLocation1_en}
            toolbarClassName="rdw-storybook-toolbar"
            wrapperClassName="rdw-storybook-wrapper"
            editorClassName="editor"
            onEditorStateChange={setValueFactoryLocation1_en}
            toolbar={customziedToolbar}
          />
        </Grid>
        <Grid item xs={6}>
          <Editor
            editorState={valueFactoryLocation2_en}
            toolbarClassName="rdw-storybook-toolbar"
            wrapperClassName="rdw-storybook-wrapper"
            editorClassName="editor"
            onEditorStateChange={setValueFactoryLocation2_en}
            toolbar={customziedToolbar}
          />
        </Grid>
      </Grid>
      <Typography variant="h6" gutterBottom mt={1}>
        Factory (zh):
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="filled-required"
            label="Factory Title:"
            // defaultValue="Contact"
            value={getContactContent('contact_factory_name', LANG.ZH)?.value}
            name="contact_factory_zh"
            onChange={handleChangeValue}
            variant="filled"
            helperText="Required*"
            sx={{ mr: 1 }}
          />
        </Grid>
        <Grid item xs={6}>
          <Editor
            editorState={valueFactoryLocation1_zh}
            toolbarClassName="rdw-storybook-toolbar"
            wrapperClassName="rdw-storybook-wrapper"
            editorClassName="editor"
            onEditorStateChange={setValueFactoryLocation1_zh}
            toolbar={customziedToolbar}
          />

        </Grid>
        <Grid item xs={6}>
          <Editor
            editorState={valueFactoryLocation2_zh}
            toolbarClassName="rdw-storybook-toolbar"
            wrapperClassName="rdw-storybook-wrapper"
            editorClassName="editor"
            onEditorStateChange={setValueFactoryLocation2_zh}
            toolbar={customziedToolbar}
          />
        </Grid>
      </Grid>
      <Button
        variant="contained"
        sx={{ marginTop: 2 }}
        onClick={() => {
          const en = draftToHtml(convertToRaw(valueEn.getCurrentContent()));
          const zh = draftToHtml(convertToRaw(valueZh.getCurrentContent()));

          const en_factory_location1_value = draftToHtml(convertToRaw(valueFactoryLocation1_en.getCurrentContent()));
          const zh_factory_location1_value = draftToHtml(convertToRaw(valueFactoryLocation1_zh.getCurrentContent()));

          const en_factory_location2_value = draftToHtml(convertToRaw(valueFactoryLocation2_en.getCurrentContent()));
          const zh_factory_location2_value = draftToHtml(convertToRaw(valueFactoryLocation2_zh.getCurrentContent()));

          const temp = {
            en_page_name: getContactContent('contact_page_name', LANG.EN).value,
            zh_page_name: getContactContent('contact_page_name', LANG.ZH).value,
            en_location_value: en,
            zh_location_value: zh,
            en_factory_name_value: getContactContent('contact_factory_name', LANG.EN).value,
            zh_factory_name_value: getContactContent('contact_factory_name', LANG.ZH).value,
            en_factory_location1_value,
            zh_factory_location1_value,
            en_factory_location2_value,
            zh_factory_location2_value,
            newImage,
          };
          props.updateOptionContact(temp);
        }}
      >
        Update
      </Button>
    </AdminPageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectOptionError(),
  data: makeSelectOptionAll(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    // getAllUser,
    updateOptionContact,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Contact.propTypes = {
  updateOptionContact: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    option: PropTypes.string,
    value: PropTypes.string,
  })),
};

export default compose(
  withConnect,
)(Contact);
