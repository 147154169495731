/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageWithFallback from 'components/ImageWithFallback/ImageWithFallback';
import _ from 'lodash';
import { restylePath } from 'utils/path.utils';
import { makeSelectRoutesNestedCategory } from 'services/routes/selectors';
import { useTranslation, withTranslation } from 'react-i18next';
import { returnCorrectLanguage, tIntermediateWithoutTranslate } from 'utils/language.utils';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';

function TitlebarBelowImageList(props) {
  const {
    language, navigate, selectedSubcategory, nestedCategory, productListBySubcategory,
  } = props;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log(productListBySubcategory);
  });

  return (
    <ImageList
      sx={{
        width: 'auto',
        paddingBottom: '5px',
      }}
      cols={5}
      gap={20}
    >
      {productListBySubcategory.map((item, index) => (
        <ImageListItem
          key={`${item.id}-${item.language}`}
          sx={{
            width: '212px',
            cursor: 'pointer',
            marginBottom: '40px',
          }}
          onClick={() => navigate(`${item.id}`)}
        >
          {item?.images.length > 0
            ? (
              <ImageWithFallback
                src={`${item?.images[0].url}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${item?.images[0].url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                alt=""
                style={{
                  borderRadius: '5px',
                  height: '237px',
                  objectFit: 'contain',
                }}
                loading="lazy"
              />
            )
            : (
              <Box
                width={212}
                height={217}
                border="1px solid #c2c2c2"
                marginTop="10px"
                marginBottom="10px"
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <CancelIcon fontSize="large" sx={{ color: '#c2c2c2' }} />
              </Box>
            )}
          <ImageListItemBar
            title={(
              <span style={{ paddingLeft: '7px' }}>
                {tIntermediateWithoutTranslate(i18n, item[language === 'cn' ? 'zh' : language].name)}
              </span>
          )}
            sx={{
              background: '#FFFFFF',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '5px',
            }}
            position="below"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}

TitlebarBelowImageList.propTypes = {
  language: PropTypes.string,
  navigate: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  nestedCategory: makeSelectRoutesNestedCategory(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

TitlebarBelowImageList.propTypes = {
  productListBySubcategory: PropTypes.arrayOf(PropTypes.shape()),
  selectedSubcategory: PropTypes.shape(),
  nestedCategory: PropTypes.string,
};

export default compose(
  withConnect,
)(TitlebarBelowImageList);
