/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  Outlet, useLocation, useParams, useNavigate,
} from 'react-router-dom';
import _ from 'lodash';
import { makeSelectRoutesNestedCategory } from 'services/routes/selectors';
import {
  makeSelectDashboardCategoryList, makeSelectDashboardSubcategoryList, makeSelectDashboardProductList, makeSelectDashboardProductLoading, makeSelectDashboardSubcategoryLoading, makeSelectDashboardCategoryLoading,
} from 'services/dashboard/selectors';
import { getProductList, getCategoryList, getSubcategoryList } from 'services/dashboard/actions';

function ProductsContainer(props) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    props.getProductList();
    props.getCategoryList();
    props.getSubcategoryList();
  }, [i18n.language]);

  return (
    <Outlet />
  );
}

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getProductList,
    getCategoryList,
    getSubcategoryList,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

ProductsContainer.propTypes = {
  getSubcategoryList: PropTypes.func.isRequired,
  getCategoryList: PropTypes.func.isRequired,
  getProductList: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(ProductsContainer);
