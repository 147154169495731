/* eslint-disable react/no-danger */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import BackgroundImage from 'assets/Background/Contact.png';
import Location from 'assets/Contact/1.png';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { isChinese, tIntermediate } from 'utils/language.utils';
import { getOptionContent } from 'utils/option.utils';
import { useTranslation, withTranslation } from 'react-i18next';
import PageHeaderButton from 'components/PageHeaderButton/PageHeaderButton';
import PageContainer from 'components/PageContainer/PageContainer';
import {
  makeSelectDashboardOptionList,
} from 'services/dashboard/selectors';
import _ from 'lodash';
import Skeleton from '@mui/material/Skeleton';

const ContactUsButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: '#FFFFFF',
  border: '1px solid #4CB3AA',
  borderRadius: '70px',
  width: '180px',
  height: '40px',
  textTransform: 'none',
  boxShadow: 'inset 0px 5px 5px #C4C4C4',
  fontSize: '20px',
  fontStyle: 'normal',
  padding: '0px 0px 0px 0px',
}));

function Contact(props) {
  const { optionList } = props;

  const { t, i18n } = useTranslation();

  const getAboutContent = (key, langId = (isChinese(i18n.language) ? 2 : 1)) => getOptionContent(optionList, key, langId);

  const sendEmail = () => {
    const link = 'mailto:enquiry@innomobel.com';

    window.location.href = link;
  };

  useEffect(() => {

  }, [i18n.language]);

  return (
    <PageContainer
      backgroundImage={BackgroundImage}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <PageHeaderButton text={tIntermediate(getAboutContent('contact_page_name')?.value) || t('contact.header')} />
          </Grid>
          <Grid item xs={12} md={12} mt={3} sx={{ width: '100%' }}>
            <Card sx={{
              background: 'rgba(255, 255, 255, 0.75)', display: 'flex', borderRadius: '25px', boxShadow: '0px 5px 5px rgba(208, 208, 210, 0.5)', backdropFilter: 'blur(4px)',
            }}
            >
              <CardContent sx={{ flex: 1, paddingLeft: '50px' }}>
                <div
                  style={{ lineHeight: 1.75, padding: 0 }}
                  dangerouslySetInnerHTML={{ __html: tIntermediate(getAboutContent('contact_location_content').value) }}
                />
                {/* <Typography component="h2" variant="h5">
                  {t('contact.company')}
                </Typography>
                <Typography variant="subtitle1" paragraph mt={2}>
                  {t('contact.address1')}
                  <br />
                  {t('contact.address2')}
                  <br />
                  {t('contact.tel')}
                  <br />
                  {t('contact.fax')}
                  <br />
                  {t('contact.workingHour')}
                  <br />
                </Typography> */}
                <div style={{
                  display: 'flex', justifyContent: 'center', paddingTop: '10px',
                }}
                >
                  <ContactUsButton onClick={sendEmail}>
                    {tIntermediate('contact.contactUs')}
                  </ContactUsButton>
                </div>
              </CardContent>
              { getAboutContent('contact_location_image', 1).value
                ? (
                  <CardMedia
                    component="img"
                    sx={{ width: 320, borderRadius: '25px', display: { xs: 'none', sm: 'block' } }}
                    image={getAboutContent('contact_location_image', 1).value}
                    alt="Company Location"
                  />
                ) : (<Skeleton variant="rectangular" width={320} height={350} sx={{ borderRadius: '25px' }} />)}
            </Card>
          </Grid>
          <Grid item xs={12} md={12} mt={3} sx={{ width: '100%' }}>
            <Card sx={{
              borderRadius: '25px', background: 'rgba(252, 252, 252, 0.7)', boxShadow: 'inset 0px 5px 5px rgba(208, 208, 210, 0.5)', backdropFilter: 'blur(4px)',
            }}
            >
              <CardContent sx={{ flex: 1, paddingLeft: '50px' }}>
                <Typography component="h2" variant="h5">
                  {tIntermediate(getAboutContent('contact_factory_name').value) || t('contact.factor.header')}
                </Typography>
                <Grid container mt={0}>
                  <Grid item md={6} xs={12}>
                    {/* <Typography variant="subtitle1" paragraph>
                      {t('contact.factor.company1.header')}
                      <br />
                      {t('contact.factor.company1.address1')}
                      <br />
                      {t('contact.factor.company1.address2')}
                      <br />
                      {t('contact.factor.company1.postalCode')}
                      <br />
                      {t('contact.factor.company1.tel')}
                      <br />
                      {t('contact.factor.company1.fax')}
                    </Typography> */}
                    <div
                      style={{ lineHeight: 1.75 }}
                      dangerouslySetInnerHTML={{ __html: tIntermediate(getAboutContent('contact_factory_location1').value) }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <div
                      style={{ lineHeight: 1.75 }}
                      dangerouslySetInnerHTML={{ __html: tIntermediate(getAboutContent('contact_factory_location2').value) }}
                    />
                    {/* <Typography variant="subtitle1" paragraph>
                      {t('contact.factor.company2.header')}
                      <br />
                      {t('contact.factor.company2.address1')}
                      <br />
                      {t('contact.factor.company2.address2')}
                      <br />
                      {t('contact.factor.company2.postalCode')}
                    </Typography> */}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  optionList: makeSelectDashboardOptionList(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Contact.propTypes = {
  optionList: PropTypes.any,
};

export default compose(
  withConnect,
)(Contact);
