import * as React from 'react';
import Container from '@mui/material/Container';
import BackgroundImage from 'assets/Background/About.png';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { useTranslation, withTranslation } from 'react-i18next';
import PageHeaderButton from 'components/PageHeaderButton/PageHeaderButton';
import AboutCardContent from 'components/AboutCardContent/AboutCardContent';
import { isChinese, tIntermediate } from 'utils/language.utils';
import AboutImage from 'assets/About/1.png';
import PageContainer from 'components/PageContainer/PageContainer';
import { tify, sify } from 'chinese-conv';

function About() {
  const { t, i18n } = useTranslation();

  return (
    <PageContainer
      backgroundImage={BackgroundImage}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <PageHeaderButton text={tIntermediate('about.header')} />
          </Grid>
          <Grid item xs={12} md={12} mt={3} sx={{ width: '100%' }}>
            <Card sx={{
              borderRadius: '25px', background: 'rgba(255, 255, 255, 0.75)', boxShadow: ' 0px 5px 5px rgba(208, 208, 210, 0.5)', backdropFilter: 'blur(4px)',
            }}
            >
              <CardContent sx={{
                flex: 1, paddingLeft: '50px', paddingRight: '50px', marginBottom: '25px',
              }}
              >
                <Grid container mt={2}>
                  <Grid item xs={12} mt={1}>
                    <AboutCardContent title={tIntermediate('about.subheader1.header')} context={tIntermediate('about.subheader1.context')} />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <AboutCardContent title={tIntermediate('about.subheader2.header')} context={tIntermediate('about.subheader2.context')} />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <AboutCardContent title={tIntermediate('about.subheader3.header')} context={tIntermediate('about.subheader3.context')} />
                  </Grid>
                  <Grid item xs={12} mt={1} sx={i18n.language === 'en' ? { display: 'none' } : { display: 'block' }}>
                    <AboutCardContent title={tIntermediate('about.subheader4.header')} context={tIntermediate('about.subheader4.context')} />
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      component="img"
                      sx={{
                        width: '100%',
                      }}
                      alt="about"
                      src={AboutImage}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
}

export default About;
