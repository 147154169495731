/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  makeSelectNewsError, makeSelectNewsLoading, makeSelectNewsAll, makeSelectNewsSnackbar,
} from 'services/news/selectors';
import {
  updateNews, addNews, removeNews, addNewsImage, removeNewsImage, changeNewsImageOrder,
  setNewsShowSnackbar,
} from 'services/news/actions';
import Box from '@mui/material/Box';
import {
  Stack, TextField, Typography, Input, Button, FormGroup, FormControlLabel, FormHelperText,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import AdminPageContainer from 'components/AdminPageContainer/AdminPageContainer';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SimpleSnackbar from 'components/SimpleSnackbar/SimpleSnackbar';
import AddIcon from '@mui/icons-material/Add';

function NewsHeader(props) {
  const {
    loading, list, snackbar,
  } = props;
  const { newsId } = useParams();
  const isNew = !newsId;
  const currentNews = _.find(list, { id: _.toNumber(newsId) });
  const [news, setNews] = useState({
    newUrlImages: [],
    newImages:
    isNew ? [] : (
      !_.isEmpty(currentNews.images) ? _.orderBy(currentNews.images, ['order'], ['asc']) : []
    ),
    en_id: isNew ? '' : currentNews.en.id,
    en_name: isNew ? '' : currentNews.en.name,
    en_desc: isNew ? '' : currentNews.en.description,
    zh_id: isNew ? '' : currentNews.zh.id,
    zh_name: isNew ? '' : currentNews.zh.name,
    zh_desc: isNew ? '' : currentNews.zh.description,
    date: isNew ? dayjs(Date.now()) : dayjs(currentNews.date),
  });

  console.log(news);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNews({ ...news, [name]: value });
  };

  const handleDayChange = (newValue) => {
    setNews({ ...news, date: dayjs(newValue) });
  };

  const handleNewImagesChange = (event) => {
    const { name, files } = event.target;
    const [file] = files;
    if (file) {
      if (isNew) {
        const clonedImages = _.cloneDeep(news.newImages);
        const urlFile = URL.createObjectURL(file);
        const clonedUrlImages = _.cloneDeep(news.newUrlImages);
        clonedUrlImages.push(urlFile);
        clonedImages.push(file);
        setNews({ ...news, newUrlImages: clonedUrlImages, newImages: clonedImages });
      } else {
        props.addNewsImage(
          newsId,
          {
            order: news.newImages.length,
            image: file,
          },
        );
      }
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  const handleSwitchChangeForNew = (event) => {
    const { value, name } = event.target;
    const clonedUrlImagess = _.cloneDeep(news.newUrlImages);
    const clonedImagess = _.cloneDeep(news.newImages);

    const tempUrl = clonedUrlImagess[value];
    clonedUrlImagess[value] = clonedUrlImagess[name];
    clonedUrlImagess[name] = tempUrl;

    const temp = clonedImagess[value];
    clonedImagess[value] = clonedImagess[name];
    clonedImagess[name] = temp;
    setNews({ ...news, newUrlImages: clonedUrlImagess, newImages: clonedImagess });
  };

  const handleSwitchChange = (event) => {
    const { value, name } = event.target;
    const fromId = name;
    const toId = value;
    props.changeNewsImageOrder({
      from_id: fromId,
      to_id: toId,
    });
  };

  useEffect(() => {
    console.log(news);
  }, []);

  return (
    <AdminPageContainer title={`News/${isNew ? 'Create' : newsId}`}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { width: '50ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          required
          id="filled-required"
          label="News Name(en_name):"
          value={news.en_name}
          name="en_name"
          variant="filled"
          helperText="Required*"
          sx={{ mr: 1 }}
          onChange={handleChange}
        />
        <TextField
          required
          id="filled-required"
          label="News Description(en_desc):"
          value={news.en_desc}
          name="en_desc"
          variant="filled"
          helperText="Required*"
          onChange={handleChange}
          multiline
          rows={5}
          sx={{
            width: '400px',
          }}
        />
        <TextField
          required
          id="filled-required"
          label="名稱 (zh_name):"
          value={news.zh_name}
          name="zh_name"
          variant="filled"
          helperText="Required*"
          sx={{ mr: 1 }}
          onChange={handleChange}
        />
        <TextField
          id="filled-required"
          label="內容 (zh_desc):"
          value={news.zh_desc}
          name="zh_desc"
          variant="filled"
          onChange={handleChange}
          multiline
          rows={5}
          fullWidth
          sx={{ marginBottom: 3 }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            label="News Date"
            inputFormat="MM/DD/YYYY"
            value={news.date}
            onChange={handleDayChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        {!isNew && (
          <div>
            <LoadingButton
              loading={loading}
              variant="contained"
              size="large"
              sx={{ marginTop: 2, marginBottom: 2 }}
              onClick={() => {
                props.updateNews({ id: newsId, news });
              }}
            >
              Update
            </LoadingButton>
            <LoadingButton
              loading={loading}
              variant="outlined"
              size="large"
              color="warning"
              sx={{ marginLeft: 2, marginTop: 2, marginBottom: 2 }}
              onClick={() => {
                props.removeNews({ id: newsId });
              }}
            >
              Delete
            </LoadingButton>
          </div>
        )}
        {/* <Typography variant="h6" component="h6" sx={{ mt: 1 }}>Image: </Typography> */}
        {isNew
          ? (
            <div>
              <Button
                size="small"
                variant="outlined"
                component="label"
              >
                Add Image
                <input
                  accept="image/*"
                  type="file"
                  name="newUrlImages"
                  hidden
                  onChange={handleNewImagesChange}
                />
              </Button>
              <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>Id</TableCell> */}
                      <TableCell align="right">Order</TableCell>
                      <TableCell align="right">Image</TableCell>
                      <TableCell align="right">Remove</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {news.newUrlImages.map((image, index) => (
                      <TableRow
                      // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <FormControl>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label="Order"
                              value={index}
                              name={_.toString(index)}
                              onChange={handleSwitchChangeForNew}
                            >
                              {news.newUrlImages.map((im, i) => (
                                <MenuItem key={`${index}${i}`} value={i}>
                                  {i + 1}
                                  {i === 0 ? '(封面)' : ''}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell align="right"><img width="250" src={image}></img></TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            onClick={() => {
                              const cloned = _.cloneDeep(news.newUrlImages);
                              const clonedImagess = _.cloneDeep(news.newImages);
                              clonedImagess.splice(index, 1);
                              setNews({ ...news, newUrlImages: _.remove(cloned, (i) => i !== image), newImages: clonedImagess });
                            }}
                          >
                            X
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow><AddIcon /></TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )
          : (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>Id</TableCell> */}
                    <TableCell align="right">Order</TableCell>
                    <TableCell align="right">Image</TableCell>
                    <TableCell align="right">Remove</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {news.newImages.map((image, index) => (
                    <TableRow
                      key={image.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Order"
                            value={image.id}
                            name={_.toString(image.id)}
                            onChange={handleSwitchChange}
                          >
                            {news.newImages.map((im, i) => (
                              <MenuItem key={`${index}${i}`} value={im.id}>
                                {im.order + 1}
                                {im.order === 0 ? '(封面)' : ''}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="right"><img width="200" src={image.url}></img></TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          onClick={() => {
                            props.removeNewsImage(image.id);
                          }}
                        >
                          X
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        {props.error && <ErrorComponent error={props.error} />}
        {isNew ? (
          <LoadingButton
            loading={loading}
            variant="contained"
            size="large"
            sx={{ marginTop: 2, marginBottom: 2 }}
            onClick={() => {
              console.log(news);
              props.addNews(news);
            }}
          >
            New
          </LoadingButton>
        ) : (
          <LoadingButton
            loading={loading}
            size="large"
            variant="outlined"
            component="label"
            sx={{
              marginTop: 2,
            }}
          >
            Add Image
            <input
              accept="image/*"
              type="file"
              name="newUrlImages"
              hidden
              onChange={handleNewImagesChange}
            />
          </LoadingButton>
        )}
      </Box>
      <SimpleSnackbar
        open={snackbar.isShow}
        handleClose={() => props.setNewsShowSnackbar(false)}
        title={snackbar.title}
      />
    </AdminPageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectNewsError(),
  loading: makeSelectNewsLoading(),
  list: makeSelectNewsAll(),
  snackbar: makeSelectNewsSnackbar(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addNews,
    updateNews,
    removeNews,
    addNewsImage,
    removeNewsImage,
    changeNewsImageOrder,
    setNewsShowSnackbar,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

NewsHeader.defaultProps = {
  currentNews: {
    images: [],
    en_id: '',
    en_name: '',
    en_description: '',
    zh_id: '',
    zh_name: '',
    zh_description: '',
    date: dayjs(Date.now()),
  },
};

NewsHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentNews: PropTypes.shape(),
  removeNews: PropTypes.func.isRequired,
  addNews: PropTypes.func.isRequired,
  updateNews: PropTypes.func.isRequired,
  addNewsImage: PropTypes.func.isRequired,
  removeNewsImage: PropTypes.func.isRequired,
  changeNewsImageOrder: PropTypes.func.isRequired,
  setNewsShowSnackbar: PropTypes.func.isRequired,
  error: PropTypes.any,
  list: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  snackbar: PropTypes.shape({
    title: PropTypes.string,
    isShow: PropTypes.bool,
  }),
};

export default compose(
  withConnect,
)(NewsHeader);
