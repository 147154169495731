import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { tify, sify } from 'chinese-conv';
import SwipeableCarouselWithoutFallback from '../SwipeableCarouselWithoutFallback/SwipeableCarouselWithoutFallback';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '300px', sm: '500px', md: '1000px' },
  background: '#FFFFFF',
  mixBlendMode: 'normal',
  borderColor: 'white',
  boxShadow: '0px 5px 5px rgba(208, 208, 210, 0.5)',
  borderRadius: '25px',
  paddingBottom: 1,
  paddingTop: 2,
  paddingLeft: 1,
};

function InformationModal(props) {
  const { t, i18n } = useTranslation();

  const { open, handleClose, data } = props;
  const theme = useTheme();
  const isMatchedXsScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep + 1 < data.images.length) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep - 1 >= 0) {
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <Modal
      disableEnforceFocus
      open={open}
      onClose={() => {
        setActiveStep(0);
        handleClose();
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Grid container sx={style}>
        <Grid item xs={12} md={7}>
          {data.images
           && (
           <SwipeableCarouselWithoutFallback
             images={data.images}
             activeStep={activeStep}
             setActiveStep={setActiveStep}
           />
           )}
        </Grid>
        { isMatchedXsScreen
        && (
        <Grid item sm={12}>
          <Stack
            spacing={3}
            direction="row"
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={handleBack}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton onClick={handleNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Stack>
        </Grid>
        )}
        <Grid
          item
          xs={12}
          md={5}
          mt={2}
          sx={{
            paddingTop: '0 !important',
            paddingLeft: 2,
            paddingRight: 5,
            height: { xs: 300, md: 'inherit' },
            overflowY: 'scroll',
          }}
        >
          <Typography
            variant="h6"
            component="h6"
            sx={{ color: '#C4C4C4', fontSize: { xs: 15, sm: 20 } }}
          >
            {data.date}
          </Typography>
          <Typography
            id="modal-title"
            mt={3}
            variant="h6"
            sx={{
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: { xs: 15, sm: 20 },
              textTransform: 'uppercase',
              color: '#00A39C',
              paddingBottom: 5,
            }}
          >
            {i18n.language === 'cn' ? sify(data.title || '') : data.title}
          </Typography>
          <Typography
            id="modal-description"
            variant="body1"
            paragraph
            sx={{
              fontSize: { xs: 12, sm: 17.5 },
            }}
          >
            {i18n.language === 'cn' ? sify(data.description || '') : data.description}
          </Typography>
        </Grid>
        { !isMatchedXsScreen
        && (
        <Grid item xs={12} md={7}>
          <Stack
            spacing={3}
            direction="row"
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={handleBack}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton onClick={handleNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Stack>
        </Grid>
        )}
      </Grid>
    </Modal>
  );
}
InformationModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  data: PropTypes.shape({
    date: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
  }),
};
export default InformationModal;
