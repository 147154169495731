import * as React from 'react';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import BackgroundImage from 'assets/Background/Contact.png';
import Location from 'assets/Contact/1.png';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useTranslation, withTranslation } from 'react-i18next';
import PageHeaderButton from 'components/PageHeaderButton/PageHeaderButton';
import PageContainer from 'components/PageContainer/PageContainer';
import { tIntermediate } from 'utils/language.utils';

const ContactUsButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: '#FFFFFF',
  border: '1px solid #4CB3AA',
  borderRadius: '70px',
  width: '180px',
  height: '40px',
  textTransform: 'none',
  boxShadow: 'inset 0px 5px 5px #C4C4C4',
  fontSize: '20px',
  fontStyle: 'normal',
  padding: '0px 0px 0px 0px',
}));

function Contact() {
  const { t, i18n } = useTranslation();

  const sendEmail = () => {
    const link = 'mailto:enquiry@innomobel.com';

    window.location.href = link;
  };

  return (
    <PageContainer
      backgroundImage={BackgroundImage}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <PageHeaderButton text={tIntermediate('contact.header')} />
          </Grid>
          <Grid item xs={12} md={12} mt={3} sx={{ width: '100%' }}>
            <Card sx={{
              background: 'rgba(255, 255, 255, 0.75)', display: 'flex', borderRadius: '25px', boxShadow: '0px 5px 5px rgba(208, 208, 210, 0.5)', backdropFilter: 'blur(4px)',
            }}
            >
              <CardContent sx={{ flex: 1, paddingLeft: '50px' }}>
                <Typography component="h2" variant="h5">
                  {tIntermediate('contact.company')}
                </Typography>
                <Typography variant="subtitle1" paragraph mt={2}>
                  {tIntermediate('contact.address1')}
                  <br />
                  {tIntermediate('contact.address2')}
                  <br />
                  {tIntermediate('contact.tel')}
                  <br />
                  {tIntermediate('contact.fax')}
                  <br />
                  {tIntermediate('contact.workingHour')}
                  <br />
                </Typography>
                <div style={{
                  display: 'flex', justifyContent: 'center', paddingTop: '10px',
                }}
                >
                  <ContactUsButton onClick={sendEmail}>
                    {tIntermediate('contact.contactUs')}
                  </ContactUsButton>
                </div>
              </CardContent>
              <CardMedia
                component="img"
                sx={{ width: 320, borderRadius: '25px', display: { xs: 'none', sm: 'block' } }}
                image={Location}
                alt="Company Location"
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={12} mt={3} sx={{ width: '100%' }}>
            <Card sx={{
              borderRadius: '25px', background: 'rgba(252, 252, 252, 0.7)', boxShadow: 'inset 0px 5px 5px rgba(208, 208, 210, 0.5)', backdropFilter: 'blur(4px)',
            }}
            >
              <CardContent sx={{ flex: 1, paddingLeft: '50px' }}>
                <Typography component="h2" variant="h5">
                  {tIntermediate('contact.factor.header')}
                </Typography>
                <Grid container mt={2}>
                  <Grid item md={6} xs={12}>
                    <Typography variant="subtitle1" paragraph>
                      {tIntermediate('contact.factor.company1.header')}
                      <br />
                      {tIntermediate('contact.factor.company1.address1')}
                      <br />
                      {tIntermediate('contact.factor.company1.address2')}
                      <br />
                      {tIntermediate('contact.factor.company1.postalCode')}
                      <br />
                      {tIntermediate('contact.factor.company1.tel')}
                      <br />
                      {tIntermediate('contact.factor.company1.fax')}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Typography variant="subtitle1" paragraph>
                      {tIntermediate('contact.factor.company2.header')}
                      <br />
                      {tIntermediate('contact.factor.company2.address1')}
                      <br />
                      {tIntermediate('contact.factor.company2.address2')}
                      <br />
                      {tIntermediate('contact.factor.company2.postalCode')}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </PageContainer>
  );
}

export default Contact;
