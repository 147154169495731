import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import { updateCategory, addCategory } from 'services/category/actions';
import { makeSelectSubcategoryError, makeSelectSubcategoryLoading } from 'services/subcategory/selectors';
import { updateSubcategory, addSubcategory, removeSubcategory } from 'services/subcategory/actions';
import {
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import Stack from '@mui/material/Stack';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import { LoadingButton } from '@mui/lab';
import { isMiddleCategory } from 'utils/category.utils';

function SubcategoryHeader(props) {
  const { categoryId, subcategoryId } = useParams();
  const {
    currentSubcategory, loading, error, type, isNew,
  } = props;
  // const isMiddleCategory = location.state?.isMiddleCategory || false;
  const [subcategory, setSubcategory] = useState({
    subcategoryId: currentSubcategory.subcategoryId || '', // for create from middle subcategory
    oldImage: currentSubcategory.image || null,
    en: currentSubcategory.en || '',
    en_id: currentSubcategory.en_id || '',
    zh: currentSubcategory.zh || '',
    zh_id: currentSubcategory.zh_id || '',
    // type: currentSubcategory.type || type,
  });

  const isMiddle = isMiddleCategory(type);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSubcategory({ ...subcategory, [name]: value });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const [file] = files;
    if (file) {
      setSubcategory({ ...subcategory, oldImage: URL.createObjectURL(file), [name]: file });
    }
  };

  useEffect(() => {
    setSubcategory({
      subcategoryId: currentSubcategory.subcategoryId || '', // for create from middle subcategory
      oldImage: currentSubcategory.image || null,
      en: currentSubcategory.en || '',
      en_id: currentSubcategory.en_id || '',
      zh: currentSubcategory.zh || '',
      zh_id: currentSubcategory.zh_id || '',
      type: currentSubcategory.type || type,
    });
    console.log(`type: ${type}`);
  }, [currentSubcategory]);

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { width: '50ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        required
        id="filled-required"
        label="Subcategory Name(en):"
        value={subcategory.en}
        name="en"
        variant="filled"
        helperText="Required*"
        sx={{ mr: 1 }}
        onChange={handleChange}
      />
      <TextField
        required
        id="filled-required"
        label="Subcategory Name(zh):"
        value={subcategory.zh}
        name="zh"
        variant="filled"
        helperText="Required*"
        onChange={handleChange}
      />
      {isMiddleCategory(type)
        && (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h6" component="h6">Image: </Typography>
            {subcategory.oldImage && (
            <img
              width="250"
              src={subcategory.oldImage}
            />
            )}
            <Button
              size="small"
              variant="outlined"
              component="label"
            >
              Upload Image
              <input
                accept="image/*"
                type="file"
                name="image"
                hidden
                onChange={handleFileChange}
              />
            </Button>
          </Stack>
        )}
      {error && <ErrorComponent error={error} />}
      <Stack></Stack>
      <LoadingButton
        loading={loading}
        variant="contained"
        size="large"
        sx={{ marginTop: 2, marginBottom: 2 }}
        onClick={() => {
          console.log(isNew, isMiddle);
          if (isNew) {
            if (isMiddle) {
              console.log(subcategory);
              props.addCategory({
                name: subcategory.zh,
                zh: subcategory.zh,
                en: subcategory.en,
                parent_id: categoryId,
                oldImage: subcategory.oldImage,
                isActive: true,
                image: subcategory.image,
              });
            } else {
              console.log('Here');
              props.addSubcategory(categoryId, subcategory);
            }
          } else if (isMiddle) {
            props.updateCategory();
          } else {
            props.updateSubcategory(currentSubcategory.id, subcategory);
          }
        }}
      >
        {isNew ? 'New' : 'Update'}
      </LoadingButton>
      {!isNew
      && (
      <LoadingButton
        loading={loading}
        variant="outlined"
        size="large"
        color="warning"
        sx={{ marginLeft: 2, marginTop: 2, marginBottom: 2 }}
        onClick={() => {
          props.removeSubcategory(currentSubcategory.id);
        }}
      >
        Delete
      </LoadingButton>
      )}
    </Box>
  );
}

const mapStateToProps = createStructuredSelector({
  error: makeSelectSubcategoryError(),
  loading: makeSelectSubcategoryLoading(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateCategory,
    addCategory,
    updateSubcategory,
    addSubcategory,
    removeSubcategory,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

SubcategoryHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.any,
  isNew: PropTypes.bool.isRequired,
  // isMiddleCategory: PropTypes.bool.isRequired,
  type: PropTypes.number.isRequired,
  updateSubcategory: PropTypes.func.isRequired,
  addSubcategory: PropTypes.func.isRequired,
  removeSubcategory: PropTypes.func.isRequired,
  updateCategory: PropTypes.func.isRequired,
  addCategory: PropTypes.func.isRequired,
  currentSubcategory: PropTypes.shape(),
};

export default compose(
  withConnect,
)(SubcategoryHeader);
