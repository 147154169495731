import {
  PROJECTS_ADD,
  PROJECTS_ADD_SUCCESS,
  PROJECTS_ADD_FAIL,
  PROJECTS_GET_ONE,
  PROJECTS_GET_ONE_SUCCESS,
  PROJECTS_GET_ONE_FAIL,
  PROJECTS_GET_ALL,
  PROJECTS_GET_ALL_SUCCESS,
  PROJECTS_GET_ALL_FAIL,
  PROJECTS_UPDATE,
  PROJECTS_UPDATE_SUCCESS,
  PROJECTS_UPDATE_FAIL,
  PROJECTS_REMOVE,
  PROJECTS_REMOVE_SUCCESS,
  PROJECTS_REMOVE_FAIL,
  PROJECTS_ADD_IMAGE,
  PROJECTS_ADD_IMAGE_SUCCESS,
  PROJECTS_ADD_IMAGE_FAIL,
  PROJECTS_REMOVE_IMAGE,
  PROJECTS_REMOVE_IMAGE_SUCCESS,
  PROJECTS_REMOVE_IMAGE_FAIL,
  PROJECTS_CHANGE_ORDER_IMAGE,
  PROJECTS_CHANGE_ORDER_IMAGE_SUCCESS,
  PROJECTS_CHANGE_ORDER_IMAGE_FAIL,
  PROJECTS_SET_SHOW_SNACKBAR,
} from './constants';

export const addProjects = (data) => ({
  type: PROJECTS_ADD,
  data,
});

export const addProjectsSuccess = (data) => ({
  type: PROJECTS_ADD_SUCCESS,
  data,
});

export const addProjectsFail = (error) => ({
  type: PROJECTS_ADD_FAIL,
  error,
});

export const updateProjects = (data) => ({
  type: PROJECTS_UPDATE,
  data,
});

export const updateProjectsSuccess = (data) => ({
  type: PROJECTS_UPDATE_SUCCESS,
  data,
});

export const updateProjectsFail = (error) => ({
  type: PROJECTS_UPDATE_FAIL,
  error,
});

export const removeProjects = (data) => ({
  type: PROJECTS_REMOVE,
  data,
});

export const removeProjectsSuccess = (data) => ({
  type: PROJECTS_REMOVE_SUCCESS,
  data,
});

export const removeProjectsFail = (error) => ({
  type: PROJECTS_REMOVE_FAIL,
  error,
});

export const getAll = () => ({
  type: PROJECTS_GET_ALL,
});

export const getAllSuccess = (data) => ({
  type: PROJECTS_GET_ALL_SUCCESS,
  data,
});

export const getAllFail = (error) => ({
  type: PROJECTS_GET_ALL_FAIL,
  error,
});

export const getOne = () => ({
  type: PROJECTS_GET_ONE,
});

export const getOneSuccess = (data) => ({
  type: PROJECTS_GET_ONE_SUCCESS,
  data,
});

export const getOneFail = (error) => ({
  type: PROJECTS_GET_ONE_FAIL,
  error,
});

export const addProjectsImage = (projectId, project) => ({
  type: PROJECTS_ADD_IMAGE,
  projectId,
  project,
});

export const addProjectsImageSuccess = (data) => ({
  type: PROJECTS_ADD_IMAGE_SUCCESS,
  data,
});

export const addProjectsImageFail = (error) => ({
  type: PROJECTS_ADD_IMAGE_FAIL,
  error,
});

export const removeProjectsImage = (id) => ({
  type: PROJECTS_REMOVE_IMAGE,
  id,
});

export const removeProjectsImageSuccess = (data) => ({
  type: PROJECTS_REMOVE_IMAGE_SUCCESS,
  data,
});

export const removeProjectsImageFail = (error) => ({
  type: PROJECTS_REMOVE_IMAGE_FAIL,
  error,
});

export const changeProjectsImageOrder = (project) => ({
  type: PROJECTS_CHANGE_ORDER_IMAGE,
  project,
});

export const changeProjectsImageOrderSuccess = (data) => ({
  type: PROJECTS_CHANGE_ORDER_IMAGE_SUCCESS,
  data,
});

export const changeProjectsImageOrderFail = (error) => ({
  type: PROJECTS_CHANGE_ORDER_IMAGE_FAIL,
  error,
});

export const setProjectsShowSnackbar = (bool) => ({
  type: PROJECTS_SET_SHOW_SNACKBAR,
  bool,
});
