import {
  SUBCATEGORY_GET_ALL,
  SUBCATEGORY_GET_ALL_SUCCESS,
  SUBCATEGORY_GET_ALL_FAIL,
  SUBCATEGORY_GET_ONE_WITH_I18N,
  SUBCATEGORY_GET_ONE_WITH_I18N_SUCCESS,
  SUBCATEGORY_GET_ONE_WITH_I18N_FAIL,
  SUBCATEGORY_GET_ONE_WITH_I18N_EMPTY,
  SUBCATEGORY_UPDATE,
  SUBCATEGORY_UPDATE_SUCCESS,
  SUBCATEGORY_UPDATE_FAIL,
  SUBCATEGORY_ADD,
  SUBCATEGORY_ADD_SUCCESS,
  SUBCATEGORY_ADD_FAIL,
  SUBCATEGORY_REMOVE,
  SUBCATEGORY_REMOVE_SUCCESS,
  SUBCATEGORY_REMOVE_FAIL,
  SUBCATEGORY_SET_CATEGORY_ID,
} from './constants';

export const getAllSubcategory = (id) => ({
  type: SUBCATEGORY_GET_ALL,
  id,
});

export const getAllSubcategorySuccess = (data) => ({
  type: SUBCATEGORY_GET_ALL_SUCCESS,
  data,
});

export const getAllSubcategoryFail = (error) => ({
  type: SUBCATEGORY_GET_ALL_FAIL,
  error,
});

export const getOneSubcategoryWithi18n = (id) => ({
  type: SUBCATEGORY_GET_ONE_WITH_I18N,
  id,
});

export const getOneSubcategoryWithi18nSuccess = (data) => ({
  type: SUBCATEGORY_GET_ONE_WITH_I18N_SUCCESS,
  data,
});

export const emptyOneSubcategoryWithi18n = () => ({
  type: SUBCATEGORY_GET_ONE_WITH_I18N_EMPTY,
});

export const getOneSubcategoryWithi18nFail = (error) => ({
  type: SUBCATEGORY_GET_ONE_WITH_I18N_FAIL,
  error,
});

export const updateSubcategory = (id, subcategory) => ({
  type: SUBCATEGORY_UPDATE,
  id,
  subcategory,
});

export const updateSubcategorySuccess = (data) => ({
  type: SUBCATEGORY_UPDATE_SUCCESS,
  data,
});

export const updateSubcategoryFail = (error) => ({
  type: SUBCATEGORY_UPDATE_FAIL,
  error,
});

export const addSubcategory = (categoryId, subcategory) => ({
  type: SUBCATEGORY_ADD,
  categoryId,
  subcategory,
});

export const addSubcategorySuccess = (data) => ({
  type: SUBCATEGORY_ADD_SUCCESS,
  data,
});

export const addSubcategoryFail = (error) => ({
  type: SUBCATEGORY_ADD_FAIL,
  error,
});

export const removeSubcategory = (id) => ({
  type: SUBCATEGORY_REMOVE,
  id,
});

export const removeSubcategorySuccess = (data) => ({
  type: SUBCATEGORY_REMOVE_SUCCESS,
  data,
});

export const removeSubcategoryFail = (error) => ({
  type: SUBCATEGORY_REMOVE_FAIL,
  error,
});

export const setCategoryId = (id) => ({
  type: SUBCATEGORY_SET_CATEGORY_ID,
  id,
});
