import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation, withTranslation } from 'react-i18next';
import SubCategroyHeaderButton from 'components/SubCategroyHeaderButton/SubCategroyHeaderButton';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
// import ProductCategoryIcon from './components/ProductCategoryIcon';
import Pagination from '@mui/material/Pagination';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { restylePath } from 'utils/path.utils';
import { makeSelectRoutesNestedCategory } from 'services/routes/selectors';
import { tIntermediate } from 'utils/language.utils';
import { tify, sify } from 'chinese-conv';
import SubCategoryMenuButton from '../components/SubCategoryMenuButton/SubCategoryMenuButton';
import SubCategoryMenu from '../components/SubCategoryMenu/SubCategoryMenu';
import TitlebarBelowImageList from '../components/TitlebarBelowImageList/TitlebarBelowImageList';
import { PRODUCT_LIST } from '../product.constants';
import { CATEGORY_LIST, PRODUCT_SUBCATEGORY } from '../constants';

function Category(props) {
  const { nestedCategory } = props;
  const { t, i18n } = useTranslation();
  const { category } = useParams();
  const navigate = useNavigate();
  const [activeProductList, setActiveProductList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [page, setPage] = useState(1);
  const numberPerPage = 12;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const initActiveProductList = () => {
    const restyledCategory = category.replaceAll('-', ' ').toUpperCase();
    const temp = PRODUCT_LIST.filter((x) => x.category.toUpperCase() === restyledCategory || x.category === CATEGORY_LIST[restyledCategory].zh);
    if (category.toUpperCase() === 'DESKING') {
      if (nestedCategory) {
        const tr = PRODUCT_SUBCATEGORY.filter((x) => x.title.toUpperCase() === nestedCategory.toUpperCase());
        setActiveProductList(temp.filter((x) => x.nestedcategory === tr[0].title.toUpperCase() || x.nestedcategory === tr[0].title_zh));
      } else {
        navigate(-1);
      }
    } else {
      setActiveProductList(temp);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const navigateToCategory = () => {
    navigate(-1);
  };

  const getMenu = () => {
    const lang = i18n.language === 'en' ? 'en' : 'zh';
    const uniqueSubcategoryList = _.uniq(_.map(activeProductList.filter((x) => x.language === lang), 'subcategory'));
    // eslint-disable-next-line no-nested-ternary
    uniqueSubcategoryList.unshift(i18n.language === 'en' ? 'All Subcategories' : i18n.language === 'zh' ? '所有產品' : '所有产品'); // TODO: add chinese 所有產品
    return uniqueSubcategoryList.map((x) => (i18n.language === 'cn' ? sify(x) : x));
  };

  const getEnglishMenu = () => {
    const lang = i18n.language === 'en' ? 'en' : 'zh';
    const uniqueSubcategoryList = _.uniq(_.map(activeProductList.filter((x) => x.language === 'en'), 'subcategory'));
    uniqueSubcategoryList.unshift('All Subcategories');
    return uniqueSubcategoryList;
  };

  const categorizedActiveProductList = (language = null) => {
    if (selectedIndex === 0) {
      return activeProductList;
    }
    if (language === 'en') {
      return activeProductList.filter((x) => x.subcategory === getEnglishMenu()[selectedIndex]);
    }
    const ignoreList = ['茶几'];
    if (ignoreList.includes(getMenu()[selectedIndex])) {
      return activeProductList.filter((x) => x.subcategory === getMenu()[selectedIndex]);
    }
    const text = i18n.language === 'cn' ? tify(getMenu()[selectedIndex]) : getMenu()[selectedIndex];
    console.log(getMenu()[selectedIndex]);
    console.log(text);
    return activeProductList.filter((x) => x.subcategory === text);
  };

  useEffect(() => {
    const currentLanguage = i18n.language;
    initActiveProductList();
    // console.log(`${currentLanguage}: ${category}`);
  }, [i18n.language]);

  return (
    <Container
      maxWidth="false"
      disableGutters
      sx={{
        width: '100vw',
        paddingBottom: '50px',
      }}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <SubCategroyHeaderButton
              text={i18n.language === 'cn' ? sify(CATEGORY_LIST[restylePath(category).replaceAll('-', ' ').toUpperCase()][i18n.language === 'cn' ? 'zh' : i18n.language]) : CATEGORY_LIST[restylePath(category).replaceAll('-', ' ').toUpperCase()][i18n.language === 'cn' ? 'zh' : i18n.language]}
              icon={`/assets/Icon/${restylePath(category).replaceAll('-', '')}_c.svg`}
              onClickEvent={navigateToCategory}
            />
          </Grid>
          <Grid item xs={12} md={12} mt={5} sx={{ width: '100%' }}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12} mt={5} sx={{ width: '100%' }}>
            <Stack spacing={3}>
              <SubCategoryMenuButton
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                disableElevation
                onClick={handleClick}
                text={getMenu()[selectedIndex]}
              />
              <SubCategoryMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                items={getMenu()}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                setPage={setPage}
              />
              <Typography component="h3" variant="h5">
                {getMenu()[selectedIndex]}
              </Typography>
              <Divider
                sx={{
                  border: '1px solid #4CB3AA',
                  width: '100px',
                  marginTop: '5px !important',
                }}
              />
              <TitlebarBelowImageList
                activeProductList_en={
                  _.slice(categorizedActiveProductList('en').filter((x) => x.language === 'en'), (page - 1) * numberPerPage, page * numberPerPage)
                }
                activeProductList_zh={
                  _.slice(categorizedActiveProductList().filter((x) => x.language === 'zh'), (page - 1) * numberPerPage, page * numberPerPage)
                }
                language={i18n.language}
                navigate={navigate}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} mt={5} mb={3} sx={{ width: '100%' }}>
            <Divider />
          </Grid>
          <Pagination
            shape="rounded"
            count={Math.ceil(categorizedActiveProductList().length / 2 / numberPerPage)}
            onChange={handleChangePage}
            hidePrevButton
          />
        </Grid>
      </Container>
    </Container>
  );
}

const mapStateToProps = createStructuredSelector({
  nestedCategory: makeSelectRoutesNestedCategory(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

Category.propTypes = {
  nestedCategory: PropTypes.string,
};

export default compose(
  withConnect,
)(Category);
