import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

const AdminPageContainer = styled(({
  children, title, isEnableTitleCallback = true, ...other
}) => {
  const navigate = useNavigate();

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Paper variant="outlined">
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <span
            tabIndex={0}
            onKeyPress={() => {}}
            role="button"
            style={{
              cursor: isEnableTitleCallback ? 'pointer' : null,
            }}
            onClick={() => {
              if (isEnableTitleCallback) navigate(-1);
            }}
          >
            <Typography variant="h5" component="h5" mb={2}>
              {isEnableTitleCallback && <ArrowBackIosIcon fontSize="small" />}
              {title}
            </Typography>
          </span>
          {children}
        </Container>
      </Paper>
    </Box>
  );
})(({ theme }) => ({
  width: '100vw',
  backgroundSize: 'cover',
  paddingBottom: '150px',
}));

export default AdminPageContainer;
