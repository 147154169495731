export const SUBCATEGORY_GET_ALL = 'SUBCATEGORY_GET_ALL';
export const SUBCATEGORY_GET_ALL_SUCCESS = 'SUBCATEGORY_GET_ALL_SUCCESS';
export const SUBCATEGORY_GET_ALL_FAIL = 'SUBCATEGORY_GET_ALL_FAIL';
export const SUBCATEGORY_GET_ONE_WITH_I18N = 'SUBCATEGORY_GET_ONE_WITH_I18N';
export const SUBCATEGORY_GET_ONE_WITH_I18N_SUCCESS = 'SUBCATEGORY_GET_ONE_WITH_I18N_SUCCESS';
export const SUBCATEGORY_GET_ONE_WITH_I18N_FAIL = 'SUBCATEGORY_GET_ONE_WITH_I18N_FAIL';
export const SUBCATEGORY_GET_ONE_WITH_I18N_EMPTY = 'SUBCATEGORY_GET_ONE_WITH_I18N_EMPTY';
export const SUBCATEGORY_UPDATE = 'SUBCATEGORY_UPDATE';
export const SUBCATEGORY_UPDATE_SUCCESS = 'SUBCATEGORY_UPDATE_SUCCESS';
export const SUBCATEGORY_UPDATE_FAIL = 'SUBCATEGORY_UPDATE_FAIL';
export const SUBCATEGORY_ADD = 'SUBCATEGORY_ADD';
export const SUBCATEGORY_ADD_SUCCESS = 'SUBCATEGORY_ADD_SUCCESS';
export const SUBCATEGORY_ADD_FAIL = 'SUBCATEGORY_ADD_FAIL';
export const SUBCATEGORY_REMOVE = 'SUBCATEGORY_REMOVE';
export const SUBCATEGORY_REMOVE_SUCCESS = 'SUBCATEGORY_REMOVE_SUCCESS';
export const SUBCATEGORY_REMOVE_FAIL = 'SUBCATEGORY_REMOVE_FAIL';
export const SUBCATEGORY_SET_CATEGORY_ID = 'SUBCATEGORY_SET_CATEGORY_ID';
