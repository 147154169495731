import { combineReducers } from 'redux';
import routesReducer from 'services/routes/reducer';
import userReducer from 'services/user/reducer';
import categoryReducer from './services/category/reducer';
import subcategoryReducer from './services/subcategory/reducer';
import productsReducer from './services/products/reducer';
import dashboardReducer from './services/dashboard/reducer';
import optionReducer from './services/option/reducer';
import newsReducer from './services/news/reducer';
import projectsReducer from './services/projects/reducer';

export default combineReducers({
  user: userReducer,
  products: productsReducer,
  category: categoryReducer,
  subcategory: subcategoryReducer,
  routes: routesReducer,
  dashboard: dashboardReducer,
  option: optionReducer,
  news: newsReducer,
  projects: projectsReducer,
});
