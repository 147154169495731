import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import StarIcon from 'assets/Icon/CatagoryIcon.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SubCategoryMenuButton = styled(({ text, ...other }) => (
  <Button
    {...other}
    endIcon={<KeyboardArrowDownIcon />}
  >
    {text}
  </Button>
))(({ theme }) => ({
  width: '190px',
  fontSize: '15px',
  border: '1px solid #C4C4C4',
  background: '#FAFAFA',
  borderRadius: '5px',
  color: '#404040',
  textTransform: 'none',
  fontWeight: 300,
  fontStyle: 'normal',
  padding: '0px 0px 0px 0px',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #C4C4C4',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
  },
}));

export default SubCategoryMenuButton;
