import React, { useState } from 'react';
import Container from '@mui/material/Container';
import BackgroundImage from 'assets/Background/Projects.png';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import PageHeaderButton from 'components/PageHeaderButton/PageHeaderButton';
import { isChinese, tIntermediate } from 'utils/language.utils';
import PageContainer from 'components/PageContainer/PageContainer';
import InformationCardContent from 'components/InformationCardContent/InformationCardContent';
import InformationModal from 'components/InformationModal/InformationModal';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { tify, sify } from 'chinese-conv';
import { PROJECTS_LIST } from './constants';

function Projects() {
  const { t, i18n } = useTranslation();
  const AddMore = 10;

  const [open, setOpen] = useState(false);
  const [activeProjects, setActiveProjects] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [count, setCount] = useState(AddMore);

  const imagePrefix = (project) => `/assets/Projects/${project.title.replaceAll('\'', '_').replaceAll('&', '_')}_${project.date}/`;

  const getActiveProjectsList = (projectCount) => {
    const lang = i18n.language === 'en' ? 'en' : 'zh';
    const list = PROJECTS_LIST.filter((x) => x.language === lang && !_.isEmpty(x.title));
    if (projectCount) {
      return _.take(list, projectCount);
    }
    return list;
  };

  const getEnglishProjectList = () => PROJECTS_LIST.filter((x) => x.language === 'en');

  const handleOpen = (id) => {
    const temp = _.cloneDeep(_.find(getActiveProjectsList(), { id }));
    const tempEnglish = _.find(getEnglishProjectList(), { id: temp.id });
    temp.images = tempEnglish.images.map((x) => `${imagePrefix(tempEnglish)}${x}`);
    setActiveProjects(temp);
    setOpen(true);
  };

  const getImages = (project) => {
    if (isChinese(i18n.language)) {
      const { id } = project;
      const temp = _.find(getEnglishProjectList(), { id });
      return temp.images;
    }
    return project.images;
  };

  const handleClose = () => setOpen(false);

  const fetchMoreData = () => {
    if (count + AddMore >= getActiveProjectsList().length) {
      setHasMore(false);
    }

    setTimeout(() => {
      setCount(count + AddMore);
    }, 200);
  };

  return (
    <PageContainer
      backgroundImage={BackgroundImage}
    >
      <Container maxWidth="lg" sx={{ paddingTop: 10 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} md={12}>
            <PageHeaderButton text={tIntermediate('projects.header')} />
          </Grid>
          <Grid item xs={12} md={12} mt={2} sx={{ width: { xs: '100%' } }}>
            <InfiniteScroll
              dataLength={getActiveProjectsList(count).length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<h3>Loading...</h3>}
            >
              {getActiveProjectsList(count).map((project) => (
                <InformationCardContent
                  key={project.id}
                  image={`${imagePrefix(_.find(getEnglishProjectList(), { id: project.id }))}${getImages(project)[0]}` || ''}
                  date={project.date}
                  title={i18n.language === 'zh' ? project.title : sify(project.title)}
                  description={i18n.language === 'zh' ? project.description : sify(project.description || '')}
                  callback={() => handleOpen(project.id)}
                />
              ))}
            </InfiniteScroll>
          </Grid>
        </Grid>
      </Container>
      <InformationModal open={open} handleClose={handleClose} data={activeProjects} />
    </PageContainer>
  );
}

export default Projects;
