import React, { useState, useEffect } from 'react';
import { compose, bindActionCreators } from 'redux';
import { PropTypes } from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { makeSelectUserList } from 'services/user/selectors';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { toDate } from 'utils/date.utils';
import { getUserGroupById } from 'utils/user.utils';
import AdminPageContainer from 'components/AdminPageContainer/AdminPageContainer';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import UserHeader from './UserHeader';

function User(props) {
  const navigate = useNavigate();

  const columns = [
    {
      field: 'user',
      headerName: 'User',
      width: 150,
      editable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: false,
    },
    {
      field: 'group',
      headerName: 'User Group',
      width: 150,
      editable: false,
      valueFormatter: (params) => getUserGroupById(params.value).name,
    },
    {
      field: 'last_login_time',
      headerName: 'Last Login Time',
      width: 250,
      editable: false,
      valueFormatter: (params) => toDate(params.value),
    },
    {
      field: 'actions',
      headerName: 'Edit',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon sx={{ fontSize: 28 }} />}
          label="Edit"
          onClick={() => {
            navigate(`edit/${params.id}`, { state: { newUser: false } });
          }}
        />,
      ],
    },
  ];
  const rows = props.userList;

  return (
    <AdminPageContainer title="User" isEnableTitleCallback={false}>
      <Button
        variant="contained"
        sx={{ marginBottom: 2 }}
        onClick={() => {
          navigate('new', { state: { newUser: true } });
        }}
      >
        New
      </Button>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[15]}
        disableSelectionOnClick
      />
    </AdminPageContainer>
  );
}

const mapStateToProps = createStructuredSelector({
  // error: makeSelectUserError(),
  // loading: makeSelectUserLoading(),
  userList: makeSelectUserList(),
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    // getAllUser,
  }, dispatch);
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

User.propTypes = {
  // loading: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // error: PropTypes.any,
  userList: PropTypes.arrayOf(PropTypes.shape()),
  // getAllUser: PropTypes.func.isRequired,
};

export default compose(
  withConnect,
)(User);
