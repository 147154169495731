import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import { styled, createTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@mui/system';
import { useTranslation, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { tify, sify } from 'chinese-conv';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { tIntermediate } from 'utils/language.utils';
import { PAGES, AVAILABLE_PAGES } from '../constants';

const theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        color: '#fff', // No more ripple, on the whole application 💣!
      },
    },
  },
  palette: {
    background: {
      dark: '#fff',
    },
    text: {
      secondary: '#fff',
    },
    primary: {
      main: '#4CB3AA',
    },
    secondary: {
      main: '#fff',
    },
  },
});

const HeaderTabs = styled(({ className, ...other }) => (
  <Tabs
    {...other}
    classes={{
      root: className,
      indicator: 'indicator',
    }}
    TabIndicatorProps={{ children: <span /> }}
  />
))({
  '& .indicator': {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '0px',
    height: '4px',
    top: 5,
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 30,
      width: '100%',
      backgroundColor: '#4CB3AA',
    },
  },
  '& .MuiTab-root': {
    width: '100px',
  },
  '& .flexContainer': {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .Mui-selected': {
    color: '#fff !important',
  },
});

function Header(props) {
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState('/');
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleSwitch = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const changeLanguage = (lang) => {
    // const currentLanguage = i18n.language;
    if (lang === 'en') {
      i18n.changeLanguage('en');
    } else if (lang === 'zh') {
      i18n.changeLanguage('zh'); // zh-tw
    } else {
      i18n.changeLanguage('cn'); // zh-cn
    }
  };

  const handleClose = (event) => {
    console.log(i18n.language);
    const { langValue } = event.currentTarget.dataset;
    console.log(langValue);
    changeLanguage(langValue);
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    if (AVAILABLE_PAGES.includes(newValue)) {
      navigate(newValue);
      setValue(newValue);
    }
  };

  const handleClick = () => {
    setValue('/');
    navigate('/');
  };

  // eslint-disable-next-line consistent-return
  const displayLanguageText = () => {
    if (i18n.language === 'en') return 'ENG';
    if (i18n.language === 'zh') return '中（繁）';
    if (i18n.language === 'cn') return '中（簡）';
  };

  useEffect(() => {
    // const { pathname } = props.location;
    const page = window.location.pathname.split('/')[1];
    if (!_.isEmpty(page)) {
      setValue(page);
    }
  }, []);

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: '#000',
        height: { md: '80px' },
        alightSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
      elevation={0}
    >
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl" disableGutters sx={{ pl: 5, pr: 5 }}>
          <Toolbar sx={{ flexWrap: 'wrap' }}>
            <Grid container rowSpacing={{ xs: 1, md: 10 }}>
              <Grid item xs={12} md={3}>
                <Stack sx={{ cursor: 'pointer' }} onClick={handleClick}>
                  <Typography variant="h6" color="inherit" noWrap>
                    Innomobel Ltd.
                  </Typography>
                  <Divider sx={{ width: '132px', borderBottomWidth: '1px', borderColor: 'white' }} />
                  <Typography variant="h6" color="inherit" noWrap>
                    Furnimax Ltd.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={7} sx={{ alignSelf: 'flex-end' }}>
                <HeaderTabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="pages">
                  {/* <Tab value="/" key="test" sx={{ display: 'none' }} /> */}
                  {PAGES.map((page) => (
                    <Tab value={page} key={page} label={`${tIntermediate(`pages.${page}`)}`} sx={{ fontSize: '16px', textTransform: 'capitalize' }} />
                  ))}
                </HeaderTabs>
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                sx={{
                  alignSelf: 'center',
                }}
                mb={{ xs: 2, md: 0 }}
              >
                <div>
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleSwitch}
                    sx={{ color: 'white' }}
                  >
                    {displayLanguageText()}
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem data-lang-value="en" onClick={handleClose}>English</MenuItem>
                    <MenuItem data-lang-value="zh" onClick={handleClose}>中（繁）</MenuItem>
                    <MenuItem data-lang-value="cn" onClick={handleClose}>中（簡）</MenuItem>
                  </Menu>
                </div>
                {/* <Typography
                  variant="body1"
                  onClick={changeLanguage}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'end',
                    fontSize: '16px',
                  }}
                >
                  {tIntermediate('language.switch')}
                </Typography> */}
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </ThemeProvider>
    </AppBar>
  );
}

Header.propTypes = {
};

export default (Header);
