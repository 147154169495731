import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

// import messageSaga from './services/message/saga';
import reducer from './reducers';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  })
  : compose;

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware),
  // other store enhancers if any
);
// Mount it on the Store
/* eslint-disable no-underscore-dangle */
const store = createStore(reducer, enhancer);

// Then run the saga
// sagaMiddleware.run(messageSaga);
store.runSaga = sagaMiddleware.run;

export default store;
