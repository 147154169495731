import {
  NEWS_ADD,
  NEWS_ADD_SUCCESS,
  NEWS_ADD_FAIL,
  NEWS_GET_ONE,
  NEWS_GET_ONE_SUCCESS,
  NEWS_GET_ONE_FAIL,
  NEWS_GET_ALL,
  NEWS_GET_ALL_SUCCESS,
  NEWS_GET_ALL_FAIL,
  NEWS_UPDATE,
  NEWS_UPDATE_SUCCESS,
  NEWS_UPDATE_FAIL,
  NEWS_REMOVE,
  NEWS_REMOVE_SUCCESS,
  NEWS_REMOVE_FAIL,
  NEWS_ADD_IMAGE,
  NEWS_ADD_IMAGE_SUCCESS,
  NEWS_ADD_IMAGE_FAIL,
  NEWS_REMOVE_IMAGE,
  NEWS_REMOVE_IMAGE_SUCCESS,
  NEWS_REMOVE_IMAGE_FAIL,
  NEWS_CHANGE_ORDER_IMAGE,
  NEWS_CHANGE_ORDER_IMAGE_SUCCESS,
  NEWS_CHANGE_ORDER_IMAGE_FAIL,
  NEWS_SET_SHOW_SNACKBAR,
} from './constants';

export const addNews = (data) => ({
  type: NEWS_ADD,
  data,
});

export const addNewsSuccess = (data) => ({
  type: NEWS_ADD_SUCCESS,
  data,
});

export const addNewsFail = (error) => ({
  type: NEWS_ADD_FAIL,
  error,
});

export const updateNews = (data) => ({
  type: NEWS_UPDATE,
  data,
});

export const updateNewsSuccess = (data) => ({
  type: NEWS_UPDATE_SUCCESS,
  data,
});

export const updateNewsFail = (error) => ({
  type: NEWS_UPDATE_FAIL,
  error,
});

export const removeNews = (data) => ({
  type: NEWS_REMOVE,
  data,
});

export const removeNewsSuccess = (data) => ({
  type: NEWS_REMOVE_SUCCESS,
  data,
});

export const removeNewsFail = (error) => ({
  type: NEWS_REMOVE_FAIL,
  error,
});

export const getAll = () => ({
  type: NEWS_GET_ALL,
});

export const getAllSuccess = (data) => ({
  type: NEWS_GET_ALL_SUCCESS,
  data,
});

export const getAllFail = (error) => ({
  type: NEWS_GET_ALL_FAIL,
  error,
});

export const getOne = () => ({
  type: NEWS_GET_ONE,
});

export const getOneSuccess = (data) => ({
  type: NEWS_GET_ONE_SUCCESS,
  data,
});

export const getOneFail = (error) => ({
  type: NEWS_GET_ONE_FAIL,
  error,
});

export const addNewsImage = (newsId, news) => ({
  type: NEWS_ADD_IMAGE,
  newsId,
  news,
});

export const addNewsImageSuccess = (data) => ({
  type: NEWS_ADD_IMAGE_SUCCESS,
  data,
});

export const addNewsImageFail = (error) => ({
  type: NEWS_ADD_IMAGE_FAIL,
  error,
});

export const removeNewsImage = (id) => ({
  type: NEWS_REMOVE_IMAGE,
  id,
});

export const removeNewsImageSuccess = (data) => ({
  type: NEWS_REMOVE_IMAGE_SUCCESS,
  data,
});

export const removeNewsImageFail = (error) => ({
  type: NEWS_REMOVE_IMAGE_FAIL,
  error,
});

export const changeNewsImageOrder = (news) => ({
  type: NEWS_CHANGE_ORDER_IMAGE,
  news,
});

export const changeNewsImageOrderSuccess = (data) => ({
  type: NEWS_CHANGE_ORDER_IMAGE_SUCCESS,
  data,
});

export const changeNewsImageOrderFail = (error) => ({
  type: NEWS_CHANGE_ORDER_IMAGE_FAIL,
  error,
});

export const setNewsShowSnackbar = (bool) => ({
  type: NEWS_SET_SHOW_SNACKBAR,
  bool,
});
