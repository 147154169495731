import { createSelector } from 'reselect';
import _ from 'lodash';
import { initialState } from './reducer';

export const selectDashboardDomain = (state) => state.dashboard || initialState;
export const makeSelectDashboardCategoryList = () => createSelector(selectDashboardDomain, _.property('categoryList'));
export const makeSelectDashboardSubcategoryList = () => createSelector(selectDashboardDomain, _.property('subcategoryList'));
export const makeSelectDashboardProductList = () => createSelector(selectDashboardDomain, _.property('productList'));
export const makeSelectDashboardOptionList = () => createSelector(selectDashboardDomain, _.property('optionList'));
export const makeSelectDashboardNewsList = () => createSelector(selectDashboardDomain, _.property('newsList'));
export const makeSelectDashboardProjectList = () => createSelector(selectDashboardDomain, _.property('projectList'));
export const makeSelectDashboardCategoryLoading = () => createSelector(selectDashboardDomain, _.property('categoryLoading'));
export const makeSelectDashboardSubcategoryLoading = () => createSelector(selectDashboardDomain, _.property('subcategoryLoading'));
export const makeSelectDashboardProductLoading = () => createSelector(selectDashboardDomain, _.property('productLoading'));
export const makeSelectDashboardNewsLoading = () => createSelector(selectDashboardDomain, _.property('newsLoading'));
export const makeSelectDashboardProjectLoading = () => createSelector(selectDashboardDomain, _.property('projectLoading'));
