import { useTranslation, withTranslation } from 'react-i18next';
import { tify, sify } from 'chinese-conv';

export const isChinese = (language) => language === 'zh' || language === 'cn';

export const tIntermediate = (key) => {
  const { t, i18n } = useTranslation();
  const text = t(key);

  if (i18n.language === 'cn') {
    return sify(text);
  }
  return text;
};

export const tIntermediateWithoutTranslate = (i18n, text) => {
  // const { i18n } = useTranslation();
  if (i18n.language === 'cn') {
    return sify(text);
  }
  return text;
};

export const returnCorrectLanguage = (lang) => (lang === 'cn' ? 'zh' : lang);
